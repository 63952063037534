import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import omit from 'lodash/omit';
import { useHistory,useParams,useLocation } from 'react-router-dom';
// import moment from 'moment';
import find from 'lodash/find';
import has from 'lodash/has';
// import axios from 'axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Livraison';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';
import IconMaterialTable from '../../IconMaterialTable';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      },
}));

// validate.validators.check_quantite_livraison = (value,options,key,attributes)=>{
//     if(attributes.quantite>attributes.quantite_reste) return 'est superieure à la quantité restante';
// }

export default function Livraison(){
    const initData = {
        // date_livraison:moment().format('YYYY-MM-DD'),
        commande:"",
        receptionnaire:"",
        details:[],
        errors:{}
    };
    const classes = useStyles();
    const history = useHistory();
    const { id,idCommande } = useParams()
    const appContext = React.useContext(AppContext);
    const [livraison,setLivraison] = React.useState({...initData})
    const [fournisseurs,setFournisseurs] = React.useState([])
    const [allCommandes,setAllCommandes] = React.useState([])
    const [commandes,setCommandes] = React.useState([])
    const [articles,setArticles] = React.useState([])
    const [unites,setUnites] = React.useState([])
    const [receptionnaires,setReceptionnaires] = React.useState([])
    const refListe = React.useRef(null);
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();

    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [fournisseurs,allCommandes,articles,unites,receptionnaires] = await Promise.all([
                axios.get(`/fournisseurs`),
                axios.get(`/commandes`),
                axios.get(`/articles`),
                axios.get(`/unites`),
                axios.get(`/employes`),
            ]);
            setFournisseurs(fournisseurs.data.results)            
            setAllCommandes(allCommandes.data.results)            
            setArticles(articles.data.results)            
            setUnites(unites.data.results)            
            setReceptionnaires(receptionnaires.data.results)            

            if(id){
                if(pathname===`/livraisons/view/${id}`){
                    setView(true)
                }
                const {data} = await axios.get(`/livraisons/${id}`);
                let livraison = {
                        id: data.id,
                        date_livraison : data.date_livraison,
                        commande: data.commande.id,
                        fournisseur: data.commande.fournisseur.id,
                        receptionnaire: data.receptionnaire.id,
                        details:data.details.map(d=>({...d,
                            quantite_commande: d.detail_commande.quantite,
                            quantite_reste:d.detail_commande.quantite_reste,
                            quantite_reste_init:d.detail_commande.quantite_reste, //juste pour changer le reste visuellement
                            montant:util.separteurMillier(d.montant)
                        })),
                        errors:{}
                    }
                
                setLivraison(livraison)
                setCommandes([{...data.commande}])
            }else if(idCommande){ // ajout d'une livraison à partir d'une commande
            const commande = find(allCommandes.data.results,c=>c.id===parseInt(idCommande))
            if(commande){
                setFournisseurs(fournisseurs.data.results.filter(f=>f.id===commande.fournisseur.id))
                setCommandes([{...commande}])
                let livraison = {
                    commande: commande.id,
                    fournisseur: commande.fournisseur.id,
                    details:commande.details.map(d=>({...omit(d,['id']),
                        detail_commande:d.id,
                        quantite:0,
                        quantite_commande: d.quantite,
                        quantite_reste:d.quantite_reste,
                        quantite_reste_init:d.quantite_reste, //juste pour changer le reste visuellement
                        montant:0
                    })),
                    errors:{}

                }
                setLivraison(livraison)
            }
        }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = async (e,value)=>{
        // console.log(e.target.name)
        if(e.target.name==='fournisseur'){
            const cs = allCommandes.filter(c=>c.fournisseur.id===parseInt(e.target.value));
            setCommandes(cs)
            setLivraison({...livraison,[e.target.name]:e.target.value,details:[]})
        }else if(e.target.name==='commande'){
            appContext.setOpenLoading(true);
            try {
                const id = e.target.value;
                const commande =  await axios.get(`/commandes/${id}`);
                const details = commande.data.details.map(d=>{
                    return {
                        ...omit(d,['id']),
                        detail_commande:d.id,
                        quantite_commande:d.quantite,
                        quantite_reste:d.quantite_reste,
                        quantite_reste_init:d.quantite_reste, //juste pour changer le reste visuellement
                        quantite:0,
                        montant:0
                    }
                })
                setLivraison({...livraison,commande:id,details});
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
            }
            appContext.setOpenLoading(false);
        }else if(e.target.name==='receptionnaire'){
            console.log(value)
            setLivraison({...livraison,receptionnaire:value});
        }else{
            setLivraison({...livraison,[e.target.name]:e.target.value})
        }
    }
    const handleChangeDetail = (e,props)=>{
        let data = {...props.rowData};
        if(props.columnDef.field==='article'){
            const article = find(articles,a=>a.id===parseInt(e.target.value))
            data = {...data,
                [props.columnDef.field]:article,
                prix: article ? article.prix.toString() : ""
            }
            props.onChange(article)
        }else if(props.columnDef.field==='unite_stockage'){
            const unite_stockage = find(unites,a=>a.id===parseInt(e.target.value))
            data = {...data,
                [props.columnDef.field]:unite_stockage,
            }
            props.onChange(unite_stockage)
        }else{
            data = {...data,[props.columnDef.field]:e.target.value}
            props.onChange(e.target.value);
        }
        data = {
            ...data,
            montant:(parseInt(data.quantite||0)*parseInt(data.prix||0)).toLocaleString('fr-FR'),
            quantite_reste: parseInt(data.quantite_reste_init||0)-parseInt(data.quantite||0),
            errors:{}
        }
        props.onRowDataChange(data)
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(livraison,constraint.add);
        if(errors){
            setLivraison({...livraison,errors});
            return;
        }else if(livraison.details.length===0){
            appContext.addMessage("Aucun article",'  error');
            return
        }
        appContext.setOpenLoading(true);
        try {
            const a = {...omit(livraison,['errors','fournisseur'])};
            a.details = a.details.map(d=>({
                id:d.id,
                detail_commande: has(d.detail_commande,'id') ? d.detail_commande.id : d.detail_commande,
                article:d.article.id,
                unite_stockage:d.unite_stockage.id,
                quantite:d.quantite,
                prix:d.prix
            }));
            if(!id){
                await axios.post(`/livraisons`,a);
                appContext.addMessage('Enregistrement effectué avec succès','success');
            }else{
                await axios.put(`/livraisons/${a.id}`,a);
                appContext.addMessage('Modification effectuée avec succès','success');
            }
            setLivraison({...initData})
            history.push("/liste-livraisons")
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }  
        appContext.setOpenLoading(false);  
    }

    const colonnes = [
        { title: 'N°', field: 'index', editable:'never',
            read_only:true,width:'0.5%',
            cellStyle:{witheSpace:'nowrap'},
            // headerStyle:{width:300,maxWidth:300},
            // initialEditValue:livraison.details.length+1,
            render: rowData => rowData ? rowData.tableData.id+1 : livraison.details.length+1
        },
        { title: 'Article', field: 'article',editable:'never',
            render:rowData=>rowData['article'] ? rowData['article'].nom : ""
        },
        { title: 'Qte Commandée', field: 'quantite_commande',initialEditValue:"0", editable:'never'},
        { title: 'Qte Livrée',initialEditValue:"0", editable:'never',
            render:rowData=>rowData.quantite_commande-rowData.quantite_reste
        },
        { title: 'Reste à livrer', field: 'quantite_reste',initialEditValue:"0", editable:'never'},
        { title: 'Qte Livraison', field: 'quantite',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    inputProps={{
                        min:"0"
                    }}
                    name="quantite"
                    value={props.value||0}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    type="number"
                    label=" "
                />
            )
        },
        { title: 'Unite', field: 'unite_stockage',editable:'never',
            render:rowData=>rowData['unite_stockage'] ? rowData['unite_stockage'].libelle : ""
        },
        { title: 'Prix', field: 'prix',initialEditValue:"0",editable:'never',
          render:rowData=>rowData.prix && util.separteurMillier(rowData.prix)
        },
        { title: 'Montant', field: 'montant',editable:'never',
            read_only:true,initialEditValue:"0",
            // render:rowData=>rowData.montant && util.separteurMillier(rowData.montant)
        },
    ];

    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            if(newData.quantite>newData.quantite_reste){

            }
            try {
                const errors = validate(newData,constraint.detail);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    const a = {...livraison}
                    a.details = a.details.map(d=>d.tableData.id===oldData.tableData.id?newData:d);
                    setLivraison(a)
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }
    const rowDelete = (oldData)=>{
        return new Promise((resolve,reject)=>{
            const ds = [...livraison.details];
            let details = ds.filter(d=>d.tableData.id!==oldData.tableData.id)
            details = details.map((ds,i)=>({...ds,index:i+1}));
            setLivraison({...livraison,details})
            resolve()
        })
    }
    const editable = ()=>!view ? ({
        onRowUpdate:rowUpdate,
        onRowDelete:rowDelete
    }):{}
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? view ? "Consultation": "Modification" : "Saisie"} Livraison
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_livraison"
                            value={livraison.date_livraison}
                            onChange={handleChange}
                            errors={livraison.errors}
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MySelectField 
                            name="fournisseur"
                            value={livraison.fournisseur}
                            onChange={handleChange}
                            errors={livraison.errors}
                            options={fournisseurs}
                            libelle="nom"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MySelectField 
                            name="commande"
                            value={livraison.commande}
                            onChange={handleChange}
                            errors={livraison.errors}
                            options={commandes}
                            libelle="date_commande"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MySelectField 
                            name="receptionnaire"
                            value={livraison.receptionnaire}
                            onChange={handleChange}
                            errors={livraison.errors}
                            options={receptionnaires}
                            libelle="full_name"
                            disabled={view}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <MaterialTable
                            title={`Liste des articles`}
                            icons={IconMaterialTable}
                            columns={colonnes}
                            data={livraison.details}
                            tableRef={refListe}
                            editable={editable()}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize:5,
                                pageSizeOptions:[5,10,15],
                                // debounceInterval:1000,
                                filtering:false,
                                search:false
                            }}
                        />
                    </Grid>
                </Grid>
                {!view && 
                    <Grid container direction="row-reverse" spacing={3} style={{margin:5}} >
                        <Grid item xs={6} sm={4} lg={3}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={enregistrer}
                            >
                                {id ? 'Modifier' : 'Enregistrer'}
                            </Button>
                        </Grid>
                    </Grid>

                }
            </div>
        </Container>
    )
};