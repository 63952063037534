import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper,FormControl,FormHelperText } from '@material-ui/core';
import omit  from 'lodash/omit';
import find  from 'lodash/find';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/PaiementCommande';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    // date_paiement:moment().format('YYYY-MM-DD'),
    commande:"",
    errors:{}
};
export default function PaiementCommandeCommande(){
    const classes = useStyles();
    const history = useHistory();
    const { id,idCommande } = useParams()
    const appContext = React.useContext(AppContext);
    const [paiementCommande,setPaiementCommande] = React.useState({...initData})
    const [fournisseurs,setFournisseurs] = React.useState([])
    const [commandes,setCommandes] = React.useState([])
    const [commandeSelectionne,setCommandeSelectionne] = React.useState(null)
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [fournisseurs] = await Promise.all([
                axios.get(`/fournisseurs`)
            ]);
            setFournisseurs(fournisseurs.data.results)            
            if(id){ // modification d'un paiementCommande
                const {data} = await axios.get(`/paiements-commandes/${id}`);
                let paiementCommande = {
                        id: data.id,
                        date_paiement:data.date_paiement,
                        fournisseur: data.commande.fournisseur.id,
                        commande:data.commande.id,
                        montant:data.montant,
                        description: data.description,
                        errors:{}
                }
                if(pathname===`/paiements-commandes/view/${id}`){
                    setView(true);
                    paiementCommande = {
                        ...paiementCommande,
                        commande:data.commande
                    }
                }
                setPaiementCommande(paiementCommande)
                setCommandes([{...data.commande}])
            }else if(idCommande){ // ajout d'un paiementCommande à partir d'un commande
                const commande = await axios.get(`commandes/${idCommande}`);
                if(commande){
                    setCommandeSelectionne(commande.data)
                    setCommandes([{...commande.data}])
                    // setFournisseurCommandes(commandes.data.results.filter(c=>c.fournisseur.id===commande.fournisseur.id && !commande.flag_termine))
                    let paiementCommande = {
                        fournisseur: commande.data.fournisseur.id,
                        commande:commande.data.id,
                        errors:{}
                    }
                    setPaiementCommande(paiementCommande)
                }
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        // if(!idCommande){ // pas besoin d'arrêter le loading, car la page est entièrement recharger
            appContext.setOpenLoading(false);
        // }
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = async (e,value)=>{
        if(e.target.name==='fournisseur'){
            if(!e.target.value) return;
            appContext.setOpenLoading(true);
            try {
                const id = e.target.value;
                const commandes =  await axios.get(`/fournisseurs/${id}/commandes`);
                setCommandes(commandes.data.results)
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
            }
            appContext.setOpenLoading(false);
            setPaiementCommande({...paiementCommande,fournisseur:value})
            setCommandeSelectionne(null);
        }else if(e.target.name === 'commande'){
            const c = find(commandes,c=>c.id===e.target.value);
            setCommandeSelectionne(c)
            setPaiementCommande({...paiementCommande,commande:e.target.value})
        }else{
            setPaiementCommande({...paiementCommande,[e.target.name]:e.target.value})
        }
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(paiementCommande,constraint.add);
        if(errors){
            setPaiementCommande({...paiementCommande,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/paiements-commandes`,{...omit(paiementCommande,['errors','fournisseur'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                if(!idCommande) setPaiementCommande({...initData})
                else fetchData()
            }else{
                history.goBack()
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(paiementCommande,constraint.add);
        if(errors){
            setPaiementCommande({...paiementCommande,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/paiements-commandes/${paiementCommande.id}`,{...omit(paiementCommande,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} PaiementCommande
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_paiement"
                            value={paiementCommande.date_paiement||""}
                            onChange={handleChange}
                            errors={paiementCommande.errors}
                            label="Date paiement"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="fournisseur"
                                value={paiementCommande.commande.fournisseur && paiementCommande.commande.fournisseur.nom}
                                label="Fournisseur"
                                disabled={view}
                            />
                        :
                            <MySelectField 
                                name="fournisseur"
                                value={paiementCommande.fournisseur}
                                onChange={handleChange}
                                errors={paiementCommande.errors}
                                options={fournisseurs}
                                libelle="nom"
                                disabled={view}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {view ?
                            <MyTextField 
                                name="commande"
                                value={paiementCommande.commande && paiementCommande.commande.date_commande}
                                label="Objet commande"
                                multiline
                                rows="4"
                                variant="outlined"
                                disabled={view}
                                required={false}
                            />
                        :
                        <FormControl fullWidth>
                            <MySelectField 
                                name="commande"
                                value={paiementCommande.commande}
                                onChange={handleChange}
                                errors={paiementCommande.errors}
                                options={commandes}
                                libelle="date_commande"
                                disabled={view}
                            />
                            {commandeSelectionne &&
                                <React.Fragment>
                                    <FormHelperText>Montant commande:{util.separteurMillier(commandeSelectionne.montant)}</FormHelperText>
                                    <FormHelperText>Montant restant:{util.separteurMillier(commandeSelectionne.montant-commandeSelectionne.montant_paye)}</FormHelperText>
                                </React.Fragment>
                            }
                        </FormControl>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={paiementCommande.description}
                            onChange={handleChange}
                            errors={paiementCommande.errors}
                            label="Description du paiement"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            required={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(paiementCommande.montant):paiementCommande.montant}
                            onChange={handleChange}
                            errors={paiementCommande.errors}
                            label="Montant du paiement"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};