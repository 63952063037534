import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Depense';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    date_depense:"",
    contrat:"",
    table:'VEHICULE',
    errors:{}
};
export default function Depense(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [depense,setDepense] = React.useState({...initData})
    const [view,setView] = React.useState(false);
    const [vehicules,setVehicules] = React.useState([])
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [vehicule] = await Promise.all([
                axios.get(`/vehicules`),
            ]);

            if(id){ // modification d'un depense
                const {data} = await axios.get(`/depenses/${id}`);
                let depense = {
                        id: data.id,
                        date_depense:data.date_depense,
                        montant:data.montant,
                        description: data.description,
                        vehicule:data.vehicule ? data.vehicule.id : null,
                        table:'VEHICULE',
                        id_table:data.vehicule ? data.vehicule.id : null,
                        errors:{}
                }
                if(pathname===`/depenses-vehicules/view/${id}`){
                    setView(true);
                    depense = {
                        ...depense,
                        vehicule:data.vehicule
                    }
                }
                setDepense(depense)
            }
            setVehicules(vehicule.data.results)
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
            appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        if(e.target.name==='vehicule'){
            depense.id_table = e.target.value
        }
        setDepense({...depense,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(depense,constraint.add);
        if(errors){
            setDepense({...depense,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/depenses`,{...omit(depense,['errors','vehicule'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setDepense({...initData})
            }else{
                history.goBack()
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(depense,constraint.add);
        if(errors){
            setDepense({...depense,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/depenses/${depense.id}`,{...depense});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} d'une dépense
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_depense"
                            value={depense.date_depense||""}
                            onChange={handleChange}
                            errors={depense.errors}
                            label="Date depense"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="vehicule"
                                value={depense.vehicule && depense.vehicule.immatriculation}
                                label="Vehicule"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="vehicule"
                                value={depense.vehicule}
                                onChange={handleChange}
                                errors={depense.errors}
                                options={vehicules}
                                libelle="immatriculation"
                                disabled={view}
                                label="Véhicule"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={depense.description}
                            onChange={handleChange}
                            errors={depense.errors}
                            label="Description de la depense"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            required={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(depense.montant):depense.montant}
                            onChange={handleChange}
                            errors={depense.errors}
                            label="Montant de la depense"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};