import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper'
import util from '../../utils/util';

const useStyles = makeStyles(theme => ({
    paper:{ paddingLeft: 100,paddingRight:70 },
}))


export default function BulletinDetail({details,...rest}){
    const classes = useStyles();
   
    const colonnes = [
            { title: 'Rubrique', field: 'rubrique.libelle'},
            { title: '', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            render : rowData=>rowData && util.separteurMillier(rowData.montant)  
        },
    ];


    return (
            <MaterialTable
                columns={colonnes}
                data={details}
                components={{
                    Container: props => <Paper elevation={0} className={classes.paper} {...props}/>,
                    Toolbar:props=><div/>
                }}
                options={{
                    actionsColumnIndex: -1,
                    pageSize:5,
                    filtering:false,
                    search:false,
                    paging:false
                }}
            />
    )
};

