import React from 'react';
// import { useHistory } from 'react-router-dom';
import {useParams } from 'react-router-dom';
import Liste from '../Codification/Liste';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';

export default function ListeArticle(){
    const appContext = React.useContext(AppContext);
    const [mouvements,setMouvements] = React.useState([]);
    const [article,setArticle] = React.useState([]);
    const {idArticle} = useParams();
    const history = useHistory();

    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [mouvements,article] = await Promise.all([
                axios.get(`/mvt-stocks?article.id=${idArticle}`),
                axios.get(`/articles/${idArticle}`),
            ]);
            setMouvements(mouvements.data.results) 
            setArticle(article.data)           
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);

    const colonnes = [
        { title: 'Data', field: 'date_mouvement' },
        { title: 'Type', field: 'type_mouvement.libelle' },
        { title: 'Article', field: 'article.nom' },
        { title: 'Unite', field: 'unite.libelle' },
        { title: 'Quantite', field: 'quantite' },
        { title: 'Prix', field: 'prix' },
        { title: 'Description', field: 'description' },
    ];
    return (
            <Liste 
                urlBaseGET={`mvt-stocks?article.id=${idArticle}`}
                title={`Liste des mouvements : ${article.nom}`}
                values={mouvements} 
                setValues={setMouvements}
                colonnes={colonnes}
                maxWidth={"lg"}
                editable={
                    {onRowUpdate:null,
                    onRowAdd:null}
                }
                actions={[                
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouveau mouvement',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push(`/mvt-stocks/articles/${idArticle}`)
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher depense',
                        onClick: (event, rowData) => history.push(`/mvt-stocks/view/${rowData.id}`)
                    },
                    rowData=> !rowData.id_table && ({
                        icon: 'edit',
                        tooltip: 'Modifier mouvement',
                        onClick: (event, rowData) => history.push(`/mvt-stocks/${rowData.id}`)
                    }),
                ]}
                // transformDataBeforeSent={data=>({...data,unite:data.unite ? data.unite.id: null})}
            />
    )
};

