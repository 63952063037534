import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
import { useHistory,useParams } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Article';
import MyTextField from '../Codification/MyTextField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    nom:"",
    unite_stockage:"",
    errors:{}
};
export default function Article(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [article,setArticle] = React.useState({...initData})
    // const [unites,setUnites] = React.useState([])
    
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            if(id){
                const {data} = await axios.get(`/articles/${id}`);
                const article = {
                        id: data.id,
                        nom : data.nom,
                        errors:{}
                    }
                setArticle(article)
            }
            // setUnites(unites.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setArticle({...article,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(article,constraint.add);
        if(errors){
            setArticle({...article,errors});
            return;
        }
        try {
            await axios.post(`/articles`,{...omit(article,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setArticle({...initData})
            }else{
                history.push("/")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
    }
    const modifier = async ()=>{
        const errors = validate(article,constraint.add);
        if(errors){
            setArticle({...article,errors});
            return;
        }
        try {
            await axios.put(`/articles/${article.id}`,{...omit(article,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
                history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
    }
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Article
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            name="nom"
                            value={article.nom}
                            onChange={handleChange}
                            errors={article.errors}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={modifier}
                            >
                                Modifier
                            </Button>
                        </Grid>
                        :
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};