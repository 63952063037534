import React from 'react';
import {TextField,FormControl} from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import util from '../../utils/util'

export default function MyTextField({name,type,label,value,onChange,disabled=false,errors=[],...rest}){
    return(
        <FormControl fullWidth error={errors[[name]]?true:false}>
            <TextField
                name={name}
                required
                type={type||"text"}
                fullWidth
                id={name}
                label={label||capitalize(name)}
                value={value||""}
                onChange={onChange}
                error={errors[[name]]?true:false}
                disabled={disabled}
                {...rest}
                helperText={errors ? util.getMessageConstraintError(errors[[name]]):''}    
            />
        </FormControl>
)
}