import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Divider
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { AppContext } from '../../App';
const StyledBadge = withStyles({
  badge: {
	backgroundColor: 'var(--success)',
	color: 'var(--success)',
	boxShadow: '0 0 0 2px #fff',
	'&::after': {
	  position: 'absolute',
	  top: 0,
	  left: 0,
	  width: '100%',
	  height: '100%',
	  borderRadius: '50%',
	  animation: '$ripple 1.2s infinite ease-in-out',
	  border: '1px solid currentColor',
	  content: '""'
	}
  },
  '@keyframes ripple': {
	'0%': {
	  transform: 'scale(.8)',
	  opacity: 1
	},
	'100%': {
	  transform: 'scale(2.4)',
	  opacity: 0
	}
  }
})(Badge);
export default function HeaderUserbox() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const appContext = React.useContext(AppContext);
  const user = appContext.user;
  const handleClick = event => {
	setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
	setAnchorEl(null);
  };

  return (
	<Fragment>
	  <Button
		color="inherit"
		onClick={handleClick}
		className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
		<Box>
		  <StyledBadge
			overlap="circle"
			anchorOrigin={{
			  vertical: 'bottom',
			  horizontal: 'right'
			}}
			variant="dot">
			<Avatar sizes="44" alt={user ? `${user.prenom} ${user.nom}` : ""}/>
		  </StyledBadge>
		</Box>
		<div className="d-none d-xl-block pl-3">
		  <div className="font-weight-bold pt-2 line-height-1">
			{user ? `${user.prenom} ${user.nom}` : ""}
		  </div>
		  {/* <span className="text-white-50">Senior  Developer</span> */}
		</div>
		<span className="pl-1 pl-xl-3">
		  <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
		</span>
	  </Button>

	  <Menu
		anchorEl={anchorEl}
		keepMounted
		getContentAnchorEl={null}
		open={Boolean(anchorEl)}
		anchorOrigin={{
		  vertical: 'center',
		  horizontal: 'center'
		}}
		transformOrigin={{
		  vertical: 'center',
		  horizontal: 'center'
		}}
		onClose={handleClose}
		className="ml-2">
		<div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
		  <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
			<Box>
			  <StyledBadge
				overlap="circle"
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'right'
				}}
				variant="dot">
				<Avatar sizes="44" alt={user ? `${user.prenom} ${user.nom}` : ""} />
			  </StyledBadge>
			</Box>
			<div className="pl-3 ">
			  <div className="font-weight-bold text-center pt-2 line-height-1">
			  	{user ? `${user.prenom} ${user.nom}` : ""}
			  </div>
			</div>
			<Divider className="w-100 mt-2" />
			<ListItem button component={Link} to="/change-password" >Changement password</ListItem>
			<ListItem button component={Link} to="/selection-projet">Changer de projet</ListItem>
			<ListItem button component={Link} to="/selection-application">Changer d'application</ListItem>
			<ListItem button component={Link} to="/logout">Deconnexion</ListItem>
		  </List>
		</div>
	  </Menu>
	</Fragment>
  );
}
