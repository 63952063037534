import moment from 'moment';
export default {
    add:{
        date_ramassage:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            datetime: {
                dateOnly: true,
                latest:moment().format('YYYY-MM-DD'),
                message :"date incorrect"
                // latest: moment.utc().subtract(18, 'years'),
                // message: "^You need to be at least 18 years old"
            }
        },
        hangar_bande:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        nbre_oeufs:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
    },
    journalier:{
        maitre:{
            date_ramassage:{
                presence: {
                    allowEmpty: false,
                    message:"est obligatoire"
                },
                datetime: {
                    dateOnly: true,
                    latest:moment().format('YYYY-MM-DD'),
                    message :"date incorrect"
                    // latest: moment.utc().subtract(18, 'years'),
                    // message: "^You need to be at least 18 years old"
                }
            },    
        },
        detail:{
            nbre_oeufs:{
                presence: {
                    allowEmpty: false,
                    message:"est obligatoire"
                },
                numericality:{
                    greaterThan:0,
                    message:" doit être superieur à 0"
                },
    
            },
        }
    }
}