import React from 'react';
// import { useHistory } from 'react-router-dom';
import Liste from '../Codification/Liste';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import { AppContext } from '../../App';
import util from '../../utils/util';
import axios from '../../utils/axios';


export default function ListeResponsable(){
    const appContext = React.useContext(AppContext);

    const [anneeScolaires,setAnneeScolaires] = React.useState([]);
    // const history = useHistory();
    const colonnes = [
        { title: 'Début', field: 'debut'},
        { title: 'Fin', field: 'fin'},
        { title: 'Etat',
            render:rowData=> rowData ?
            <div className={`badge badge-${rowData.cloture ?'success':'warning'} px-4`}>
                {rowData.cloture ?'Clôturé':'Encours'}
            </div> : ""
    },
        // { title: 'Active', field: 'active',editable:'never',filtering: false},
    ];

    const cloture = async (annee) => {
        if(annee.cloture) {
            appContext.addMessage('Déjà cloturé','info');
            return;
        }
        try {
            await axios.put(`/annee-scolaires/${annee.id}/cloture`);
            annee.cloture = true;
            appContext.addMessage('Année scolaire cloturée avec succès','success');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
    }


    return (
            <Liste table='annee-scolaire' 
                values={anneeScolaires} 
                setValues={setAnneeScolaires}
                colonnes={colonnes}
                actions={[                
                    rowData => !rowData.cloture && ({
                        icon: rowData.cloture ? 'lock' : 'lock_open',
                        iconProps:{style:{color:rowData.cloture ?green[500]:yellow[500]}},
                        tooltip: "Clotûrer l'année",
                        onClick: (event, rowData) =>appContext.setConfirmationMessage({
                            content:'Voulez-vous clôturer cette années scolaire',
                            title:'Confirmation',
                            valider: async ()=>{cloture(rowData);appContext.setOpenConfirmationMessage(false)},
                            annuler: () => appContext.setOpenConfirmationMessage(false)
                        })
                    }),                
                ]}

            />
    )
};

