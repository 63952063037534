export default {
    nom:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        }
    },
    prenom:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        }
    },
    email:{
        email: true
    },
    prenom_pere:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        }
    },
    prenom_mere:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        }
    },
    nom_mere:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        }
    },
    date_naissance:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        },
        date: {
            message: "est incorrect"
          }
    }, 
    salle:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        }
    },      
}