import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmationDialog (props) {
	const valider = () => {
			props.valider();
	}
	const annuler = () => {
			props.annuler();
	}
	return (
		<div>
			<Dialog
				open={props.open}
				onClose={annuler}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{props.content}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={annuler} color="primary">
						Annuler
					</Button>
					<Button onClick={valider} color="primary" autoFocus>
						Valider
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}