import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
// import util from '../../utils/util';

import Liste from '../Codification/Liste';

export default function ListeModele(){
    const [modeles,setModeles] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Marque', field: 'marque.nom' },
        { title: 'Nom', field: 'nom' },
    ];
    return (
            <Liste table='modele' 
                values={modeles} 
                setValues={setModeles}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouveau numeroCompte',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/modeles")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher numeroCompte',
                        onClick: (event, rowData) => history.push(`/modeles/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier numeroCompte',
                        onClick: (event, rowData) => history.push(`/modeles/${rowData.id}`)
                    },
                ]}

            />
    )
};

