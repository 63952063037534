import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Mouvement';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }, 
}))
const initData = {
    date_mouvement:"",
    type_mouvement:"",
    article:"",
    unite:"",
    errors:{}
};
export default function Mouvement(){
    const classes = useStyles();
    const history = useHistory();
    const appContext = React.useContext(AppContext);
    const [typeOperations,setTypeOperations] = React.useState([])
    const [article,setArticle] = React.useState([]);
    const [mouvement,setMouvement] = React.useState({...initData})
    const {id} = useParams();
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [typeOperations] = await Promise.all([
                axios.get(`/type-operations`)
            ]);
            if(id){
                const {data} = await axios.get(`/mvt-stocks/${id}`);
                setArticle(data.article)
                let mouvement = {
                        id: data.id,
                        date_mouvement:data.date_mouvement,
                        type_mouvement:data.type_mouvement.id,
                        article:data.article.nom,
                        unite:data.unite.libelle,
                        quantite:data.quantite,
                        description:data.description,
                        errors:{}
                    }
                if(pathname===`/mvt-stocks/view/${id}`){
                    setView(true);
                    mouvement = {
                        ...mouvement,
                        type_mouvement:data.type_mouvement
                    }
                }
                setMouvement(mouvement)
            }
            setTypeOperations(typeOperations.data.results);
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setMouvement({...mouvement,[e.target.name]:e.target.value})
    }
    const modifier = async ()=>{
        const errors = validate(mouvement,constraint.add);
        if(errors){
            setMouvement({...mouvement,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            let mvt = {
                ...mouvement,
                article:article.id,
                unite:article.unite.id
            }
            await axios.put(`/mvt-stocks/${id}`,{...omit(mvt,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Mouvement
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_mouvement"
                            value={mouvement.date_mouvement||""}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Date mouvement"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="type_mouvement"
                                value={mouvement.type_mouvement && mouvement.type_mouvement.libelle}
                                label="Type Mouvement"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="type_mouvement"
                                value={mouvement.type_mouvement}
                                onChange={handleChange}
                                errors={mouvement.errors}
                                options={typeOperations}
                                libelle="libelle"
                                disabled={view}
                                label="Type mouvement"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            name="article"
                            value={mouvement.article}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Article"
                            variant="outlined"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            name="unite"
                            value={mouvement.unite}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Unite"
                            variant="outlined"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="quantite"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(mouvement.quantite):mouvement.quantite}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Quantite"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={mouvement.description}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Description du mouvement"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            required={true}
                        />
                    </Grid>
                    {id &&
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};