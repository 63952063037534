import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/OperationBriqueterie';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }, 
}))
const initData = {
    date_operation:"",
    salaire:0,
    vehicule:"",
    errors:{}
};
export default function Operation(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [operation,setOperation] = React.useState({...initData})
    const [chargements,setChargements] = React.useState([])
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [chargements] = await Promise.all([
                axios.get(`/chargements?type_chargement.libelle=granite`),
            ]);
            if(id){
                const {data} = await axios.get(`/operations-briqueteries/${id}`);
                let operation = {
                        id: data.id,
                        date_operation:data.date_operation,
                        chargement_granite:data.chargement_granite.id,
                        nbre_brouette_sable:data.nbre_brouette_sable,
                        nbre_sac_ciment:data.nbre_sac_ciment,
                        nbre_manoeuvre:data.nbre_manoeuvre,
                        prime_manoeuvre:data.prime_manoeuvre,
                        description: data.description,
                        errors:{}
                    }
                if(pathname===`/operations-briqueteries/view/${id}`){
                    setView(true);
                    operation = {
                        ...operation,
                        chargement_granite:data.chargement_granite
                    }
                }
                setOperation(operation)
            }
            setChargements(chargements.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        // if(e.target.name==='nbre_manoeuvre'){
        //     let prime = parseInt(e.target.value)*appContext.parametres.prime_manoeuvre;
        //     operation.prime_manoeuvre = prime
        // }
        setOperation({...operation,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(operation,constraint.add);
        if(errors){
            setOperation({...operation,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/operations-briqueteries`,{...omit(operation,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setOperation({...initData})
            }else{
                history.push("/liste-operations-briqueteries")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(operation,constraint.add);
        if(errors){
            setOperation({...operation,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/operations-briqueteries/${operation.id}`,{...omit(operation,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Opération
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_operation"
                            value={operation.date_operation||""}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Date operation"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="chargement_granite"
                                value={operation.chargement_granite && operation.chargement_granite.code}
                                label="Chargement granite"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="chargement_granite"
                                value={operation.chargement_granite}
                                onChange={handleChange}
                                errors={operation.errors}
                                options={chargements}
                                libelle="libelle"
                                disabled={view}
                                label="Chargement granite"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="nbre_brouette_sable"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.nbre_brouette_sable):operation.nbre_brouette_sable}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Nbre brouette"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="nbre_sac_ciment"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.nbre_sac_ciment):operation.nbre_sac_ciment}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Nbre sac ciment"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={3}>
                        <MyTextField 
                            inputProps={{
                                // min:"-1",
                                style:{textAlign:'right'}
                            }}
                            name="nbre_manoeuvre"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.nbre_manoeuvre):operation.nbre_manoeuvre}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Nbre Manoeuvre"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="prime_manoeuvre"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.prime_manoeuvre):operation.prime_manoeuvre}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Prime manoeuvres"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>                                       
                   
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={operation.description}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Description"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            // required={false}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};