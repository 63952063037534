import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Liste from '../Codification/Liste';
// import { AppContext } from '../../App';
// import parameters from '../../config/parameters';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div" 
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.secondary,
    margin: theme.spacing(3),
  },
}));

export default function TabFonction() {
  // const appContext = React.useContext(AppContext);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [parametres, setParametres] = React.useState([]);
  const [unites, setUnites] = React.useState([]);
  const [metiers, setMetiers] = React.useState([]);
  const [banques, setBanques] = React.useState([]);
  const [annees, setAnnees] = React.useState([]);
  const [typeReglements, setTypeReglements] = React.useState([]);
  const [marques, setMarques] = React.useState([]);
  const [carburants, setCarburants] = React.useState([]);
  const [typeChargements, setTypeChargements] = React.useState([]);
  const [typeOperations, setTypeOperations] = React.useState([]);
  const [typeMouvements, setTypeMouvements] = React.useState([]);
  const [typeLiens, setTypeLiens] = React.useState([]);
//   const [exercices, setExercices] = React.useState([]);
  // const ONGLETS = [
  //   {
  //     nom:'Parametres',
  //     urlBase:'parametres',
  //     fields:[
  //       {title:'Nom',field:'nom'},
  //       {title:'Valeur',field:'valeur'},
  //       {title:'Description',field:'description'},
  //     ]
  //   },
  // ]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto">
          <Tab label="Parametres" {...a11yProps(0)} />
          <Tab label="Unite Stockage" {...a11yProps(1)} />
          <Tab label="Metier" {...a11yProps(2)} />
          <Tab label="Annee" {...a11yProps(3)} />
          <Tab label="Type reglèment" {...a11yProps(4)} />
          <Tab label="Banque" {...a11yProps(5)} />
          <Tab label="Type opération" {...a11yProps(6)} />
          <Tab label="Marque" {...a11yProps(7)} />
          <Tab label="Carburant" {...a11yProps(8)} />
          <Tab label="Type chargement" {...a11yProps(9)} />
          <Tab label="Type mouvement" {...a11yProps(10)} />
          <Tab label="Type Lien" {...a11yProps(11)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
            <Liste table='parametre' 
                values={parametres} 
                setValues={setParametres}
                colonnes={[
                    { title: 'Nom', field: 'nom' },
                    { title: 'Valeur', field: 'valeur' },
                    { title: 'Description', field: 'description' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={1}>
            <Liste table='unite' 
                values={unites} 
                setValues={setUnites}
                colonnes={[
                    { title: 'Libelle', field: 'libelle' },
                    { title: 'Symbôle', field: 'symbole' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={2}>
            <Liste table='metier' 
                values={metiers} 
                setValues={setMetiers}
                colonnes={[
                    { title: 'Libelle', field: 'libelle' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={3}>
            <Liste table='annee' 
                values={annees} 
                setValues={setAnnees}
                colonnes={[
                    { title: 'Annee', field: 'valeur' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={4}>
            <Liste 
                urlBase='type-reglements'
              // table='type_reglement' 
                values={typeReglements} 
                setValues={setTypeReglements}
                colonnes={[
                    { title: 'Libelle', field: 'libelle' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={5}>
            <Liste 
                urlBase='banques'
              // table='type_reglement' 
                values={banques} 
                setValues={setBanques}
                colonnes={[
                    { title: 'Libelle', field: 'libelle' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={6}>
            <Liste 
                urlBase='type-operations'
              // table='type_reglement' 
                values={typeOperations} 
                setValues={setTypeOperations}
                colonnes={[
                    { title: 'Libelle', field: 'libelle' },
                    { title: 'Signe', field: 'signe' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={7}>
            <Liste 
                urlBase='marques'
                table='marque' 
                values={marques} 
                setValues={setMarques}
                colonnes={[
                    { title: 'Nom', field: 'nom' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={8}>
            <Liste 
                urlBase='carburants'
                table='carburant' 
                values={carburants} 
                setValues={setCarburants}
                colonnes={[
                    { title: 'Libelle', field: 'libelle' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={9}>
            <Liste 
                urlBase='type-chargements'
                // table='carburant' 
                values={typeChargements} 
                setValues={setTypeChargements}
                colonnes={[
                    { title: 'Libelle', field: 'libelle' },
                    { title: 'Code', field: 'code' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={10}>
            <Liste 
                urlBase='type-mouvements'
                // table='carburant' 
                values={typeMouvements} 
                setValues={setTypeMouvements}
                colonnes={[
                    { title: 'Libelle', field: 'libelle' },
                    { title: 'Signe', field: 'signe' },
                ]}
            />
      </TabPanel>
      <TabPanel value={value} index={11}>
            <Liste 
                urlBase='lien-responsables'
                titel='Liste lien responsable'
                // table='carburant' 
                values={typeLiens} 
                setValues={setTypeLiens}
                colonnes={[
                    { title: 'Libelle', field: 'libelle' },
                ]}
            />
      </TabPanel>

    </div>
  );
}