import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,Paper,
    TextField,Grid,Button } from '@material-ui/core';

import axios from '../../utils/axios';

import { AppContext } from '../../App';


import validate from '../../utils/validate'
import constraintUser from '../../Constraints/User';
import util from '../../utils/util';
import parametres from '../../config/parameters';

const useStyles = makeStyles(theme => ({
    root: {
        width: '500px',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    paper:{
        width:'100%'
    },
    title : {
        marginTop: theme.spacing(2)
    },
    textField: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))
export default function Reinitialisation(props){
    const classes = useStyles();
    const [user,setUser]=React.useState({password_actuel:'',password:'',password_confirmation:'',errors:{}})
    const appContext = React.useContext(AppContext);

    const handleChange = (e)=> {
        const u = {...user,[[e.target.name]]:e.target.value};
        setUser(u);
    }
    const reinitialisation = async ()=>{
        const errors = validate(user,constraintUser.reinitialisation);
        if(errors){
            setUser({...user,errors});
            return;
        }
        if(user.password!==user.password_confirmation){
            appContext.addMessage('La confirmation est differente de password','error');
            return;
        }
        try {
            await axios.put(parametres.API_URL+'/users/change-password',user);
            appContext.addMessage('Mot de passe modifié avec succès','success');
            appContext.logout();
            props.history.push('/login');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }
    return (
        <Container className={classes.root}>
            <Paper className={classes.paper}>
                <Typography variant="h5" color="inherit" align="center" className={classes.title}>
                        MODIFICATION DE MOT DE PASSE
                </Typography>
                <form action="">
                    <TextField
                        name="password_actuel"
                        label="password actuel"
                        className={classes.textField}
                        value={user.password_ancien}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        type="password"
                        error={user.errors['password_actuel']?true:false}
                        helperText={user.errors ? util.getMessageConstraintError(user.errors['password_actuel']):''}
                    />
                    <TextField
                        name="password"
                        label="Mot de passe"
                        className={classes.textField}
                        value={user.password}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        type="password"
                        error={user.errors['password']?true:false}
                        helperText={user.errors ? util.getMessageConstraintError(user.errors['password']):''}
                    />
                    <TextField
                        name="password_confirmation"
                        label="Confirmation"
                        className={classes.textField}
                        value={user.password_confirmation}
                        onChange={handleChange}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        type="password"
                        error={user.errors['password_confirmation']?true:false}
                        helperText={user.errors ? util.getMessageConstraintError(user.errors['password_confirmation']):''}
                    />
                </form>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                        <Button variant="contained" color="primary" className={classes.button} 
                            onClick={reinitialisation}
                        >
                            REINITIALISER
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
};