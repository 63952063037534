import moment from 'moment';
export default {
    add:{
        vehicule:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        chauffeur:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        date_debut:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            datetime: {
                dateOnly: true,
                latest:moment().format('YYYY-MM-DD'),
                message :"date incorrect"
            }
        },
        date_fin:{
            datetime: {
                dateOnly: true,
                latest:moment().format('YYYY-MM-DD'),
                message :"date incorrect"
            }
        },                    
    }
}