export default {
    add:{
        nom:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        effectif_commande:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        date_commande:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        }
    },
    addHangar:{
        hangar:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        effectif:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:0,
                message:" doit être superieur à 0"
            },
        }
    }
}