import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import omit from 'lodash/omit';
import { useHistory,useParams,useLocation } from 'react-router-dom';
// import moment from 'moment';
import find from 'lodash/find';
// import axios from 'axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/OperationFabrique';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';
import IconMaterialTable from '../../IconMaterialTable';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      },
}));
// const initDataDetail = {
//     article:"",
//     errors:[]
// }

export default function OperationClient(){
    const initData = {
        date_operation:"",
        details:[],
        errors:{}
    };
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [operation,setOperation] = React.useState({...initData})
    const [articles,setArticles] = React.useState([])
    const [articleProduit,setArticleProduit] = React.useState([])
    // const [unites,setUnites] = React.useState([])
    // const [typeMouvements,setTypeMouvements] = React.useState([])
    const refListe = React.useRef(null);
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();

    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        setOperation({
            ...operation,
            cout_production:0,
            cout_autre_charge:appContext.parametres.autres_charge_fabrique,
            marge:appContext.parametres.marge_fabrique,
            prix_vente:0,
        })
        try {
            const [articles,/*unites,typeMouvements*/] = await Promise.all([
                axios.get(`/articles`),
                // axios.get(`/unites`),
                // axios.get(`/type-mouvements`),
            ]);
            if(id){
                if(pathname===`/operations-fabriques/view/${id}`){
                    setView(true)
                }
                const {data} = await axios.get(`/operations-fabriques/${id}`);
                const operation = {
                        id: data.id,
                        date_operation : data.date_operation,
                        article_produit : data.article_produit.id,
                        unite_stockage : data.unite_stockage.libelle,
                        quantite_produite : data.quantite_produite,
                        cout_production:data.cout_production,
                        cout_autre_charge:data.cout_autre_charge,
                        marge:data.marge,
                        prix_vente:data.prix_vente,
                        description:data.description,
                        // details:data.detail_operations,
                        details:data.detail_operations.map(d=>({
                            ...d,
                            montant:util.separteurMillier(d.montant),
                            // unite_stockage:d.unite_stockage?d.unite_stockage.libelle:null
                        })),
                        errors:{}
                    }
                setOperation(operation)
                setArticleProduit(data.article_produit)
            }
            setArticles(articles.data.results)            
            // setUnites(unites.data.results)    
            // setTypeMouvements(typeMouvements.data.results)        
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        let data = {...operation,[e.target.name]:e.target.value}
        if(e.target.name==='article_produit'){
            const article = find(articles,a=>a.id===parseInt(e.target.value))
            if(article){
                data = {
                    ...operation,
                    'article_produit':article.id,
                    'unite_stockage':article.unite ? article.unite.libelle : null
                }
                setArticleProduit(article)
            }
        }
        else if(e.target.name==='quantite_produite'){
            data = update_cout(data,0)
        }
        setOperation(data)
    }
    const handleChangeDetail = (e,props)=>{
        let data = {...props.rowData};
        if(props.columnDef.field==='article'){
            const article = find(articles,a=>a.id===parseInt(e.target.value))
            data = {...data,
                [props.columnDef.field]:article,
                prix: article ? article.prix.toString() : null,
                unite_stockage:article.unite ? article.unite : null
            }
            props.onChange(article)
        }/*else if(props.columnDef.field==='type_mouvement'){
            const type_mouvement = find(typeMouvements,a=>a.id===parseInt(e.target.value))
            data = {...data,
                [props.columnDef.field]:type_mouvement,
            }
            props.onChange(type_mouvement)
        }*/
        else{
            data = {...data,[props.columnDef.field]:e.target.value}
            props.onChange(e.target.value);
        }
        data = {
            ...data,
            montant:(parseInt(data.quantite||0)*parseInt(data.prix||0)),
            montant_format:(parseInt(data.quantite||0)*parseInt(data.prix||0)).toLocaleString('fr-FR'),
            errors:{}
        }
        props.onRowDataChange(data)
    }
    const update_cout = (o)=>{
        let montant = 0;
        o.details.forEach(d=>montant = montant + d.montant);
        let cout_production = 0;
        if(o.quantite_produite && parseInt(o.quantite_produite)!==0)
            cout_production = o.quantite_produite  ? Math.round(montant/parseInt(o.quantite_produite)) : 0;
        
        const data = {
            ...o,
            cout_production: cout_production,
            prix_vente:cout_production+parseInt(o.marge)+parseInt(o.cout_autre_charge)
        }
        return data;
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(operation,constraint.add);
        if(errors){
            setOperation({...operation,errors});
            return;
        }else if(operation.details.length===0){
            appContext.addMessage("Aucun article",'error');
            return;
        }
        appContext.setOpenLoading(true);
        try {
            let a = {...omit(operation,['errors'])};
            a = {
                ...a,
                article_produit:articleProduit.id,
                unite_stockage:articleProduit.unite.id,
            }
            if(!id){
                a.detail_operations = a.details.map(d=>({
                    id:d.id,
                    article:d.article.id,
                    // type_mouvement:d.type_mouvement.id,
                    unite_stockage:d.article.unite ? d.article.unite.id : null,
                    quantite:d.quantite,
                    prix:d.prix,
                }));
                await axios.post(`/operations-fabriques`,a);
                appContext.addMessage('Enregistrement effectué avec succès','success');
            }else{
                a.detail_operations = a.details.map(d=>({
                    id:d.id,
                    article:d.article.id,
                    // type_mouvement:d.type_mouvement.id,
                    unite_stockage:d.unite_stockage ? d.unite_stockage.id : null,
                    quantite:d.quantite,
                    prix:d.prix,
                }));
                await axios.put(`/operations-fabriques/${a.id}`,a);
                appContext.addMessage('Modification effectuée avec succès','success');
            }
            setOperation({...initData})
            history.push("/liste-operations-fabriques")
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }  
        appContext.setOpenLoading(false);  
    }

    const colonnes = [
        { title: 'N°', field: 'index', editable:'never',
            read_only:true,width:'0.5%',
            cellStyle:{witheSpace:'nowrap'},
            // headerStyle:{width:300,maxWidth:300},
            // initialEditValue:operation.details.length+1,
            render: rowData => rowData ? rowData.tableData.id+1 : operation.details.length+1
        },/*
        { title: 'Type', field: 'type_mouvement',
            editComponent: props => (
                <MySelectField 
                    name="type_mouvement"
                    value={props.value && props.value.id}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    options={typeMouvements}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['type_mouvement'] ? rowData['type_mouvement'].libelle : ""
        },*/
        { title: 'Article', field: 'article',
            editComponent: props => (
                <MySelectField 
                    name="article"
                    value={props.value && props.value.id}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    options={articles}
                    libelle="nom"
                    label=" "
                />
            ),
            render:rowData=>rowData['article'] ? rowData['article'].nom : ""
        },
        { title: 'Quantite', field: 'quantite',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    inputProps={{
                        min:"0"
                    }}
                    name="quantite"
                    value={props.value||0}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    type="number"
                    label=" "
                />
            )
        },
        { title: 'Prix', field: 'prix',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    inputProps={{
                        min:"0"
                    }}
                    name="prix"
                    value={props.value||0}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    type="number"
                    label=" "
                />
            )
        },
        { title: 'Unite', field: 'unite_stockage.libelle',editable:'never' },
        { title: 'Montant', field: 'montant',editable:'never',
            read_only:true,initialEditValue:"0",
        // render:rowData=>rowData.montant && util.separteurMillier(rowData.montant)
        },
    ];

    const rowAdd =  (newData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.detail);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    let v = {...operation}
                    v.details.push({...newData,errors:{}});
                    v = update_cout(v)
                    setOperation(v)
                    resolve();
                }      
            } catch (error) {
                reject()
            }
        })
    }
    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.detail);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    const v = {...operation}
                    v.details = v.details.map(d=>d.tableData.id===oldData.tableData.id?newData:d);
                    setOperation(update_cout(v))
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }
    const rowDelete = (oldData)=>{
        return new Promise((resolve,reject)=>{
            const ds = [...operation.details];
            let details = ds.filter(d=>d.tableData.id!==oldData.tableData.id)
            details = details.map((ds,i)=>({...ds,index:i+1}));
            const v = {...operation,details}
            setOperation(update_cout(v))
            resolve()
        })
    }
    const editable = ()=>!view ? ({
        onRowUpdate:rowUpdate,
        onRowAdd:rowAdd,
        onRowDelete:rowDelete
    }):{}
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? view ? "Consultation": "Modification" : "Saisie"} Operation
                </Typography>
                <Grid container spacing={3}>  
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_operation"
                            label="Date"
                            value={operation.date_operation}
                            onChange={handleChange}
                            errors={operation.errors}
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MySelectField 
                            name="article_produit"
                            value={operation.article_produit}
                            onChange={handleChange}
                            errors={operation.errors}
                            options={articles}
                            libelle="nom"
                            disabled={view}
                            label="Article produit"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={6}>
                        <MyTextField 
                            name="unite_stockage"
                            value={operation.unite_stockage}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Unité stockage"
                            variant="outlined"
                            disabled={true}
                            required={false}
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="quantite_produite"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.quantite_produite):operation.quantite_produite}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Quantité produite"
                            disabled={view}
                            // style={{}}
                        />                    
                    </Grid> 
                    <Grid item xs={6} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="cout_production"
                            type={"text"}
                            value={util.separteurMillier(operation.cout_production)}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Coût de production"
                            disabled={true}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="marge"
                            type={"text"}
                            value={util.separteurMillier(operation.marge)}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Marge"
                            disabled={true}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="cout_autre_charge"
                            type={"text"}
                            value={util.separteurMillier(operation.cout_autre_charge)}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Autres charge"
                            disabled={true}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="prix_vente"
                            type={"text"}
                            value={util.separteurMillier(operation.prix_vente)}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Prix vente"
                            disabled={true}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <MyTextField 
                            name="description"
                            value={operation.description}
                            onChange={handleChange}
                            errors={operation.errors}
                            // label="Description de l'achat"
                            multiline
                            rows="2"
                            variant="outlined"
                            disabled={view}
                            required={false}
                            />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <MaterialTable
                            title={`Liste des articles`}
                            icons={IconMaterialTable}
                            columns={colonnes}
                            data={operation.details}
                            tableRef={refListe}
                            editable={editable()}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize:5,
                                pageSizeOptions:[5,10,15],
                                // debounceInterval:1000,
                                filtering:false,
                                search:false
                            }}
                        />
                    </Grid>

                    
                    {!view && // pas de modification du type de reglement sauf dans les règlements
                    <React.Fragment>
                        <Grid item xs={6} sm={6} lg={3}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={enregistrer}
                            >
                                {id ? 'Modifier' : 'Enregistrer'}
                            </Button>
                        </Grid>
                    </React.Fragment>
                    }
                </Grid>
            </div>
        </Container>
    )
};