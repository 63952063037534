import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

import Liste from '../Codification/Liste';
import util from '../../utils/util';
import ModalLivraison from '../Livraison/ModalLivraison';
import ModalPaiement from '../Paiement/ModalPaiement';

export default function ListeCommande(){
    const [commandes,setCommandes] = React.useState([]);
    const [commandeSelected,setCommandeSelected]= React.useState({});
    const history = useHistory();
    const [openModalLivraison,setOpenModalLivraison] = React.useState(false);
    const [openModalPaiement,setOpenModalPaiement] = React.useState(false);

    const toggleOpenModalLivraison = ()=>setOpenModalLivraison(!openModalLivraison);
    const toggleOpenModalPaiement = ()=>setOpenModalPaiement(!openModalPaiement);




   
    const colonnes = [
        { title: 'Date', field: 'date_commande', type:'date',
            filterComponent: props =>
            <TextField
                id="date"
                name="date_commande"
                type="date"
                value={props.columnDef.tableData.filterValue||""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
            />
    
        },
        { title: 'Fournisseur', field: 'fournisseur.nom'},
        { title: 'Montant commandé', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowData=>util.separteurMillier(rowData.montant)
        },
        { title: 'Montant payé', field: 'montant_paye',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            // render:rowData=>util.separteurMillier(rowData.montant_paye)
            render:rowData=><Button onClick={e=>{setCommandeSelected(rowData);toggleOpenModalPaiement()}} className="m-2" color="default">{util.separteurMillier(rowData.montant_paye)}</Button>
        },
        { title: 'Montant livré', field: 'montant_livre',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            // render:rowData=>util.separteurMillier(rowData.montant_livre||0),
            render:rowData=><Button onClick={e=>{setCommandeSelected(rowData);toggleOpenModalLivraison()}} className="m-2" color="default">{util.separteurMillier(rowData.montant_livre)}</Button>

        },
    ];
    return (
        <React.Fragment>
            <Liste table='commande' 
                values={commandes} 
                setValues={setCommandes}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        tooltip: 'Nouvelle commande',
                        isFreeAction: true,
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        onClick: (event, rowData) => history.push("/commandes")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher',
                        onClick: (event, rowData) => history.push(`/commandes/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier',
                        onClick: (event, rowData) => history.push(`/commandes/${rowData.id}`)
                    },
                    rowData=>({
                        icon: 'add_shopping_cart',
                        iconProps:{style:{color:yellow[500]}},
                        tooltip: 'Ajouter une livraison',
                        onClick: (event, rowData) => history.push(`/commandes/${rowData.id}/livraison`)
                    }),
                    rowData=> ({
                        icon: 'payment',
                        iconProps:{style:{color:green[500]}},
                        tooltip: 'Ajouter un paiement',
                        onClick: (event, rowData) => history.push(`/commandes/${rowData.id}/paiement`)
                    }),

                ]}

            />
            <ModalLivraison livraisons={commandeSelected.livraisons} open={openModalLivraison} toggleOpen={toggleOpenModalLivraison}/>
            <ModalPaiement paiements={commandeSelected.paiements} open={openModalPaiement} toggleOpen={toggleOpenModalPaiement}/>
        </React.Fragment>

    )
};

