import React from 'react';

import Liste from '../Codification/Liste';
// import find from 'lodash/find';
// import MySelectField from '../Codification/MySelectField';
// import { AppContext } from '../../App';
// import axios from '../../utils/axios';
// import util from '../../utils/util';
// import MyTextField from '../Codification/MyTextField';


export default function ListeArticle(){
    const [hangars,setHangars] = React.useState([]);
    // const [bandes,setBandes] = React.useState([]);
    // const appContext = React.useContext(AppContext);

    // const fetchData = async ()=>{
    //     appContext.setOpenLoading(true);
    //     try {
    //         const [bandes] = await Promise.all([
    //             axios.get(`/bandes`),
    //         ]);
    //         setBandes(bandes.data.results)            
    //     } catch (error) {
    //         console.log(error,error.response);
    //         appContext.addMessage(util.getContentError(error),'error');
    //     }
    //     appContext.setOpenLoading(false);
    // }
    // React.useEffect(()=>{
    //     fetchData();
    //     // eslint-disable-next-line
    // },[]);
    // const handleChange = (e,valeur,props)=>{
    //     const listes = {
    //         bande:bandes,
    //     }
    //     if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
    //         if(valeur===""){
    //             props.onChange(valeur)
    //         }else{
    //             const values = listes[props.columnDef.field];
    //             const value = find(values,v=>v.id===parseInt(valeur));
    //             if(value){
    //                      props.onChange(value)
    //             }
    //         }
    //     }else{
    //         props.onChange(e.target.value);
    //     }
    // }

    const colonnes = [
            { title: 'Libelle', field: 'libelle' },
            // { title: 'Bande', field: 'bande',
            // editComponent: props => (
            //     <MySelectField 
            //         name="bande"
            //         value={props.value && props.value.id}
            //         onChange={(e,v)=>handleChange(e,v,props)}
            //         errors={props.rowData.errors||[]}
            //         options={[{id:"",nom:""},...bandes]}
            //         libelle="nom"
            //         label=" "
            //     />
            // ),
            // render:rowData=>rowData['bande'] ? rowData['bande'].nom : ""
            // },
            // { title: 'Effectif', field: 'effectif',
            // editComponent: props => (
            //     <MyTextField 
            //         inputProps={{
            //             min:"0"
            //         }}
            //         name="effectif"
            //         value={props.value||0}
            //         onChange={(e,v)=>handleChange(e,v,props)}
            //         errors={props.rowData.errors||[]}
            //         type="number"
            //         label=" "
            //     />
            // )

            // },
];
    return (
            <Liste table='hangar' 
                values={hangars} 
                setValues={setHangars}
                colonnes={colonnes}
                transformDataBeforeSent={(hangar)=>{
                    const bande = hangar.bande ? hangar.bande.id : ""
                    return {...hangar,bande}
                }}

            />
    )
};

