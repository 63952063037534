import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
// import { green } from '@material-ui/core/colors';
// import Button from '@material-ui/core/Button';
import Liste from '../Codification/Liste';
// import MyDateField from '../Codification/MyDateField';
import util from '../../utils/util';
import green from '@material-ui/core/colors/green';


export default function ListeEleve(){
    const [inscriptions,setInscriptions] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Matricule', field: 'eleve.matricule' },
        { title: 'Nom', field: 'eleve.full_name' },
        { title: 'Classe', field: 'eleve.salle.nom' },
        { 
            title: 'Frais', field: 'montant_a_payer',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant_a_payer) 
        },
        { 
            title: 'Payé', field: 'montant_paye',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant_paye) 
        },
        { 
            title: 'Reste', 
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant_a_payer-rowdata.montant_paye) 
        },
    ];
    return (
            <Liste table='inscription' 
                values={inscriptions} 
                setValues={setInscriptions}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    rowData=> ({
                        icon: 'payment',
                        iconProps:{style:{color:green[500]}},
                        tooltip: 'Gestion paiements',
                        onClick: (event, rowData) => history.push(`/inscriptions/${rowData.id}/paiements`)
                    }),
                ]}

            />
    )
};

