import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Chargement';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }, 
}))
const initData = {
    date_achat:"",
    salaire:0,
    typeChargement:"",
    errors:{}
};
export default function Chargement(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [chargement,setChargement] = React.useState({...initData})
    const [typeChargements,setTypeChargements] = React.useState([])
    const [chargementParents,setChargementParents] = React.useState([])
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [typeChargement,chargementParents] = await Promise.all([
                axios.get(`/type-chargements`),
                axios.get(`/chargements?type_chargement.libelle=sable`),
            ]);
            if(id){
                const {data} = await axios.get(`/chargements/${id}`);
                let chargement = {
                        id: data.id,
                        date_achat:data.date_achat,
                        type_chargement:data.type_chargement ? data.type_chargement.id : null,
                        montant:data.montant,
                        description: data.description,
                        chargement_parent:data.chargement_parent ? data.chargement_parent.id : null,
                        errors:{}
                    }
                if(pathname===`/chargements/view/${id}`){
                    setView(true);
                    chargement = {
                        ...chargement,
                        type_chargement:data.type_chargement,
                        chargement_parent:data.chargement_parent,
                    }
                }
                setChargement(chargement)
            }
            setTypeChargements(typeChargement.data.results)       
            setChargementParents(chargementParents.data.results)     
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{

        setChargement({...chargement,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(chargement,constraint.add);
        if(errors){
            setChargement({...chargement,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/chargements`,{...omit(chargement,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setChargement({...initData})
            }else{
                history.push("/liste-chargements")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(chargement,constraint.add);
        if(errors){
            setChargement({...chargement,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/chargements/${chargement.id}`,{...omit(chargement,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Opération
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_achat"
                            value={chargement.date_achat||""}
                            onChange={handleChange}
                            errors={chargement.errors}
                            label="Date achat"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="type_chargement"
                                value={chargement.type_chargement && chargement.type_chargement.libelle}
                                label="Type"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="type_chargement"
                                value={chargement.type_chargement}
                                onChange={handleChange}
                                errors={chargement.errors}
                                options={typeChargements}
                                libelle="libelle"
                                disabled={view}
                                label="Type"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(chargement.montant):chargement.montant}
                            onChange={handleChange}
                            errors={chargement.errors}
                            label="Montant"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>    
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="chargement_parent"
                                value={chargement.chargement_parent && chargement.chargement_parent.code}
                                label="Chargement sable"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="chargement_parent"
                                value={chargement.chargement_parent}
                                onChange={handleChange}
                                errors={chargement.errors}
                                options={chargementParents}
                                libelle="code"
                                disabled={view}
                                label="Chargement sable"
                            />
                        }
                    </Grid>                                                                                                               
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={chargement.description}
                            onChange={handleChange}
                            errors={chargement.errors}
                            label="Description"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            // required={false}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};