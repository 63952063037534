import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';
import util from '../../utils/util';

export default function ListeOperationVehicule(){
    const [operations,setOperations] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_operation', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date_operation"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Vehicule', field: 'vehicule.immatriculation' },
        { title: 'Recette', field: 'montant_recette',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant_recette)
        },
        { title: 'Mt.Carb', field: 'montant_carburant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant_carburant)
        },
        { title: 'Salaire', field: 'salaire',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.salaire)
        },
        { title: 'Km', field: 'nbre_km_parcouru',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.nbre_km_parcouru)
        },
        { title: 'Conso(Litre)', field: 'carburant_consomme',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.carburant_consomme)
        },        
        { title: 'Mt. Autres dépenses', field: 'autres_depenses',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.autres_depenses)
        },
        { title: 'Description', field: 'description' },
    ];
    return (
            <Liste table='operations-vehicule' 
                values={operations} 
                setValues={setOperations}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouvelle opération',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/operations-vehicules")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher opération',
                        onClick: (event, rowData) => history.push(`/operations-vehicules/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier operation',
                        onClick: (event, rowData) => history.push(`/operations-vehicules/${rowData.id}`)
                    },
                ]}

            />
    )
};

