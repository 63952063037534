import React from 'react';
import { useHistory } from 'react-router-dom';
import Liste from '../Codification/Liste';
// import { AppContext } from '../../App';
export default function ListeClasse(){
    const [classes,setClasses] = React.useState([]);
    const history = useHistory();
    const colonnes = [
        { title: 'Libelle', field: 'libelle' },
        // { title: 'Coût tranche', field: 'cout_tranche_scolarite' },
        { title: 'Frais Inscription', field: 'frais_inscription' },

    ];
    return (
            <Liste 
                table='classe' 
                values={classes} 
                setValues={setClasses}
                colonnes={colonnes}
                maxWidth={"md"}
                actions={[                
                    {
                        icon: 'holiday_village',
                        iconProps:{color:'primary'},
                        tooltip: 'Gestion des salles',
                        onClick: (event, rowData) => history.push(`/classes/${rowData.id}/salles`)
                    },
                    {
                        icon: 'payment',
                        iconProps:{color:'primary'},
                        tooltip: 'Gestion des tranches',
                        onClick: (event, rowData) => history.push(`/classes/${rowData.id}/tranches`)
                    },
                ]}
                // transformDataBeforeSent={data=>({...data,unite:data.unite ? data.unite.id: null})}
            />
    )
};

