import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';
import util from '../../utils/util';

export default function ListeOperation(){
    const [operations,setOperations] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_operation', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date_operation"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Type Operation', field: 'type_operation.libelle' },
        { title: 'Numero', field: 'numero_compte.libelle' },
        { title: 'Description', field: 'description' },
        { title: 'Dépôt', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>rowdata.type_operation.signe === '+' ? util.separteurMillier(rowdata.montant) : 0
        },
        { title: 'Retrait', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>rowdata.type_operation.signe === '-' ? util.separteurMillier(rowdata.montant) : 0
        },
    ];
    return (
            <Liste table='operation' 
                values={operations} 
                setValues={setOperations}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouvelle opération',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/operations")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher opération',
                        onClick: (event, rowData) => history.push(`/operations/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier operation',
                        onClick: (event, rowData) => history.push(`/operations/${rowData.id}`)
                    },
                ]}

            />
    )
};

