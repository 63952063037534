import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper,FormControl,FormHelperText } from '@material-ui/core';
import omit  from 'lodash/omit';
import find  from 'lodash/find';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Paiement';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    date_paiement:"",
    contrat:"",
    errors:{}
};
export default function Paiement(){
    const classes = useStyles();
    const history = useHistory();
    const { id,idContrat } = useParams()
    const appContext = React.useContext(AppContext);
    const [paiement,setPaiement] = React.useState({...initData})
    const [prestataires,setPrestataires] = React.useState([])
    const [prestataireContrats,setPrestataireContrats] = React.useState([])
    const [contrats,setContrats] = React.useState([])
    const [contratSelectionne,setContratSelectionne] = React.useState(null)
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [prestataires,contrats] = await Promise.all([
                axios.get(`/prestataires`),
                axios.get(`/contrats`),
            ]);
            setPrestataires(prestataires.data.results)            
            setContrats(contrats.data.results)            
            if(id){ // modification d'un paiement
                const {data} = await axios.get(`/paiements/${id}`);
                let paiement = {
                        id: data.id,
                        date_paiement:data.date_paiement,
                        prestataire: data.contrat.prestataire.id,
                        contrat:data.contrat.id,
                        montant:data.montant,
                        description: data.description,
                        errors:{}
                }
                if(pathname===`/paiements/view/${id}`){
                    setView(true);
                    paiement = {
                        ...paiement,
                        contrat:data.contrat
                    }
                }
                setPaiement(paiement)
                setPrestataireContrats(contrats.data.results.filter(c=>c.prestataire.id===data.contrat.prestataire.id && !data.contrat.flag_termine))
            }else if(idContrat){ // ajout d'un paiement à partir d'un contrat
                const contrat = find(contrats.data.results,c=>c.id===parseInt(idContrat))
                if(contrat){
                    setContratSelectionne(contrat)
                    setPrestataireContrats(contrats.data.results.filter(c=>c.prestataire.id===contrat.prestataire.id && !contrat.flag_termine))
                    let paiement = {
                        prestataire: contrat.prestataire.id,
                        contrat:contrat.id,
                        errors:{}
                    }
                    setPaiement(paiement)
                }
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        // if(!idContrat){ // pas besoin d'arrêter le loading, car la page est entièrement recharger
            appContext.setOpenLoading(false);
        // }
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        if(e.target.name==='prestataire'){
            const pcs = contrats.filter(c=>c.prestataire.id===parseInt(e.target.value) && !c.flag_termine)
            setPrestataireContrats(pcs);
            setContratSelectionne(null);
        }else if(e.target.name === 'contrat'){
            const c = find(contrats,c=>c.id===e.target.value);
            setContratSelectionne(c)
        }
        setPaiement({...paiement,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(paiement,constraint.add);
        if(errors){
            setPaiement({...paiement,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/paiements`,{...omit(paiement,['errors','prestataire'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                if(!idContrat) setPaiement({...initData})
                else fetchData()
            }else{
                history.goBack()
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(paiement,constraint.add);
        if(errors){
            setPaiement({...paiement,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/paiements/${paiement.id}`,{...omit(paiement,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Paiement
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_paiement"
                            value={paiement.date_paiement||""}
                            onChange={handleChange}
                            errors={paiement.errors}
                            label="Date paiement"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="prestataire"
                                value={paiement.contrat.prestataire && paiement.contrat.prestataire.full_name}
                                label="Prestataire"
                                disabled={view}
                            />
                        :
                            <MySelectField 
                                name="prestataire"
                                value={paiement.prestataire}
                                onChange={handleChange}
                                errors={paiement.errors}
                                options={prestataires}
                                libelle="full_name"
                                disabled={view}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {view ?
                            <MyTextField 
                                name="contrat"
                                value={paiement.contrat && paiement.contrat.objet}
                                label="Objet contrat"
                                multiline
                                rows="4"
                                variant="outlined"
                                disabled={view}
                                required={false}
                            />
                        :
                        <FormControl fullWidth>
                            <MySelectField 
                                name="contrat"
                                value={paiement.contrat}
                                onChange={handleChange}
                                errors={paiement.errors}
                                options={prestataireContrats}
                                libelle="objet"
                                disabled={view}
                            />
                            {contratSelectionne &&
                                <React.Fragment>
                                    <FormHelperText>Montant contrat:{util.separteurMillier(contratSelectionne.montant)}</FormHelperText>
                                    <FormHelperText>Montant restant:{util.separteurMillier(contratSelectionne.montant-contratSelectionne.montant_paye)}</FormHelperText>
                                </React.Fragment>
                            }
                        </FormControl>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={paiement.description}
                            onChange={handleChange}
                            errors={paiement.errors}
                            label="Description du paiement"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            required={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(paiement.montant):paiement.montant}
                            onChange={handleChange}
                            errors={paiement.errors}
                            label="Montant du paiement"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};