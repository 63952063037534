import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import {IconButton} from '@material-ui/core';

import UndoIcon from '@material-ui/icons/Undo';


const HeaderSearch = () => {
  const history = useHistory();

  return (
    <Fragment>
      <IconButton
        size="medium"
        onClick={e=>history.goBack()}
        color="inherit"
        className="btn-inverse font-size-xs">
        <UndoIcon />
      </IconButton>
    </Fragment>
  );
};

export default HeaderSearch;
