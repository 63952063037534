import React from 'react';
import { useHistory } from 'react-router-dom';

import { AppContext } from '../../App';


export default function Logout(){
    const appContext = React.useContext(AppContext);
    const history = useHistory();
    React.useEffect(()=>{
        appContext.logout();
        history.push('/login');
        
        // eslint-disable-next-line
    },[]);
    return (
       <React.Fragment>

       </React.Fragment>
    )
};