import React from 'react';
import {Grid,Button,Card,CardContent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import find from 'lodash/find';
// import axios from 'axios';

import { AppContext } from '../../App';
import {MySelectField} from '../Codification/MySelectField'
import axios from '../../utils/axios';
import util from '../../utils/util';
import parametres from '../../config/parameters';

export default function SelectionApplication(){
    const history = useHistory();
    const appContext = React.useContext(AppContext);
    const [appSelected,setAppSelected] = React.useState(null);



    const handleChange = (e)=> {
        const app = find(appContext.apps,{id:e.target.value});
        setAppSelected(app?app:null);
    }
    const selectionner = async ()=>{
        try {
            appContext.setOpenLoading(true);
            // console.log(axios.defaults.headers
            const res = await axios.get(parametres.API_URL+`/users/my-info`);
            appContext.setUser(res.data);
            const app = find(res.data.apps,a=>a.id===appSelected.id)
            if(app){
                app.projets = [{id:-1,nom:'GLOBAL',key:null},...app.projets]
                // res.data.user.apps = res.data.user.apps.map(app=>({...app,projets:[{id:-1,nom:'GLOBAL',key:null},...app.projets]}))
                localStorage.setItem('app',JSON.stringify(app))
                appContext.setAppSelected(app);
                if(app.projets.length===1){
                    const projet = app.projets[0]
                    localStorage.setItem('projet',JSON.stringify(projet));
                    appContext.setProjetSelected(projet)
                    history.push('/');
                  }else{
                    history.push('/selection-projet');
                  }
            }else{

                appContext.addMessage('Application introuvable','error');
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);

        // localStorage.setItem('app',JSON.stringify(appSelected));
        // appContext.setAppSelected(appSelected);
    }
    return (
        <React.Fragment>
        <div className="app-wrapper min-vh-100">
        <div className="app-main flex-column">
            <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5 ">
                    <Grid container style={{flexDirection:'row',justifyContent:'center'}}>
                        <Grid
                        item
                        xs={12}
                        lg={4}
                        className="d-flex flex-column align-items-center">
                        <span className="w-100 text-left text-md-center pb-4">
                            <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                                Applications
                            </h1>
                        </span>
                        <Card className="m-0 w-100 p-0 border-0">
                            <CardContent className="p-3">
                            <form className="px-5">
                                <MySelectField 
                                    name="app"
                                    value={appSelected?appSelected.id:''}
                                    onChange={handleChange}
                                    errors={[]}
                                    options={appContext.apps}
                                    libelle="libelle"
                                    label=" "
                                />

                                <div className="text-center">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        className="my-2"
                                        onClick={selectionner}
                                        disabled={appSelected===null}
                                    >
                                        Valider
                                    </Button>
                                </div>
                            </form>
                            </CardContent>
                        </Card>
                        </Grid>
                    </Grid>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        </React.Fragment>

    )
};