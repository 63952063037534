import React from 'react';

import Liste from '../Codification/Liste';


export default function ListePrestataire(){
    const [prestataires,setPrestataires] = React.useState([]);
   
    const colonnes = [
        { title: 'Nom', field: 'nom' },
        { title: 'Prenoms', field: 'prenom' },
        { title: 'Telephone', field: 'telephone' },
        { title: 'Email', field: 'email',emptyValue:()=>""},
        { title: 'Adresse', field: 'adresse',emptyValue:()=>""},
    ];
    return (
            <Liste table='prestataire' 
                values={prestataires} 
                setValues={setPrestataires}
                colonnes={colonnes}

            />
    )
};

