export default {
    add:{
        nom:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        // unite_stockage:{
        //     presence: {
        //         allowEmpty: false,
        //         message:"est obligatoire"
        //     }
        // },
        // quantite:{
        //     presence: {
        //         allowEmpty: false,
        //         message:"est obligatoire"
        //     }
        // },
        
    }
}