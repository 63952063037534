import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Mouvement';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }, 
}))
const initData = {
    date_mouvement:"",
    type_mouvement:"",
    article:"",
    unite:"",
    errors:{}
};
export default function Mouvement(){
    const classes = useStyles();
    const history = useHistory();
    const appContext = React.useContext(AppContext);
    const [typeMouvements,setTypeMouvements] = React.useState([])
    const [article,setArticle] = React.useState([]);
    const [mouvement,setMouvement] = React.useState({...initData})
    const {idArticle} = useParams();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [typeMouvements,article] = await Promise.all([
                axios.get(`/type-mouvements`),
                axios.get(`/articles/${idArticle}`),
            ]);
            if(article){
                setMouvement({...mouvement,article:article.data.nom,unite:article.data.unite.libelle})
            }
            setTypeMouvements(typeMouvements.data.results);
            setArticle(article.data)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setMouvement({...mouvement,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(mouvement,constraint.add);
        if(errors){
            setMouvement({...mouvement,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            setMouvement({...mouvement,article:article.id,unite:article.unite.id})
            let mvt = {
                ...mouvement,
                article:article.id,
                unite:article.unite.id
            }
            await axios.post(`/mvt-stocks`,{...omit(mvt,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setMouvement({...initData})
            }else{
                history.push(`/articles/${idArticle}/mvt-stocks`)
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    Saisie Mouvement
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_mouvement"
                            value={mouvement.date_mouvement||""}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Date mouvement"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MySelectField 
                            name="type_mouvement"
                            value={mouvement.type_mouvement}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            options={typeMouvements}
                            libelle="libelle"
                            label="Type mouvement"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            name="article"
                            value={mouvement.article}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Article"
                            variant="outlined"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            name="unite"
                            value={mouvement.unite}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Unite"
                            variant="outlined"
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="quantite"
                            type={"number"}
                            value={mouvement.quantite}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Quantite"
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={mouvement.description}
                            onChange={handleChange}
                            errors={mouvement.errors}
                            label="Description du mouvement"
                            multiline
                            rows="4"
                            variant="outlined"
                            required={true}
                        />
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={()=>enregistrer(true)}
                            >
                                Enregistrer & Nouveau
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={()=>enregistrer(false)}
                            >
                                Enregistrer & Quitter
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
};