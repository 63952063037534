import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable from 'material-table';
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container';

import { AppContext } from '../../App';
import util from '../../utils/util';
import MySelectField from '../Codification/MySelectField';
import constraint from '../../Constraints/Bande';
import validate from '../../utils/validate';
import find from 'lodash/find';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
// import MTableToolbar from 'material-table/dist/components/m-table-toolbar';
const useStyles = makeStyles(theme => ({
    root: {
        // width: '80%',
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(1),
        overflowX: 'auto' ,
        marginLeft:theme.spacing(2)
    },
    paper:{ paddingLeft: 5 },
}))


export default function MiniListeHangars({bande,...rest}){
    const classes = useStyles();
    const [hangars] = React.useState(rest.hangars||[]);
    const appContext = React.useContext(AppContext);

    const handleChange = (e,valeur,props)=>{
        const listes = {
            hangar:rest.refHangars,
        }
        if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
            if(valeur===""){
                props.onChange(valeur)
            }else{
                const values = listes[props.columnDef.field];
                const value = find(values,v=>v.id===valeur);
                if(value){
                    props.onChange(value)
                }
            }
        }else{
            props.onChange(e.target.value);
        }
    }
   
    const colonnes = [
            { title: 'Hangar', field: 'hangar',
            editComponent: props => (
                <MySelectField 
                    name="hangar"
                    value={props.value && props.value.id}
                    onChange={(e,v)=>handleChange(e,v,props)}
                    errors={props.rowData.errors||[]}
                    options={rest.refHangars||[]}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['hangar'] ? rowData['hangar'].libelle : ""
            },
            { title: 'Effectif', field: 'effectif',},
            { title: 'Mortalite', field: 'mortalite',editable:'never'},
    ];

    const rowAdd = async (newData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.addHangar);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    let data = {
                        effectif:newData.effectif
                    }
                    rest.addHangar(bande,newData.hangar,data)
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject()
            }
        })
    }
    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.addHangar);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    let data = {
                        id: newData.id,
                        effectif:newData.effectif
                    }
                    rest.updateHangar(bande,newData.hangar,data)
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }

    return (
        <Container maxWidth="md" className={classes.root}>
                    <MaterialTable
                        title={null}
                        columns={colonnes}
                        data={hangars}
                        components={{
                            Container: props => <Paper elevation={0} {...props}/>,
                            // Toolbar:props=><MTableToolbar {...props}/>
                        }}
                        editable={{
                            onRowUpdate:rowUpdate,
                            onRowAdd:rowAdd,
                        }}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize:5,
                            filtering:false,
                            search:false,
                            paging:false
                        }}
                        actions={[
                            rowData=> rowData.is_active && ({
                                icon: 'link_off',
                                iconProps:{style:{color:red[500]}},
                                tooltip: 'Retirer de la bande',
                                onClick: (event, rowData) =>appContext.setConfirmationMessage({
                                    content:'Voulez-vous retirer ce hangar de cette bande',
                                    title:'Confirmation',
                                    valider: async ()=>{rest.retrait(bande,rowData.hangar);appContext.setOpenConfirmationMessage(false)},
                                    annuler: () => appContext.setOpenConfirmationMessage(false)
                                })
                            }),
                            rowData=> !rowData.is_active && ({
                                icon: 'link',
                                iconProps:{style:{color:green[500]}},
                                tooltip: 'Attacher à la bande',
                                onClick: (event, rowData) =>appContext.setConfirmationMessage({
                                    content:'Voulez-vous attacher ce hangar de cette bande',
                                    title:'Confirmation',
                                    valider: async ()=>{rest.attacher(bande,rowData.hangar);appContext.setOpenConfirmationMessage(false)},
                                    annuler: () => appContext.setOpenConfirmationMessage(false)
                                })
                            }),
                        ]}
                    />
        </Container>

    )
};

