import React from 'react';
import { useParams,useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
// import MaterialTable from 'material-table';

import Liste from '../Codification/Liste';
import util from '../../utils/util';
import axios from '../../utils/axios';
import { AppContext } from '../../App';

// import ModalPaiement from '../Paiement/ModalPaiement';
const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      },
}));

export default function SitutationContratLocation(){
    const appContext = React.useContext(AppContext);
    const classes = useStyles();
    // const [contratLocationSelected,setContratLocationSelected]= React.useState({});
    const [contratLocations,setContratLocations] = React.useState([]);
    const [contrat,setContrat] = React.useState(null);
    const history = useHistory();
    const {idContrat} = useParams()

    const colonnes = [
        { title: 'Annee', field: 'annee.valeur' },
        { title: 'Mois', field: 'mois.libelle' },
        { title: 'Montant', field: 'montant_paye',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant_paye)
        },
        { title: 'Reste', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant_a_payer-rowdata.montant_paye)
        },
    ];
    const fetchData = async (query)=>{
        appContext.setOpenLoading(true);
        try {
            const [contrat] = await Promise.all([
                axios.get(`/contrats-locations/${idContrat}`)
            ]);
            setContrat(contrat.data)
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                {contrat && 
                    <Typography variant="h3" gutterBottom>
                        {contrat.info}
                    </Typography>
                }
                <Grid container direction="row-reverse" spacing={3} style={{margin:5}} >
                    <Grid item xs={6} sm={4} lg={3}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={()=>history.goBack()}
                        >
                            Retour
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Liste urlBase={`contrats-locations/${idContrat}/paiements`} 
                        title='Situation des paiement'
                        // fetchData={fetchData}
                        values={contratLocations} 
                        setValues={setContratLocations}
                        colonnes={colonnes}
                        editable={{
                            onRowUpdate:null,
                            onRowAdd:null
                        }}
                        actions={[
                            {
                                icon: 'add_circle',
                                tooltip: 'Nouveau contrat',
                                iconProps:{style:{fontSize:50,color:green[500]}},
                                isFreeAction: true,
                                onClick: (event, rowData) => history.push(`/contrats-locations/${contrat.id}/paiements-locations`)
                            },
                            rowData=> ({
                                icon: 'payments',
                                iconProps:{style:{color:green[500]}},
                                tooltip: 'Ajouter un paiement',
                                onClick: (event, rowData) => history.push(`/contrats-locations/${contrat.id}/paiements-locations/${rowData.id}`)
                            })
                        ]}
                    />
                </Grid>
            </div>
        </Container>

    )
};

