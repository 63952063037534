import React from 'react';
import {NativeSelect,FormHelperText, InputLabel,FormControl,TextField} from '@material-ui/core';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete from '@material-ui/lab/Autocomplete';
import capitalize from 'lodash/capitalize';
import util from '../../utils/util'
import has from 'lodash/has'
import find from 'lodash/find'

export  function MySelectField({name,type,label,value,onChange,disabled=false,errors,...rest}){
    return(
        <FormControl fullWidth error={errors[[name]]?true:false}>
            <InputLabel shrink htmlFor={`${name}-native-label-placeholder`}>
                {label||capitalize(name)}
            </InputLabel>
            <NativeSelect
                value={value||""}
                disabled={disabled}
                onChange={onChange}
                name={name}
                inputProps={{
                    name: `${name}`,
                    id: `${name}-native-label-placeholder`,
                }}
            >
                <option value=""></option>
                {
                    rest.options && rest.options.map(o=>(
                        <option key={o.id} value={o.id}>{o[[rest.libelle]]}</option>
                    ))

                }
            </NativeSelect>
            <FormHelperText>{errors ? util.getMessageConstraintError(errors[[name]]):''}</FormHelperText>
        </FormControl>                

)
}
export default function MyAutocompleteField({name,type,label,value,onChange,disabled=false,errors,...rest}){
    const [options,setOptions] = React.useState(rest.options?rest.options:[])
    React.useEffect(()=>{
        setOptions(rest.options)
    },[rest.options])
    const getValue = (value)=>{
        if(!value) return "";
        const v = find(options,o=>o.id===value)
        if(v) return v;
        else return ""
    }
    return(
        <FormControl fullWidth error={errors[[name]]?true:false}>
            <InputLabel shrink htmlFor={`${name}-native-label-placeholder`}>
                {label||capitalize(name)}
            </InputLabel>
            <Autocomplete 
                options={options}
                getOptionLabel={option=>option[rest.libelle] ? option[rest.libelle].toString() : ""}
                id={`${name}`}
                renderInput={params => <TextField {...params} margin="normal" fullWidth />}
                onChange={(e,value)=>{e.target.name=name;e.target['value'] = has(value,'id') ? value['id'] :"" ;onChange(e,has(value,'id') ? value['id'] :"")}}
                name={name}
                disabled={disabled}
                value={getValue(value)}
                noOptionsText=""
            />
            <FormHelperText>{errors ? util.getMessageConstraintError(errors[[name]]):''}</FormHelperText>
        </FormControl>                

)
}