import React, { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';

import util from '../../utils/util';
import MaterialTable from 'material-table';
const ModalPaiement = ({paiements=[],open,toggleOpen}) => {
	const colonnes = [
        { title: 'Date', field: 'date_paiement', type:'date',      
        },
        { title: 'Description', field: 'description'},
        { title: 'Montant', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            render:rowdata=>util.separteurMillier(rowdata.montant) 
        },
    ];

	return (
		<Fragment>
			<Dialog scroll="body" maxWidth="md" open={open} onClose={toggleOpen}>
				<Grid container spacing={0}>
					<Grid item xs={12} lg={12}>
						<div className="bg-white ">
							<MaterialTable
									title="Liste des paiements"
									columns={colonnes}
									data={paiements}
									options={{
										actionsColumnIndex: -1,
										pageSize:5,
										filtering:false,
										search:false
									}}
                            />
						</div>
					</Grid>
				</Grid>
			</Dialog>

		</Fragment>
	);
};

export default ModalPaiement;
