import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';

import Liste from '../Codification/Liste';

export default function ListeMortalite(){
    const [mortalites,setMortalites] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_mortalite', type:'date',
            filterComponent: props =>
            <TextField
                id="date"
                name="date_mortalite"
                type="date"
                value={props.columnDef.tableData.filterValue||""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
            />
    
        },
        { title: 'Hangar', field: 'hangar_bande.hangar.libelle'},
        { title: 'Bande', field: 'hangar_bande.bande.nom'},
        { title: 'Nombre', field: 'nbre' },
        { title: 'Volailler', field: 'volailler.username' },
    ];
    return (
            <Liste table='mortalite' 
                values={mortalites} 
                setValues={setMortalites}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouveau mortalite',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/mortalites")
                    },
                    {
                        icon: 'view_headline',
                        iconProps:{style:{fontSize:50,color:orange[500]}},
                        tooltip: 'Mortalite journalier',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/mortalite-journalier")
                    },
                    {
                        icon: 'edit',
                        iconProps:{style:{color:yellow[500]}},
                        tooltip: 'Modifier mortalite',
                        onClick: (event, rowData) => history.push(`/mortalites/${rowData.id}`)
                    },
                ]}

            />
    )
};

