import React from 'react';
import { useHistory } from 'react-router-dom';
import Liste from '../Codification/Liste';
// import { AppContext } from '../../App';
// import find from 'lodash/find';
export default function ListeArticle(){
    // const appContext = React.useContext(AppContext);
    const [articles,setArticles] = React.useState([]);
    const history = useHistory();
    /*const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [unites] = await Promise.all([
                axios.get(`/unites`),
            ]);
            setUnites(unites.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);*/
    const colonnes = [
        { title: 'Article', field: 'article.nom' },
        { title: 'Unite', field: 'unite.libelle' },
        { title: 'Quantite', field: 'quantite' },
    ];
    return (
            <Liste 
                table='stock' 
                values={articles} 
                setValues={setArticles}
                colonnes={colonnes}
                maxWidth={"md"}
                editable={
                    {onRowUpdate:null,
                    onRowAdd:null}
                }
                actions={[                
                    {
                        icon: 'sync_alt',
                        iconProps:{color:'primary'},
                        tooltip: 'Mouvements stock',
                        onClick: (event, rowData) => history.push(`/articles/${rowData.article.id}/mvt-stocks`)
                    },
                ]}
                // transformDataBeforeSent={data=>({...data,unite:data.unite ? data.unite.id: null})}
            />
    )
};

