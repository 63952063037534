export default {
    rubriques:{
        rubrique:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        date_debut:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            datetime: {
                dateOnly: true,
                message :"date incorrect"
                // latest: moment.utc().subtract(18, 'years'),
                // message: "^You need to be at least 18 years old"
            }
        },
        montant:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThanOrEqualTo:0,
                message:" doit être superieur ou egal à 0"
            },
        },        
    }
}