import React from 'react';
import {useParams } from 'react-router-dom';
import Liste from '../Codification/Liste';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
import MySelectField from '../Codification/MySelectField';
import find from 'lodash/find';
import util from '../../utils/util';
import validate from '../../utils/validate';

const constraint = {
    tranche:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        }
    },    
    montant:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        },
        numericality:{
            greaterThan:-1,
            message:" doit être positif"
        },
    }
}

export default function ListeClasseUnite(){
    const appContext = React.useContext(AppContext);
    const [classe,setClasse] = React.useState([]);
    const [tranches,setTranches] = React.useState([])
    // const [trancheClasses,setTrancheClasses] = React.useState([])
    // const history = useHistory();
    const {id} = useParams();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [classe,tranches] = await Promise.all([
                axios.get(`/classes/${id}`),
                axios.get(`/tranches`),
                // axios.get(`/tranches-classes?classe.id=${id}`)
            ]);
            setClasse(classe.data)     
            setTranches(tranches.data.results)      
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e,props)=>{
        let data = {...props.rowData};
        const listes = {
            tranche:tranches,
        }
        if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
            const values = listes[props.columnDef.field];
            const value = find(values,v=>v.id===parseInt(e.target.value));
            if(value){
                data = {...data,
                    [props.columnDef.field]:value,
                }
                props.onChange(value)
            }
        }else{
            data = {...data,[props.columnDef.field]:e.target.value}
            props.onChange(e.target.value);
        }
        props.onRowDataChange(data)
    }
    const rowAdd =  (newData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    let r = {
                        tranche:newData.tranche.id,
                        montant:newData.montant,
                        classe:id
                    }
                    await axios.post(`/tranches-classes`,r);
                    appContext.addMessage('Enregistrement effectué avec succès','success');
                    resolve();
                }      
            } catch (error) {
                reject()
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');    
            }
        })
    }
    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            let data = {
                id:newData.id,
                montant:newData.montant,
                tranche:newData.tranche.id,
                classe:newData.classe.id,
            }
            try {
                const errors = validate(data,constraint);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    await axios.put(`/tranches-classes/${data.id}`,data);
                    appContext.addMessage('Modification effectuée avec succès','success');
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }
    const colonnes = [
        { title: 'Tranche', field: 'tranche', 
            editComponent: props => (
                <MySelectField 
                    name="tranche"
                    value={props.value && props.value.id}
                    onChange={e=>handleChange(e,props)}
                    errors={props.rowData.errors||[]}
                    options={tranches}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['tranche'] ? rowData['tranche'].libelle : ""
            },
        { title: 'Montant', field: 'montant' },
    ];
    return (
            <Liste  
                table='tranches-classe'
                // urlBase={`classe-unites?classe.id=${id}`}
                urlBaseGET={`tranches-classes?classe.id=${id}`}
                title={`Liste des tranches : ${classe.libelle}`}
                values={[]} 
                // setValues={setTranches}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:rowUpdate,
                    onRowAdd:rowAdd
                }}
                // maxWidth={"md"}
                transformDataBeforeSent={data=>({...data,classe:id})}
            />
    )
};

