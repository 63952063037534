import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';
import MiniListeHangar from '../Hangar/MiniListeHangar';
import axios from '../../utils/axios';
import { AppContext } from '../../App';
import util from '../../utils/util';


export default function ListeBandes(){
    const appContext = React.useContext(AppContext);
    const [bandes,setBandes] = React.useState([]);
    const [hangars,setHangars] = React.useState([]);
    const history = useHistory();
    const [reload,setReload] = React.useState(false)

    React.useEffect(()=>{
        if(reload) setReload(false)
    },[reload])

    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [hangars] = await Promise.all([
                axios.get(`/hangars`),
            ]);
            setHangars(hangars.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        // appContext.setOpenLoading(false);   
        fetchData();
        // eslint-disable-next-line
    },[]);

    const addHangar = async (bande,hangar,data) =>{
        appContext.setOpenLoading(true);
        try {
                await axios.post(`/bandes/${bande.id}/hangars/${hangar.id}`,data);
                appContext.addMessage('Enregistrement effectué avec succès','success');
              
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
        setReload(true)


    }
    const updateHangar = async (bande,hangar,data) =>{
        appContext.setOpenLoading(true);
        try {
                await axios.put(`/bandes/${bande.id}/hangars/${hangar.id}`,data);
                appContext.addMessage('Modification effectuée avec succès','success');
              
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
        setReload(true)


    }
    const retrait = async (bande,hangar) => {
        appContext.setOpenLoading(true);
        try {
            await axios.put(`/bandes/${bande.id}/hangars/${hangar.id}/retrait`);
            appContext.addMessage('Hangar retiré avec succès','success');
            // setReload(true) 
    } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
            // setReload(true)
        } 
        appContext.setOpenLoading(false);   
        setReload(true)
    }
    const attacher = async (bande,hangar) => {
        appContext.setOpenLoading(true);
        try {
            await axios.put(`/bandes/${bande.id}/hangars/${hangar.id}/attacher`);
            appContext.addMessage('Hangar attaché avec succès','success');
            // setReload(true)
    } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
            // setReload(true)
        } 
        appContext.setOpenLoading(false);   
        setReload(true)
    }
    const colonnes = [
        { title: 'Nom', field: 'nom'},
        { title: 'Date commande', field: 'date_commande', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Date acquisition', field: 'date_acquisition', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Eff.Commandé', field: 'effectif_commande',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.effectif_commande) 
        },
        { title: 'Eff.reçu', field: 'effectif_recu',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.effectif_recu) 
        },
        { title: 'Effectif', field: 'effectif',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.effectif) 
        },
        { title: 'Mortalite', field: 'mortalite',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.mortalite) 
        },
        { title: 'Date liquidation', field: 'date_liquidation', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
    },
    ];
    return (
            <Liste table='bande' 
                values={bandes} 
                setValues={setBandes}
                colonnes={colonnes}
                reload={reload}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                detailPanel={[
                    {
                        tooltip: 'Liste des hangars',
                        // icon:'account_balance',
                        isFreeAction:true,
                        render: rowData => {
                            return (
                                <MiniListeHangar bande={rowData} refHangars={hangars} setListeHangars={setHangars} 
                                    hangars={rowData.hangars} addHangar={addHangar} updateHangar={updateHangar}
                                    retrait={retrait} attacher={attacher}
                                    />
                            )
                        }
                    }
                ]}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouvelle bande',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/bandes")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher bande',
                        onClick: (event, rowData) => history.push(`/bandes/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier bande',
                        onClick: (event, rowData) => history.push(`/bandes/${rowData.id}`)
                    }
                ]}

            />
    )
};

