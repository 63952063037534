import React from 'react';
import {useParams } from 'react-router-dom';
import Liste from '../Codification/Liste';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
import MySelectField from '../Codification/MySelectField';
import MyTextField from '../Codification/MyTextField';
import find from 'lodash/find';
import util from '../../utils/util';
export default function ListeArticleUnite(){
    const appContext = React.useContext(AppContext);
    const [article,setArticle] = React.useState([]);
    const [articleUnites,setArticleUnites] = React.useState([]);
    const [unites,setUnites] = React.useState([])
    // const history = useHistory();
    const {id} = useParams();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [unites,article] = await Promise.all([
                axios.get(`/unites`),
                axios.get(`/articles/${id}`)
            ]);
            setUnites(unites.data.results) 
            setArticle(article.data)           
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e,props)=>{
        let data = {...props.rowData};
        const listes = {
            unite:unites,
        }
        if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
            const values = listes[props.columnDef.field];
            const value = find(values,v=>v.id===parseInt(e.target.value));
            if(value){
                data = {...data,
                    [props.columnDef.field]:value,
                }
                props.onChange(value)
            }
        }else{
            data = {...data,[props.columnDef.field]:e.target.value}
            props.onChange(e.target.value);
        }
        props.onRowDataChange(data)
        // setProduction({...production,[e.target.name]:e.target.value})
    }
    const colonnes = [
        { title: 'Unite', field: 'unite',
            editComponent: props => (
                <MySelectField 
                    name="unite"
                    value={props.value && props.value.id}
                    onChange={e=>handleChange(e,props)}
                    errors={props.rowData.errors||[]}
                    options={unites}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['unite'] ? rowData['unite'].libelle : ""
        },
        { title: 'Priorite', field: 'priorite',
            editComponent: props => (
                <MyTextField 
                    inputProps={{
                        min:"0"
                    }}
                    name="priorite"
                    value={props.value||0}
                    onChange={e=>handleChange(e,props)}
                    errors={props.rowData.errors||[]}
                    type="number"
                    label=" "
                />
            )
        },
        { title: 'Valeur unité supérieure', field: 'valeur_unite_superieur',
            editComponent: props => (
                <MyTextField 
                    inputProps={{
                        min:"0"
                    }}
                    name="valeur_unite_superieur"
                    value={props.value||0}
                    onChange={e=>handleChange(e,props)}
                    errors={props.rowData.errors||[]}
                    type="number"
                    label=" "
                />
            ),
            render:rowdata=>util.separteurMillier(rowdata.valeur_unite_superieur)
        },

    ];
    return (
            <Liste  
                table='article-unite'
                // urlBase={`article-unites?article.id=${id}`}
                urlBaseGET={`article-unites?article.id=${id}`}
                title={`Liste des unites : ${article.nom}`}
                values={articleUnites} 
                setValues={setArticleUnites}
                colonnes={colonnes}
                maxWidth={"md"}
                transformDataBeforeSent={data=>({...data,unite:data.unite? data.unite.id:null,article:id})}
            />
    )
};

