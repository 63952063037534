import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';

import Liste from '../Codification/Liste';
import util from '../../utils/util';

export default function ListeLivraison(){
    const [livraisons,setLivraisons] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date livraison', field: 'date_livraison', type:'date',
            filterComponent: props =>
            <TextField
                id="date"
                name="date_livraison"
                type="date"
                value={props.columnDef.tableData.filterValue||""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
            />
    
        },
        { title: 'Date Commande', field: 'commande.date_commande', type:'date',
            filterComponent: props =>
            <TextField
                id="date"
                name="date_commande"
                type="date"
                value={props.columnDef.tableData.filterValue||""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
            />
    
        },
        { title: 'Fournisseur', field: 'commande.fournisseur.nom'},
        { title: 'Montant', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowData=>util.separteurMillier(rowData.montant)
        },

    ];
    return (
            <Liste table='livraison' 
                values={livraisons} 
                setValues={setLivraisons}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        tooltip: 'Nouvelle livraison',
                        isFreeAction: true,
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        onClick: (event, rowData) => history.push("/livraisons")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher',
                        onClick: (event, rowData) => history.push(`/livraisons/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier',
                        onClick: (event, rowData) => history.push(`/livraisons/${rowData.id}`)
                    },
                ]}

            />
    )
};

