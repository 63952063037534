import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/AffectationVehicule';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }, 
}))
const initData = {
    date_affectation:"",
    vehicule:"",
    errors:{}
};
export default function AffectationVehicule(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [affectation,setAffectation] = React.useState({...initData})
    const [vehicules,setVehicules] = React.useState([])
    const [chauffeurs,setChauffeurs] = React.useState([])
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [vehicules,chauffeurs] = await Promise.all([
                axios.get(`/vehicules`),
                axios.get(`/chauffeurs`),
            ]);
            if(id){
                const {data} = await axios.get(`/affectation-vehicules/${id}`);
                let affectation = {
                        id: data.id,
                        vehicule:data.vehicule.id,
                        chauffeur:data.chauffeur.id,
                        date_debut:data.date_debut,
                        date_fin:data.date_fin,
                        errors:{}
                    }
                if(pathname===`/affectation-vehicules/view/${id}`){
                    setView(true);
                    affectation = {
                        ...affectation,
                        vehicule:data.vehicule,
                        chauffeur:data.chauffeur
                    }
                }
                setAffectation(affectation)
            }
            setVehicules(vehicules.data.results)            
            setChauffeurs(chauffeurs.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setAffectation({...affectation,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(affectation,constraint.add);
        if(errors){
            setAffectation({...affectation,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/affectation-vehicules`,{...omit(affectation,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setAffectation({...initData})
            }else{
                history.push("/liste-affectation-vehicules")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(affectation,constraint.add);
        if(errors){
            setAffectation({...affectation,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/affectation-vehicules/${affectation.id}`,{...omit(affectation,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Affectation
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="vehicule"
                                value={affectation.vehicule && affectation.vehicule.immatriculation}
                                label="Vehicule"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="vehicule"
                                value={affectation.vehicule}
                                onChange={handleChange}
                                errors={affectation.errors}
                                options={vehicules}
                                libelle="immatriculation"
                                disabled={view}
                                label="Véhicule"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="chauffeur"
                                value={affectation.chauffeur && affectation.chauffeur.full_name}
                                label="Chauffeur"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="chauffeur"
                                value={affectation.chauffeur}
                                onChange={handleChange}
                                errors={affectation.errors}
                                options={chauffeurs}
                                libelle="full_name"
                                disabled={view}
                                label="Chauffeur"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_debut"
                            value={affectation.date_debut||""}
                            onChange={handleChange}
                            errors={affectation.errors}
                            label="Date début"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_fin"
                            value={affectation.date_fin||""}
                            onChange={handleChange}
                            errors={affectation.errors}
                            label="Date fin"
                            disabled={view}
                        />
                    </Grid>

                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};