import React from 'react';
// import { useHistory } from 'react-router-dom';
import Liste from '../Codification/Liste';
import BulletinDetail from './BulletinDetail';

// import { AppContext } from '../../App';
import util from '../../utils/util';
// import find from 'lodash/find';

export default function ListeBulletins(props){
    // const appContext = React.useContext(AppContext);
    const [bulletins,setMois] = React.useState([]);
    // const history = useHistory();

    const colonnes = [
            { title: 'Annee', field: 'mois.annee' },
            { title: 'Mois', field: 'mois.libelle',editable:'never'},
            { title: 'Employé', field: 'employe.full_name',editable:'never', },
            { title: 'Montant', field: 'montant',editable:'never',filtering:false,
                cellStyle:{textAlign:"right"},
                headerStyle:{textAlign:'right'},
                render : rowData=>rowData && util.separteurMillier(rowData.montant)   
            },
    ];

    return (
            <Liste 
                title='Liste des bulletins'
                // urlBaseGET={props.url}
                url={props.url}
                values={bulletins} 
                setValues={setMois}
                colonnes={colonnes}
                editable={{}}
                detailPanel={[
                    {
                        tooltip: 'Details',
                        // icon:'account_balance',
                        isFreeAction:true,
                        render: rowData => {
                            return (
                                <BulletinDetail details={rowData.details} />
                            )
                        }
                    }
                ]}
                // actions={[
                //     {
                //         icon: 'view_headline',
                //         iconProps:{color:'primary'},
                //         tooltip: 'Liste des bulletins',
                //         onClick: (event, rowData) => history.push(`/contrats/view/${rowData.id}`)
                //     },
                // ]}
            />
    )
};

