import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper,FormControl,FormHelperText } from '@material-ui/core';
import omit  from 'lodash/omit';
import find  from 'lodash/find';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/ReglementVente';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    // date_paiement:moment().format('YYYY-MM-DD'),
    vente:"",
    errors:{}
};
export default function ReglementVente(){
    const classes = useStyles();
    const history = useHistory();
    const { id,idVente } = useParams()
    const appContext = React.useContext(AppContext);
    const [reglementVente,setReglementVente] = React.useState({...initData})
    const [clients,setClients] = React.useState([])
    const [typeReglements,setTypeReglements] = React.useState([])
    const [ventes,setVentes] = React.useState([])
    const [venteSelectionne,setVenteSelectionne] = React.useState(null)
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [clients,typeReglements] = await Promise.all([
                axios.get(`/clients`),
                axios.get(`/type-reglements`),
            ]);
            setClients(clients.data.results)            
            setTypeReglements(typeReglements.data.results)            
            if(id){ // modification d'un reglementVente
                const {data} = await axios.get(`/reglements-ventes/${id}`);
                let reglementVente = {
                        id: data.id,
                        date_paiement:data.date_paiement,
                        client: data.vente.client.id,
                        type_reglement: data.type_reglement.id,
                        vente:data.vente.id,
                        montant:data.montant,
                        montant_net:data.vente.montant_net,
                        montant_paye:data.vente.montant_paye,
                        errors:{}
                }
                if(pathname===`/reglements-ventes/view/${id}`){
                    setView(true);
                    reglementVente = {
                        ...reglementVente,
                        vente:data.vente,
                        type_reglement:data.type_reglement
                    }
                }
                setVenteSelectionne(reglementVente)
                setReglementVente(reglementVente)
                setVentes([{...data.vente}])
            }else if(idVente){ // ajout d'un reglementVente à partir d'un vente
                const vente = await axios.get(`ventes/${idVente}`);
                if(vente){
                    setVenteSelectionne(vente.data)
                    setVentes([{...vente.data}])
                    // setClientVentes(ventes.data.results.filter(c=>c.client.id===vente.client.id && !vente.flag_termine))
                    let reglementVente = {
                        client: vente.data.client.id,
                        vente:vente.data.id,
                        errors:{}
                    }
                    setReglementVente(reglementVente)
                }
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        // if(!idVente){ // pas besoin d'arrêter le loading, car la page est entièrement recharger
            appContext.setOpenLoading(false);
        // }
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = async (e,value)=>{
        if(e.target.name==='client'){
            if(!e.target.value) return;
            appContext.setOpenLoading(true);
            try {
                const id = e.target.value;
                const ventes =  await axios.get(`/clients/${id}/ventes`);
                setVentes(ventes.data.results)
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
            }
            appContext.setOpenLoading(false);
            setReglementVente({...reglementVente,client:value})
            setVenteSelectionne(null);
        }else if(e.target.name === 'vente'){
            const c = find(ventes,c=>c.id===e.target.value);
            setVenteSelectionne(c)
            setReglementVente({...reglementVente,vente:e.target.value})
        }else{
            setReglementVente({...reglementVente,[e.target.name]:e.target.value})
        }
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(reglementVente,constraint.add);
        if(errors){
            setReglementVente({...reglementVente,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/reglements-ventes`,{...omit(reglementVente,['errors','client'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                if(!idVente) setReglementVente({...initData})
                else fetchData()
            }else{
                history.goBack()
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(reglementVente,constraint.add);
        if(errors){
            setReglementVente({...reglementVente,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/reglements-ventes/${reglementVente.id}`,{...omit(reglementVente,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Reglement
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_paiement"
                            value={reglementVente.date_paiement||""}
                            onChange={handleChange}
                            errors={reglementVente.errors}
                            label="Date reglement"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="client"
                                value={reglementVente.vente.client && reglementVente.vente.client.full_name}
                                label="Client"
                                disabled={view}
                            />
                        :
                            <MySelectField 
                                name="client"
                                value={reglementVente.client}
                                onChange={handleChange}
                                errors={reglementVente.errors}
                                options={clients}
                                libelle="full_name"
                                disabled={view}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {view ?
                            <MyTextField 
                                name="vente"
                                value={reglementVente.vente && reglementVente.vente.reference}
                                label="Vente"
                                variant="outlined"
                                disabled={view}
                                required={false}
                            />
                        :
                        <FormControl fullWidth>
                            <MySelectField 
                                name="vente"
                                value={reglementVente.vente}
                                onChange={handleChange}
                                errors={reglementVente.errors}
                                options={ventes}
                                libelle="reference"
                                disabled={view}
                            />
                            {venteSelectionne &&
                                <React.Fragment>
                                    <FormHelperText>Montant vente:{util.separteurMillier(venteSelectionne.montant_net)}</FormHelperText>
                                    <FormHelperText>Montant restant:{util.separteurMillier(venteSelectionne.montant_net-venteSelectionne.montant_paye)}</FormHelperText>
                                </React.Fragment>
                            }
                        </FormControl>
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(reglementVente.montant):reglementVente.montant}
                            onChange={handleChange}
                            errors={reglementVente.errors}
                            label="Montant du paiement"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="type_reglement"
                                value={reglementVente.type_reglement && reglementVente.type_reglement.libelle}
                                label="Type règlement"
                                disabled={view}
                            />
                        :
                            <MySelectField 
                                name="type_reglement"
                                value={reglementVente.type_reglement}
                                onChange={handleChange}
                                errors={reglementVente.errors}
                                options={typeReglements}
                                libelle="libelle"
                                disabled={view}
                            />
                        }
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};