import React from 'react';
import {FormControl,InputLabel,Select,MenuItem,Checkbox,ListItemText,Input} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import find from 'lodash/find';
import dropRight from 'lodash/dropRight';

const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },  }));

export default function SelectRoles({roles,rolesSelected,onChange}){
    const classes = useStyles();
    // const [values,setValues] = React.useState(roles.map(r=>findIndex(rolesSelected,rs=>rs.id===r.id!==-1?r.selected=true:r.selected=false))||[]);
    const [values,setValues] = React.useState(rolesSelected||[]);
    const handleChange = event => {
        dropRight(event.target.value)
        let idNewValue = event.target.value.pop();
        let value = find(values,r=>r.id===idNewValue);
        value.selected = !value.selected
        let vs = values.map(v=>v.id!==value.id?v:{...value})
        setValues(vs)
    };
    
    React.useEffect(()=>{
        console.log()
        onChange(values.filter(v=>v.selected));
        // eslint-disable-next-line
    },[values])
    return(
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-multiple-checkbox">Roles</InputLabel>
            <Select
                multiple
                value={rolesSelected||[]}
                onChange={handleChange}
                input={<Input id="select-multiple-checkbox"/>}
                renderValue={selected =>selected && selected.filter(g=>g.selected).map(g=>g.name)[0]}
            // MenuProps={MenuProps}
            >
            {values && values.map(v => (
                <MenuItem key={v.id} value={v.id}>
                    <Checkbox checked={v.selected}/>
                    <ListItemText primary={v.name} />
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    )
}