import React from 'react';
import { AppContext } from '../../App';
import { useLocation } from 'react-router-dom';

export default function DashboardElastic() {
    const appContext = React.useContext(AppContext);
	const location = useLocation();
	
	const name_dash = `link_${location.pathname.replace('/','').replace('-','_')}`
	return (
		<iframe title="dashboard" style={{width:"100%",height:"600px"}} src={appContext.parametres[name_dash]}></iframe>
	);
}
