import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';
import util from '../../utils/util';

export default function ListeDepenses(){
    const [depenses,setDepenses] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_depense', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Vehicule', field: 'vehicule.immatriculation'},
        { title: 'Description', field: 'description'},
        { title: 'Montant', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant) 
        },
    ];
    return (
            <Liste table='depense' 
                values={depenses} 
                setValues={setDepenses}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouvelle depense',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/depenses-vehicules")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher depense',
                        onClick: (event, rowData) => history.push(`/depenses-vehicules/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier depense',
                        onClick: (event, rowData) => history.push(`/depenses-vehicules/${rowData.id}`)
                    },
                ]}

            />
    )
};

