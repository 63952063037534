export default {
    add:{
        banque:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        numero:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        description:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },       
    }
}