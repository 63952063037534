import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Hidden, IconButton, AppBar, Box, Tooltip } from '@material-ui/core';

import { connect } from 'react-redux';

import {
  setSidebarToggle,
  setSidebarToggleMobile
} from '../../reducers/ThemeOptions';
import projectLogo from '../../assets/images/react.svg';

import HeaderLogo from '../../Layouts/HeaderLogo';
import HeaderUserbox from '../../Layouts/HeaderUserbox';
import HeaderBack from '../../Layouts/HeaderBack';

import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

import { AppContext } from '../../App';

const Header = props => {
	const appContext = React.useContext(AppContext);
  const toggleSidebar = () => {
	setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
	setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const {
	headerShadow,
	headerFixed,
	sidebarToggleMobile,
	setSidebarToggleMobile,
	setSidebarToggle,
	sidebarToggle
  } = props;

  return (
	<Fragment>
	  <AppBar
		color="secondary"
		className={clsx('app-header', {
		  'app-header-collapsed-sidebar': props.isCollapsedLayout
		})}
		position={headerFixed ? 'fixed' : 'absolute'}
		elevation={headerShadow ? 11 : 3}>
		{!props.isCollapsedLayout && <HeaderLogo />}
		<Box className="app-header-toolbar">
		  <Hidden lgUp>
			<Box
			  className="app-logo-wrapper"
			  title="Triton">
			  <Link to="/" className="app-logo-link">
				<IconButton
				  color="primary"
				  size="medium"
				  className="app-logo-btn">
				  <img
					className="app-logo-img"
					alt=""
					src={projectLogo}
				  />
				</IconButton>
			  </Link>
			  <Hidden smDown>
				<Box className="app-logo-text">
					{appContext.appSelected && appContext.appSelected.libelle.toUpperCase() }
				</Box>
			  </Hidden>
			</Box>
		  </Hidden>
		  <Hidden mdDown>
			<Box className="d-flex align-items-center">
			  {!props.isCollapsedLayout && (
				<Box
				  className={clsx('btn-toggle-collapse', {
					'btn-toggle-collapse-closed': sidebarToggle
				  })}>
				  <Tooltip title="Toggle Sidebar" placement="right">
					<IconButton
					  color="inherit"
					  onClick={toggleSidebar}
					  size="medium"
					  className="btn-inverse">
					  {sidebarToggle ? (
						<MenuRoundedIcon />
					  ) : (
						<MenuOpenRoundedIcon />
					  )}
					</IconButton>
				  </Tooltip>
				</Box>
			  )}
			  {/* <HeaderSearch /> */}
			  {/* <HeaderMenu /> */}
			</Box>
		  </Hidden>
		  <Box className="d-flex align-items-center">
			{/* <HeaderDots /> */}
			<HeaderBack />
			<HeaderUserbox />
			{/* <HeaderDrawer /> */}
			<Box className="toggle-sidebar-btn-mobile">
			  <Tooltip title="Toggle Sidebar" placement="right">
				<IconButton
				  color="inherit"
				  onClick={toggleSidebarMobile}
				  size="medium">
				  {sidebarToggleMobile ? (
					<MenuOpenRoundedIcon />
				  ) : (
					<MenuRoundedIcon />
				  )}
				</IconButton>
			  </Tooltip>
			</Box>
		  </Box>
		</Box>
	  </AppBar>
	</Fragment>
  );
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
