import React from 'react';
import TextField  from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import util from '../../utils/util';

import Liste from '../Codification/Liste';

export default function ListeAchats(){
    const [achats,setAchats] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_achat', type:'date',
            filterComponent: props =>
            <TextField
                id="date"
                name="date_achat"
                type="date"
                value={props.columnDef.tableData.filterValue||""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
            />
    
        },
        { title: 'Fournisseur', field: 'fournisseur.nom'},
        { title: 'Description', field: 'description'},
        { title: 'Montant', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowData=>util.separteurMillier(rowData.montant)
        },
    ];
    return (
            <Liste table='achat' 
                values={achats} 
                setValues={setAchats}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    // {
                    //     icon: 'add',
                    //     tooltip: 'Nouvel achat',
                    //     isFreeAction: true,
                    //     onClick: (event, rowData) => history.push("/achat-fournisseur")
                    // },
                    // {
                    //     icon: 'edit',
                    //     tooltip: 'Nouvel achat multiple',
                    //     isFreeAction: true,
                    //     onClick: (event, rowData) => history.push("/achat-multiple")
                    // },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher',
                        onClick: (event, rowData) => history.push(`/achats/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier achat',
                        onClick: (event, rowData) => history.push(`/achats/${rowData.id}`)
                    },
                ]}

            />
    )
};

