import moment from 'moment';
export default {
    add:{
        date_operation:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            datetime: {
                dateOnly: true,
                latest:moment().format('YYYY-MM-DD'),
                message :"date incorrect"
            }
        },
        chargement_granite:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        nbre_brouette_sable:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:-1,
                message:" doit être superieur à 0"
            },
        },        
        nbre_sac_ciment:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:-1,
                message:" doit être superieur à 0"
            },
        },        
        nbre_manoeuvre:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:-1,
                message:" doit être superieur à 0"
            },
        },        
        prime_manoeuvre:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:-1,
                message:" doit être superieur à 0"
            },
        },              
    }
}