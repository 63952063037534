import React from 'react';
import { useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
// import MaterialTable from 'material-table';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';
import util from '../../utils/util';
import axios from '../../utils/axios';
import { AppContext } from '../../App';

// import ModalPaiement from '../Paiement/ModalPaiement';

export default function ListeContratLocation(){
    const appContext = React.useContext(AppContext);
    // const [contratLocationSelected,setContratLocationSelected]= React.useState({});
    const [contratLocations,setContratLocations] = React.useState([]);
    const history = useHistory();

    // React.useEffect(()=>{
    //     console.log(contratLocationSelected)
    // },[contratLocationSelected])

    const cloture = async (contratLocation) => {
        if(!contratLocation.date_fin) {
            appContext.addMessage('Veuillez renseigner la date fin du contratLocation','error');
            return;
        }
        if(contratLocation.flag_termine) {
            appContext.addMessage('Déjà cloturé','info');
            return;
        }
        try {
            await axios.put(`/contrats-locations/cloture/${contratLocation.id}`);
            contratLocation.flag_termine = true;
            appContext.addMessage('ContratLocation cloturé avec succès','success');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
    }
    const colonnes = [
        { title: 'Date', field: 'date_debut', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date_debut"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Objet', field: 'objet'},
        { title: 'Appartement', field: 'appartement.info' },
        { title: 'Locataire', field: 'locataire.full_name' },
        { title: 'Montant', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant)
        },
        { title: 'Status',
            render:rowData=><div className={`badge badge-${rowData.flag_termine ?'success':'warning'} px-4`}>
                    {rowData.flag_termine ?'Terminé':'Encours'}
                </div>
        },
    ];
    return (
        <React.Fragment>
            <Liste table='contrats-location' 
                title='Liste des contrats'
                values={contratLocations} 
                setValues={setContratLocations}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        tooltip: 'Nouveau contrat',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/contrats-locations")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher contrat',
                        onClick: (event, rowData) => history.push(`/contrats-locations/view/${rowData.id}`)
                    },
                    rowData => !rowData.flag_termine && ({
                        icon: 'edit',
                        // iconProps:{style:{color:yellow[500]}},
                        tooltip: 'Modifier contrat',
                        onClick: (event, rowData) => history.push(`/contrats-locations/${rowData.id}`)
                    }),
                    rowData=> !rowData.flag_termine && ({
                        icon: 'payments',
                        iconProps:{style:{color:green[500]}},
                        tooltip: 'Ajouter un paiement',
                        onClick: (event, rowData) => history.push(`/contrats-locations/${rowData.id}/paiements-locations`)
                    }),
                    rowData=> ({
                        icon: 'payment',
                        iconProps:{style:{color:green[500]}},
                        tooltip: 'Situation paiements',
                        onClick: (event, rowData) => history.push(`/contrats-locations/${rowData.id}/situations-paiements-locations`)
                    }),
                    rowData => !rowData.flag_termine && ({
                        icon: rowData.flag_termine ? 'lock' : 'lock_open',
                        iconProps:{style:{color:rowData.flag_termine ?green[500]:yellow[500]}},
                        tooltip: 'Clotûrer le contratLocation',
                        onClick: (event, rowData) =>appContext.setConfirmationMessage({
                            content:'Voulez-vous clôturer ce contrat',
                            title:'Confirmation',
                            valider: async ()=>{cloture(rowData);appContext.setOpenConfirmationMessage(false)},
                            annuler: () => appContext.setOpenConfirmationMessage(false)
                        })
                    }),
                ]}
            />
            {/* <ModalPaiement paiements={contratLocationSelected.paiements} open={openModalPaiment} toggleOpen={toggleOpenModalPaiement}/> */}
        </React.Fragment>

    )
};

