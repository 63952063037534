import React from 'react';
import TextField  from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import util from '../../utils/util';
import green from '@material-ui/core/colors/green';
import Liste from '../Codification/Liste';

export default function ListeVentes(){
    const [ventes,setVentes] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_vente', type:'date',
            filterComponent: props =>
            <TextField
                id="date"
                name="date_vente"
                type="date"
                value={props.columnDef.tableData.filterValue||""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
            />
    
        },
        { title: 'Reference', field: 'reference'},
        { title: 'Client', field: 'client.full_name'},
        { title: 'Montant', field: 'montant_net',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowData=>util.separteurMillier(rowData.montant_net)
        },
        { title: 'Payé', field: 'montant_paye',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowData=>util.separteurMillier(rowData.montant_paye)
        },
        { title: 'Reste',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowData=>util.separteurMillier(rowData.montant_net-rowData.montant_paye)
        },
    ];
    return (
            <Liste table='vente' 
                values={ventes} 
                setValues={setVentes}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        tooltip: 'Nouvelle vente',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/ventes")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher',
                        onClick: (event, rowData) => history.push(`/ventes/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier vente',
                        onClick: (event, rowData) => history.push(`/ventes/${rowData.id}`)
                    },
                    rowData=> rowData.montant_paye<rowData.montant_net && ({
                        icon: 'payments',
                        iconProps:{style:{color:green[500]}},
                        tooltip: 'Ajouter un règlement',
                        onClick: (event, rowData) => history.push(`/ventes/${rowData.id}/reglements-ventes`)
                    }),
                ]}

            />
    )
};

