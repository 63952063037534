import React from 'react';

import Liste from '../Codification/Liste';

export default function ListeRubriques(){
    const [rubriques,setRubriques] = React.useState([]);


    const colonnes = [
            { title: 'Libelle', field: 'libelle' },
];
    return (
            <Liste table='rubrique' 
                values={rubriques} 
                setValues={setRubriques}
                colonnes={colonnes}

            />
    )
};

