import React from 'react';

import Liste from '../Codification/Liste';


export default function ListeClient(){
    const [clients,setClients] = React.useState([]);
   
    const colonnes = [
        { title: 'Nom', field: 'nom' },
        { title: 'Prenoms', field: 'prenom' },
        { title: 'Telephone', field: 'telephone' },
        { title: 'Email', field: 'email',emptyValue:()=>""},
        { title: 'Adresse', field: 'adresse',emptyValue:()=>""},
    ];
    return (
            <Liste table='client' 
                values={clients} 
                setValues={setClients}
                colonnes={colonnes}

            />
    )
};

