import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Bande';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    date_bande:"",
    contrat:"",
    errors:{}
};
export default function Bande(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [bande,setBande] = React.useState({...initData})
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            if(id){ // modification d'un bande
                const {data} = await axios.get(`/bandes/${id}`);
                let bande = {
                        id: data.id,
                        nom:data.nom,
                        date_commande:data.date_commande,
                        date_acquisition:data.date_acquisition,
                        date_liquidation:data.date_liquidation,
                        effectif_commande:data.effectif_commande,
                        effectif_recu:data.effectif_recu,
                        effectif:data.effectif,
                        errors:{}
                }
                if(pathname===`/bandes/view/${id}`){
                    setView(true);
                }
                setBande(bande)
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
            appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setBande({...bande,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(bande,constraint.add);
        if(errors){
            setBande({...bande,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/bandes`,{...bande});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setBande({...initData})
            }else{
                history.goBack()
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(bande,constraint.add);
        if(errors){
            setBande({...bande,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/bandes/${bande.id}`,{...bande});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} d'une bande
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            name="nom"
                            type={"text"}
                            value={bande.nom}
                            onChange={handleChange}
                            errors={bande.errors}
                            label="Nom de la bande"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_commande"
                            value={bande.date_commande||""}
                            onChange={handleChange}
                            errors={bande.errors}
                            label="Date commande"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_acquisition"
                            value={bande.date_acquisition||""}
                            onChange={handleChange}
                            errors={bande.errors}
                            label="Date acquistion"
                            disabled={view}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="effectif_commande"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(bande.effectif_commande):bande.effectif_commande}
                            onChange={handleChange}
                            errors={bande.errors}
                            label="Effectif commandé"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="effectif_recu"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(bande.effectif_recu):bande.effectif_recu}
                            onChange={handleChange}
                            errors={bande.errors}
                            label="Effectif reçu"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyDateField 
                            name="date_liquidation"
                            value={bande.date_liquidation||""}
                            onChange={handleChange}
                            errors={bande.errors}
                            label="Date liquidation"
                            disabled={view}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};