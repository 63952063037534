import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import util from '../../utils/util';
import axios from '../../utils/axios';
import { AppContext } from '../../App';

import Liste from '../Codification/Liste';
// import MyDateField from '../Codification/MyDateField';
// import util from '../../utils/util';

export default function ListeEleve(){
    const appContext = React.useContext(AppContext);

    const [eleves,setEleves] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Matricule', field: 'matricule' },
        { title: 'Nom', field: 'nom' },
        { title: 'Prénom', field: 'prenom' },
        { title: 'Tel', field: 'telephone' },
        { title: 'Classe', field: 'salle.nom' },
    ];
    const actualisaterTranche = async (id) =>{
        try {
            await axios.put(`/eleves/${id}/actualisation-tranche`);
            appContext.addMessage('Actualisation effectuée avec succès','success');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }   
    }
    return (
            <Liste table='eleve' 
                values={eleves} 
                setValues={setEleves}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouvel Elève',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/eleves")
                    },
                    {
                        icon: 'manage_accounts',
                        iconProps:{color:'primary'},
                        tooltip: 'Responsables',
                        onClick: (event, rowData) => history.push(`/eleves/${rowData.id}/responsables`)
                    },
                    {
                        icon: 'refresh',
                        tooltip: 'Actualisation tranche',
                        onClick: (event, rowData) =>appContext.setConfirmationMessage({
                            content:'Voulez-vous re-actualiser les tranches de paiement',
                            title:'Confirmation',
                            valider: async ()=>{actualisaterTranche(rowData.id);appContext.setOpenConfirmationMessage(false)},
                            annuler: () => appContext.setOpenConfirmationMessage(false)
                        })
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Consultation',
                        onClick: (event, rowData) => history.push(`/eleves/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier élève',
                        onClick: (event, rowData) => history.push(`/eleves/${rowData.id}`)
                    }
                ]}

            />
    )
};

