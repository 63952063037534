import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { CircleLoader } from 'react-spinners';

import MuiTheme from './theme';
import { AppContext } from './App';

// Layout Blueprints

import {
	LeftSidebar,
	MinimalLayout,
} from './layout-blueprints';

// Example Pages

import PagesLogin from './Pages/PagesLogin';
import PagesError404 from './Pages/PagesError404';
import PagesError500 from './Pages/PagesError500';
import PagesError505 from './Pages/PagesError505';

import SelectionApplication from './Components/SelectionApplication/SelectionApplication';
import SelectionProjet from './Components/SelectionProjet/SelectionProjet';
import ChangePassword from './Components/ChangePassword'
import Logout from './Components/Logout'
import ListeUsers from './Components/User/Liste'

import TabReferentiel from './Components/Referentiel/TabReferentiel'
import Hangars from './Components/Hangar/Liste'

import Ramassage from './Components/Ramassage/Ramassage';
import RamassageJournalier from './Components/Ramassage/RamassageJournalier';
import ListeRamassages from './Components/Ramassage/Liste';


import Article from './Components/Article/Article';
import ListeArticle from './Components/Article/Liste';
import ListeArticleUnite from './Components/Article/ListeArticleUnite';

import ListeFournisseur from './Components/Fournisseur/Liste';

import AchatFournisseur from './Components/Achat/AchatFournisseur';
import ListeAchat from './Components/Achat/Liste';
import AchatMultiple from './Components/Achat/AchatMultiple';

import ListePrestataires from './Components/Prestataire/Liste';
import ListeContrats from './Components/Contrat/Liste';
import Contrat from './Components/Contrat/Contrat';
import ListePaiements from './Components/Paiement/Liste';
import Paiement from './Components/Paiement/Paiement';

import ListeDepenses from './Components/Depense/Liste';
import Depense from './Components/Depense/Depense';

import ListeSourceSubvention from './Components/SourceSubvention/Liste';
import ListeSubventions from './Components/Subvention/Liste';
import Subvention from './Components/Subvention/Subvention';

import ListeCommande from './Components/Commande/Liste';
import Commande from './Components/Commande/Commande';

import ListeLivraison from './Components/Livraison/Liste';
import Livraison from './Components/Livraison/Livraison';

import ListePaiementComandes from './Components/PaiementCommande/Liste';
import PaiementCommande from './Components/PaiementCommande/PaiementCommande';

import ListeBandes from './Components/Bande/Liste';
import Bande from './Components/Bande/Bande';

import Mortalite from './Components/Mortalite/Mortalite';
import MortaliteJournalier from './Components/Mortalite/MortaliteJournalier';
import ListeMortalites from './Components/Mortalite/Liste';

import ListeFonctions from './Components/Fonction/Liste';
import ListePersonnels from './Components/Personnel/Liste';
import RubriqueEmploye from './Components/Rubrique/RubriqueEmploye';
import ListeRubriques from './Components/Rubrique/Liste';
import ListeMois from './Components/Mois/Liste';
import ListeBulletinsEmploye from './Components/Bulletin/ListeParEmploye';
// import ListeBulletins from './Components/Bulletin/Liste';
import ListeBulletinsParMois from './Components/Bulletin/ListeParMois';

// import Propriete from './Components/Propriete/Propriete';
import ListeProprietes from './Components/Propriete/Liste';
import ListeAppartements from './Components/Appartement/Liste';
import ListeLocataires from './Components/Locataire/Liste';

// import ListeLocataires from './Components/ContratLocation/Liste';
import ContratLocation from './Components/ContratLocation/ContratLocation';
import ListeContratLocation from './Components/ContratLocation/Liste';
import SituationContratLocation from './Components/ContratLocation/SituationContrat';
import ListePaiementsLocation from './Components/PaiementLocation/Liste';
import PaiementLocation from './Components/PaiementLocation/Paiement';

import ListeClients from './Components/Client/Liste';
import VenteClient from './Components/Vente/VenteClient';
import ListeVentes from './Components/Vente/Liste';
import ListeReglementVentes from './Components/ReglementVente/Liste';
import ReglementVente from './Components/ReglementVente/ReglementVente';

import ListeReinvestissement from './Components/Reinvestissement/Liste';
import Reinvestir from './Components/Reinvestissement/Reinvestir';

import NumeroCompte from './Components/NumeroCompte/NumeroCompte';
import ListeNumeroCompte from './Components/NumeroCompte/Liste';

import Operation from './Components/Operation/Operation';
import ListeOperation from './Components/Operation/Liste';

import Modeles from './Components/Modele/Modele';
import ListeModeles from './Components/Modele/Liste';

import ListeVehicules from './Components/Vehicule/Liste';
import Vehicule from './Components/Vehicule/Vehicule';

// import ListeChauffeurs from './Components/Chauffeur/Liste';

import OperationVehicule from './Components/OperationVehicule/Operation';
import ListeOperationVehicule from './Components/OperationVehicule/Liste';
import DepenseVehicule from './Components/Depense/DepenseVehicule';
import ListeDepenseVehicule from './Components/Depense/ListeDepenseVehicule';
import AffectationVehicule from './Components/AffectationVehicule/AffectationVehicule';
import ListeAffectationVehicule from './Components/AffectationVehicule/Liste';

import Chargement from './Components/Chargement/Chargement';
import ListeChargement from './Components/Chargement/Liste';
import OperationBriqueterie from './Components/OperationBriqueterie/Operation';
import ListeOperationBriqueterie from './Components/OperationBriqueterie/Liste';
import ProductionBrique from './Components/ProductionBrique/Production';
import ListeProductionBrique from './Components/ProductionBrique/Liste';

import ListeStock from './Components/Stock/Liste';
import ListeMouvementsByArticle from './Components/Mouvement/ListeByArticle';
import MouvementArticle from './Components/Mouvement/MouvementArticle';
import Mouvement from './Components/Mouvement/Mouvement';
import MouvementSortieMultiple from './Components/Mouvement/MouvementSortieMultiple';


import OperationFabrique from './Components/OperationFabrique/Operation';
import ListeOperationFabrique from './Components/OperationFabrique/Liste';

import ListeClasse from './Components/Classe/Liste';
import ListeSalleParClasse from './Components/Classe/ListeSalleParClasse';
import ListeTrancheParClasse from './Components/Classe/ListeTrancheParClasse';

import ListeTranches from './Components/Tranche/Liste';

import ListeEleve from './Components/Eleve/Liste';
import Eleve from './Components/Eleve/Eleve';

import ListeResponsable from './Components/Responsable/Liste';
import ListeElevesParResponsable from './Components/Responsable/ListeElevesParResponsable';
import ListeResponsablesParEleve from './Components/Eleve/ListeResponsablesParEleve';

import ListeAnneeScolaire from './Components/AnneeScolaire/Liste';
import ListeInscriptions from './Components/Inscription/Liste';
import ListePaiementsInscription from './Components/Inscription/ListePaiementInscription';

import ListeScolarites from './Components/Scolarite/Liste';
import ListePaiementsScolarite from './Components/Scolarite/ListePaiementScolarite';

// import ListePaiementInscription from './Components/PaiementInscription/Liste';
// import PaiementInscription from './Components/PaiementInscription/Paiement';

import DashboardElastic from './Components/Dashboard/DashboardElastic';
// import DashboardChiffre from './Components/Dashboard/DashboardChiffre';
// import DashboardVente from './Components/Dashboard/DashboardVente';
// import DashboardStock from './Components/Dashboard/DashboardStock';


const Dashboard = lazy(() => import('./Components/Dashboard'));
// const DashboardDefault = lazy(() => import('./Pages/DashboardDefault'));
const LandingPage = lazy(() => import('./Components/LandingPage'));

export const SuspenseLoading = () => {
	return (
		<Fragment>
			<div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
				<div className="d-flex align-items-center flex-column px-4">
					<CircleLoader color={'#5383ff'} loading={true} />
				</div>
				<div className="text-muted font-size-xl text-center pt-3">
					Veuillez patienter...
				</div>
			</div>
		</Fragment>
	);
};

function SecureRoute (props){
	const appContext = React.useContext(AppContext);
	return (
			!appContext.isAuthenticated()  ?
					<Redirect to="/login"/>:
					appContext.isReseted() ?
							<Redirect to="/change-password"/> :
							<Route {...props} /> 
	)
}
const Routes = () => {
	const location = useLocation();

	const pageVariants = {
		initial: {
			opacity: 0,
			scale: 0.99
		},
		in: {
			opacity: 1,
			scale: 1
		},
		out: {
			opacity: 0,
			scale: 1.01
		}
	};

	const pageTransition = {
		type: 'tween',
		ease: 'anticipate',
		duration: 0.4
	};

	return (
		<ThemeProvider theme={MuiTheme}>
			<AnimatePresence>
				<Suspense fallback={<SuspenseLoading />}>
					<Switch>
						<Redirect exact from="/" to="/LandingPage" />

						<Route
							path={[
								'/login',
								'/logout',
								'/selection-application',
								'/selection-projet',
								'/PagesError404',
								'/PagesError500',
								'/PagesError505'
							]}>
							<MinimalLayout>
								<Switch location={location} key={location.pathname}>
									<motion.div
										initial="initial"
										animate="in"
										exit="out"
										variants={pageVariants}
										transition={pageTransition}>
										<Route path="/login" component={PagesLogin} />
										<Route path="/logout" component={Logout} />
										<SecureRoute path="/selection-application" component={SelectionApplication} />
										<SecureRoute path="/selection-projet" component={SelectionProjet} />
										<Route path="/PagesError404" component={PagesError404} />
										<Route path="/PagesError500" component={PagesError500} />
										<Route path="/PagesError505" component={PagesError505} />
									</motion.div>
								</Switch>
							</MinimalLayout>
						</Route>

						<Route
							path={[
								'/LandingPage',
								'/DashboardDefault',
								// '/dashboard-chiffre',
								// '/dashboard-vente',
								// '/dashboard-stock',
								'/dashboard-*',
								'/change-password',
								'/referentiels',
								'/hangars',
								'/liste-users',
								'/ramassages',
								'/ramassage-journalier',
								'/ramassages/:id',
								'/liste-ramassages',
								'/articles',
								'/articles/:id',
								'/liste-articles',
								'/liste-article-unites/:id',
								'/fournisseurs',
								'/achat-fournisseur',
								'/achats',
								'/achat-multiple',
								'/achats/:id',
								'/achats/view/:id',
								'/prestataires',
								'/liste-contrats',
								'/contrats',
								'/contrats/:id',
								'/contrats/view/:id',
								'/contrats/:idContrat/paiement',
								'/liste-paiements',
								'/paiements',
								'/paiements/:id',
								'/paiements/view/:id',
								'/sources-subventions',
								'/liste-subventions',
								'/subventions',
								'/subventions/:id',
								'/subventions/view/:id',
								'/PagesError404',
								'/liste-commandes',
								'/commandes',
								'/commandes/:id',
								'/commandes/view/:id',
								'/commandes/:idCommande/paiement',
								'/liste-livraisons',
								'/livraisons',
								'/livraisons/:id',
								'/livraisons/view/:id',
								'/livraisons/:idCommande/paiement',
								'/liste-paiements-commandes',
								'/paiements-commandes',
								'/paiements-commandes/:id',
								'/paiements-commandes/view/:id',
								'/liste-depenses',
								'/depenses',
								'/depenses/:id',
								'/depenses/view/:id',
								'/liste-bandes',
								'/bandes',
								'/bandes/:id',
								'/bandes/:id/hangars',
								'/bandes/view/:id',
								'/mortalites',
								'/mortalite-journalier',
								'/mortalites/:id',
								'/liste-mortalites',
								'/liste-fonctions',
								'/liste-employes',
								'/employes/:id/rubriques',
								'/employes/:id/bulletins',
								'/liste-rubriques',
								'/salaires',
								'/liste-bulletins',
								'/liste-bulletins/:mois',
								'/liste-proprietes',
								'/proprietes/:id/appartements',
								'/locataires',
								'/liste-contrats-locations',
								'/contrats-locations',
								'/contrats-locations/:id',
								'/contrats-locations/view/:id',
								'/contrats-locations/:idContrat/paiements-locations',
								'/contrats-locations/:idContrat/paiements-locations/:idPaiement',
								'/contrats-locations/:idContrat/situations-paiements-locations',
								'/liste-paiements-locations',
								'/paiements-locations',
								'/paiements-locations/:id',
								'/paiements-locations/view/:id',
								
								'/clients',
								'/liste-ventes',
								'/ventes',
								'/ventes/:id',
								'/ventes/view/:id',
								'/ventes/:idVente/reglements-ventes',
								'/liste-reglements-ventes',
								'/reglements-ventes',
								'/reglements-ventes/:id',
								'/reglements-ventes/view/:id',
								// '/proprietes',

								'/liste-reinvestissements',
								'/reinvestir',
								'/reinvestir/:id',
								'/reinvestir/view/:id',

								'/liste-comptes',
								'/numero-comptes',
								'/numero-comptes/:id',
								'/numero-comptes/view/:id',
								
								'/liste-operations',
								'/operations',
								'/operations/:id',
								'/operations/view/:id',
								
								'/liste-modeles',
								'/modeles',
								'/modeles/:id',
								'/modeles/view/:id',
								'/liste-vehicules',
								'/vehicules',
								'/vehicules/:id',
								'/vehicules/view/:id',
								// '/liste-chauffeurs',
								'/liste-operations-vehicules',
								'/operations-vehicules',
								'/operations-vehicules/:id',
								'/operations-vehicules/view/:id',
								'/liste-depenses-vehicules',
								'/depenses-vehicules',
								'/depenses-vehicules/:id',
								'/depenses-vehicules/view/:id',
								'/liste-affectation-vehicules',
								'/affectation-vehicules',
								'/affectation-vehicules/:id',
								'/affectation-vehicules/view/:id',
								
								'/liste-chargements',
								'/chargements',
								'/chargements/:id',
								'/chargements/view/:id',
								'/liste-operations-briqueteries',
								'/operations-briqueteries',
								'/operations-briqueteries/:id',
								'/operations-briqueteries/view/:id',
								'/liste-production-briques',
								'/production-briques',
								'/production-briques/:id',
								'/production-briques/view/:id',
								
								'/liste-stock',
								'/articles/:idArticle/mvt-stocks',
								'/mvt-stocks/articles/:idArticle',
								'/mvt-stocks/:id',
								'/mvt-stocks/view/:id',
								'/sorties-multiples',
								
								'/liste-operations-fabriques',
								'/operations-fabriques',
								'/operations-fabriques/:id',
								'/operations-fabriques/view/:id',
								
								'/liste-classes',
								'/classes/:id/salles',
								'/classes/:id/tranches',
								
								'/liste-tranches',

								'/liste-eleves',
								'/eleves',
								'/eleves/:id',
								'/eleves/view/:id',
								'/eleves/:id/responsables',
								
								'/liste-responsables',
								'/responsables/:id/eleves',
								'/liste-annees-scolaires',
								
								'/liste-inscriptions',
								'/inscriptions/:id/paiements',

								'/liste-scolarites',
								'/scolarites/:id/paiements',

								// '/liste-paiements-inscriptions',

							]}>
							<LeftSidebar>
								<Switch location={location} key={location.pathname}>
									<motion.div
										initial="initial"
										animate="in"
										exit="out"
										variants={pageVariants}
										transition={pageTransition}>
											<SecureRoute path="/liste-fonctions" exact component={ListeFonctions} />
											<SecureRoute path="/liste-employes" exact component={ListePersonnels} />
											<SecureRoute path="/employes/:id/rubriques" exact component={RubriqueEmploye} />
											<SecureRoute path="/employes/:id/bulletins" exact component={ListeBulletinsEmploye} />
											<SecureRoute path="/liste-rubriques" exact component={ListeRubriques} />

											<SecureRoute path="/LandingPage" component={LandingPage}/>
											<SecureRoute path="/DashboardDefault" component={Dashboard}/>
											<SecureRoute path="/dashboard-*" component={DashboardElastic}/>
											{/* <SecureRoute path="/dashboard-chiffre" component={DashboardChiffre}/>
											<SecureRoute path="/dashboard-vente" component={DashboardVente}/>
											<SecureRoute path="/dashboard-stock" component={DashboardStock}/> */}
											<Route path="/change-password" component={ChangePassword}/>
											<SecureRoute path="/liste-users" exact component={ListeUsers} />

											<SecureRoute path="/referentiels" exact component={TabReferentiel} />
											<SecureRoute path="/hangars" exact component={Hangars} />


											<SecureRoute path="/ramassages" exact component={Ramassage} />
											<SecureRoute path="/ramassage-journalier" exact component={RamassageJournalier} />
											<SecureRoute path="/ramassages/:id" exact component={Ramassage} />
											<SecureRoute path="/liste-ramassages" exact component={ListeRamassages} />

											<SecureRoute path="/articles" exact component={Article} />
											<SecureRoute path="/articles/:id" exact component={Article} />
											<SecureRoute path="/liste-articles" exact component={ListeArticle} />
											<SecureRoute path="/liste-article-unites/:id" exact component={ListeArticleUnite} />

											<SecureRoute path="/fournisseurs" exact component={ListeFournisseur} />

											<SecureRoute path="/achat-fournisseur" exact component={AchatFournisseur} />
											<SecureRoute path="/achat-multiple" exact component={AchatMultiple} />
											<SecureRoute path="/achats" exact component={ListeAchat} />
											<SecureRoute path="/achats/:id" exact component={AchatFournisseur} />
											<SecureRoute path="/achats/view/:id" exact component={AchatFournisseur} />

											<SecureRoute path="/prestataires" exact component={ListePrestataires} />
											<SecureRoute path="/liste-contrats" exact component={ListeContrats} />
											<SecureRoute path="/contrats" exact component={Contrat} />
											<SecureRoute path="/contrats/:id" exact component={Contrat} />
											<SecureRoute path="/contrats/view/:id" exact component={Contrat} />
											<SecureRoute path="/contrats/:idContrat/paiement" exact component={Paiement} />
											<SecureRoute path="/liste-paiements" exact component={ListePaiements} />
											<SecureRoute path="/paiements" exact component={Paiement} />
											<SecureRoute path="/paiements/:id" exact component={Paiement} />
											<SecureRoute path="/paiements/view/:id" exact component={Paiement} />
											
											<SecureRoute path="/sources-subventions" exact component={ListeSourceSubvention} />
											<SecureRoute path="/liste-subventions" exact component={ListeSubventions} />
											<SecureRoute path="/subventions" exact component={Subvention} />
											<SecureRoute path="/subventions/:id" exact component={Subvention} />
											<SecureRoute path="/subventions/view/:id" exact component={Subvention} />
											<SecureRoute path="/liste-commandes" exact component={ListeCommande} />
											<SecureRoute path="/commandes" exact component={Commande} />
											<SecureRoute path="/commandes/:id" exact component={Commande} />
											<SecureRoute path="/commandes/view/:id" exact component={Commande} />
											<SecureRoute path="/commandes/:idCommande/livraison" exact component={Livraison} />
											<SecureRoute path="/commandes/:idCommande/paiement" exact component={PaiementCommande} />
											
											<SecureRoute path="/liste-livraisons" exact component={ListeLivraison} />
											<SecureRoute path="/livraisons" exact component={Livraison} />
											<SecureRoute path="/livraisons/:id" exact component={Livraison} />
											<SecureRoute path="/livraisons/view/:id" exact component={Livraison} />
											
											<SecureRoute path="/liste-paiements-commandes" exact component={ListePaiementComandes} />
											<SecureRoute path="/paiements-commandes" exact component={PaiementCommande} />
											<SecureRoute path="/paiements-commandes/:id" exact component={PaiementCommande} />
											<SecureRoute path="/paiements-commandes/view/:id" exact component={PaiementCommande} />
											<SecureRoute path="/liste-depenses" exact component={ListeDepenses} />
											<SecureRoute path="/depenses" exact component={Depense} />
											<SecureRoute path="/depenses/:id" exact component={Depense} />
											<SecureRoute path="/depenses/view/:id" exact component={Depense} />
											<SecureRoute path="/liste-bandes" exact component={ListeBandes} />
											<SecureRoute path="/bandes" exact component={Bande} />
											<SecureRoute path="/bandes/:id" exact component={Bande} />
											<SecureRoute path="/bandes/view/:id" exact component={Bande} />

											<SecureRoute path="/mortalites" exact component={Mortalite} />
											<SecureRoute path="/mortalite-journalier" exact component={MortaliteJournalier} />
											<SecureRoute path="/mortalites/:id" exact component={Mortalite} />
											<SecureRoute path="/liste-mortalites" exact component={ListeMortalites} />
											<SecureRoute path="/salaires" exact component={ListeMois} />
											{/* <SecureRoute path="/liste-bulletins" exact component={ListeBulletins} /> */}
											<SecureRoute path="/liste-bulletins/:annee-:mois" exact component={ListeBulletinsParMois} />
											{/* <SecureRoute path="/proprietes" exact component={Propriete} /> */}
											<SecureRoute path="/liste-proprietes" exact component={ListeProprietes} />
											<SecureRoute path="/proprietes/:id/appartements" exact component={ListeAppartements} />
											<SecureRoute path="/locataires" exact component={ListeLocataires} />
											<SecureRoute path="/liste-contrats-locations" exact component={ListeContratLocation} />
											<SecureRoute path="/contrats-locations" exact component={ContratLocation} />
											<SecureRoute path="/contrats-locations/:id" exact component={ContratLocation} />
											<SecureRoute path="/contrats-locations/:idContrat/paiements-locations" exact component={PaiementLocation} />
											<SecureRoute path="/contrats-locations/:idContrat/paiements-locations/:idPaiement" exact component={PaiementLocation} />
											<SecureRoute path="/contrats-locations/:idContrat/situations-paiements-locations" exact component={SituationContratLocation} />
											<SecureRoute path="/contrats-locations/view/:id" exact component={ContratLocation} />
											<SecureRoute path="/liste-paiements-locations" exact component={ListePaiementsLocation} />
											<SecureRoute path="/paiements-locations" exact component={PaiementLocation} />
											<SecureRoute path="/paiements-locations/:id" exact component={PaiementLocation} />
											<SecureRoute path="/paiements-locations/view/:id" exact component={PaiementLocation} />

											<SecureRoute path="/clients" exact component={ListeClients} />
											<SecureRoute path="/Ventes" exact component={VenteClient} />
											<SecureRoute path="/liste-ventes" exact component={ListeVentes} />
											<SecureRoute path="/ventes/:id" exact component={VenteClient} />
											<SecureRoute path="/ventes/view/:id" exact component={VenteClient} />
											<SecureRoute path="/ventes/:idVente/reglements-ventes" exact component={ReglementVente} />
											<SecureRoute path="/liste-reglements-ventes" exact component={ListeReglementVentes} />
											<SecureRoute path="/reglements-ventes" exact component={ReglementVente} />
											<SecureRoute path="/reglements-ventes/:id" exact component={ReglementVente} />
											<SecureRoute path="/reglements-ventes/view/:id" exact component={ReglementVente} />
											
											<SecureRoute path="/liste-reinvestissements" exact component={ListeReinvestissement} />
											<SecureRoute path="/reinvestir" exact component={Reinvestir} />
											<SecureRoute path="/reinvestir/:id" exact component={Reinvestir} />
											<SecureRoute path="/reinvestir/view/:id" exact component={Reinvestir} />
											
											<SecureRoute path="/liste-comptes" exact component={ListeNumeroCompte} />
											<SecureRoute path="/numero-comptes" exact component={NumeroCompte} />
											<SecureRoute path="/numero-comptes/:id" exact component={NumeroCompte} />
											<SecureRoute path="/numero-comptes/view/:id" exact component={NumeroCompte} />
											
											<SecureRoute path="/liste-operations" exact component={ListeOperation} />
											<SecureRoute path="/operations" exact component={Operation} />
											<SecureRoute path="/operations/:id" exact component={Operation} />
											<SecureRoute path="/operations/view/:id" exact component={Operation} />

											<SecureRoute path="/liste-modeles" exact component={ListeModeles} />
											<SecureRoute path="/modeles" exact component={Modeles} />
											<SecureRoute path="/modeles/:id" exact component={Modeles} />
											<SecureRoute path="/modeles/view/:id" exact component={Modeles} />
											<SecureRoute path="/liste-vehicules" exact component={ListeVehicules} />
											<SecureRoute path="/vehicules" exact component={Vehicule} />
											<SecureRoute path="/vehicules/:id" exact component={Vehicule} />
											<SecureRoute path="/vehicules/view/:id" exact component={Vehicule} />
											{/* <SecureRoute path="/liste-chauffeurs" exact component={ListeChauffeurs} /> */}
											<SecureRoute path="/liste-operations-vehicules" exact component={ListeOperationVehicule} />
											<SecureRoute path="/operations-vehicules" exact component={OperationVehicule} />
											<SecureRoute path="/operations-vehicules/:id" exact component={OperationVehicule} />
											<SecureRoute path="/operations-vehicules/view/:id" exact component={OperationVehicule} />
											<SecureRoute path="/liste-depenses-vehicules" exact component={ListeDepenseVehicule} />
											<SecureRoute path="/depenses-vehicules" exact component={DepenseVehicule} />
											<SecureRoute path="/depenses-vehicules/:id" exact component={DepenseVehicule} />
											<SecureRoute path="/depenses-vehicules/view/:id" exact component={DepenseVehicule} />
											<SecureRoute path="/liste-affectation-vehicules" exact component={ListeAffectationVehicule} />
											<SecureRoute path="/affectation-vehicules" exact component={AffectationVehicule} />
											<SecureRoute path="/affectation-vehicules/:id" exact component={AffectationVehicule} />
											<SecureRoute path="/affectation-vehicules/view/:id" exact component={AffectationVehicule} />

											<SecureRoute path="/liste-chargements" exact component={ListeChargement} />
											<SecureRoute path="/chargements" exact component={Chargement} />
											<SecureRoute path="/chargements/:id" exact component={Chargement} />
											<SecureRoute path="/chargements/view/:id" exact component={Chargement} />
											<SecureRoute path="/liste-operations-briqueteries" exact component={ListeOperationBriqueterie} />
											<SecureRoute path="/operations-briqueteries" exact component={OperationBriqueterie} />
											<SecureRoute path="/operations-briqueteries/:id" exact component={OperationBriqueterie} />
											<SecureRoute path="/operations-briqueteries/view/:id" exact component={OperationBriqueterie} />
											<SecureRoute path="/liste-production-briques" exact component={ListeProductionBrique} />
											<SecureRoute path="/production-briques" exact component={ProductionBrique} />
											<SecureRoute path="/production-briques/:id" exact component={ProductionBrique} />
											<SecureRoute path="/production-briques/view/:id" exact component={ProductionBrique} />

											<SecureRoute path="/liste-stock" exact component={ListeStock} />
											<SecureRoute path="/articles/:idArticle/mvt-stocks" exact component={ListeMouvementsByArticle} />
											<SecureRoute path="/mvt-stocks/articles/:idArticle" exact component={MouvementArticle} />
											<SecureRoute path="/mvt-stocks/:id" exact component={Mouvement} />
											<SecureRoute path="/mvt-stocks/view/:id" exact component={Mouvement} />
											<SecureRoute path="/sorties-multiples" exact component={MouvementSortieMultiple} />

											<SecureRoute path="/liste-operations-fabriques" exact component={ListeOperationFabrique} />
											<SecureRoute path="/operations-fabriques" exact component={OperationFabrique} />
											<SecureRoute path="/operations-fabriques/:id" exact component={OperationFabrique} />
											<SecureRoute path="/operations-fabriques/view/:id" exact component={OperationFabrique} />

											<SecureRoute path="/liste-classes" exact component={ListeClasse} />
											<SecureRoute path="/classes/:id/salles" exact component={ListeSalleParClasse} />
											<SecureRoute path="/classes/:id/tranches" exact component={ListeTrancheParClasse} />

											<SecureRoute path="/liste-tranches" exact component={ListeTranches} />

											<SecureRoute path="/liste-eleves" exact component={ListeEleve} />
											<SecureRoute path="/eleves" exact component={Eleve} />
											<SecureRoute path="/eleves/:id" exact component={Eleve} />
											<SecureRoute path="/eleves/view/:id" exact component={Eleve} />
											<SecureRoute path="/eleves/:id/responsables" exact component={ListeResponsablesParEleve} />
											
											<SecureRoute path="/liste-responsables" exact component={ListeResponsable} />
											<SecureRoute path="/responsables/:id/eleves" exact component={ListeElevesParResponsable} />

											<SecureRoute path="/liste-annees-scolaires" exact component={ListeAnneeScolaire} />

											<SecureRoute path="/liste-inscriptions" exact component={ListeInscriptions} />
											<SecureRoute path="/inscriptions/:id/paiements" exact component={ListePaiementsInscription} />
											<SecureRoute path="/liste-scolarites" exact component={ListeScolarites} />
											<SecureRoute path="/scolarites/:id/paiements" exact component={ListePaiementsScolarite} />
											
									</motion.div>
								</Switch>
							</LeftSidebar>
						</Route>
						<Redirect from="*" to="/PagesError404" />
					</Switch>
				</Suspense>
			</AnimatePresence>
		</ThemeProvider>
	);
};

export default Routes;
