import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { useHistory,useParams } from 'react-router-dom';
import find from 'lodash/find';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Achat';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';
import IconMaterialTable from '../../IconMaterialTable';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}));
// const initDataDetail = {
//     article:"",
//     errors:[]
// }
// const initData = {
//     date_achat:moment().format('YYYY-MM-DD'),
//     fournisseur:"",
//     details:[],
//     errors:{}
// };
export default function AchatFournisseur(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [achats,setAchats] = React.useState([])
    const [fournisseurs,setFournisseurs] = React.useState([])
    const [articles,setArticles] = React.useState([])
    // const [unites,setUnites] = React.useState([])
    const refListe = React.useRef(null);

    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [fournisseurs,articles/*,unites*/] = await Promise.all([
                axios.get(`/fournisseurs`),
                axios.get(`/articles`),
                // axios.get(`/unites`),
            ]);
            setFournisseurs(fournisseurs.data.results)            
            setArticles(articles.data.results)            
            // setUnites(unites.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChangeDetail = (e,props)=>{
        let data = {...props.rowData};
        const listes = {
            article:articles,
            // unite_stockage:unites,
            fournisseur:fournisseurs
        }
        if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
            const values = listes[props.columnDef.field];
            const value = find(values,v=>v.id===parseInt(e.target.value));
            if(value){
                data = {...data,
                    [props.columnDef.field]:value,
                }
                if(props.columnDef.field==='article')
                    data = {...data,prix: value.prix.toString(),unite_stockage:value.unite ? value.unite.libelle : null}
                props.onChange(value)
            }
        }else{
            data = {...data,[props.columnDef.field]:e.target.value}
            props.onChange(e.target.value);
        }
        data = {
            ...data,
            montant:(parseInt(data.quantite||0)*parseInt(data.prix||0)).toLocaleString('fr-FR'),
            errors:{}
        }
        props.onRowDataChange(data)
    }
    const enregistrer = async ()=>{
        try {
            let a = achats.map(d=>({
                id:d.id,
                date_achat:d.date_achat,
                fournisseur:d.fournisseur.id,
                article:d.article.id,
                unite_stockage:d.article.unite ? d.article.unite.id : null,
                quantite:d.quantite,
                prix:d.prix
            }));
            await axios.post(`/achats-multiples`,a);
            appContext.addMessage('Enregistrement effectué avec succès','success');
            history.push("/achats")
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
    }

    const colonnes = [
        { title: 'N°', field: 'index', editable:'never',
            read_only:true,width:'0.5%',
            cellStyle:{witheSpace:'nowrap'},
            // headerStyle:{width:300,maxWidth:300},
            // initialEditValue:achat.details.length+1,
            render: rowData => rowData ? rowData.tableData.id+1 : achats.length+1
        },
        { title: 'Date', field: 'date_achat',
            editComponent: props => (
                <MyDateField 
                    name="date_achat"
                    value={props.value|""}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    label=" "
                />
            ),
            // render:rowData=>rowData['article'] ? rowData['article'].nom : ""
        },
        { title: 'Fournisseur', field: 'fournisseur',
            editComponent: props => (
                <MySelectField 
                    name="fournisseur"
                    value={props.value && props.value.id}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    options={fournisseurs}
                    libelle="nom"
                    label=" "
                />
            ),
            render:rowData=>rowData['fournisseur'] ? rowData['fournisseur'].nom : ""
        },
        { title: 'Article', field: 'article',
            editComponent: props => (
                <MySelectField 
                    name="article"
                    value={props.value && props.value.id}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    options={articles}
                    libelle="nom"
                    label=" "
                />
            ),
            render:rowData=>rowData['article'] ? rowData['article'].nom : ""
        },
        { title: 'Quantite', field: 'quantite',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    inputProps={{
                        min:"0"
                    }}
                    name="quantite"
                    value={props.value||0}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    type="number"
                    label=" "
                />
            )
        },
        { title: 'Unite', field: 'unite_stockage',editable:'never' },
        // { title: 'Unite', field: 'unite_stockage',
        //     editComponent: props => (
        //         <MySelectField 
        //             name="unite_stockage"
        //             value={props.value && props.value.id}
        //             onChange={e=>handleChangeDetail(e,props)}
        //             errors={props.rowData.errors||[]}
        //             options={unites}
        //             libelle="libelle"
        //             label=" "
        //         />
        //     ),
        //     render:rowData=>rowData['unite_stockage'] ? rowData['unite_stockage'].libelle : ""
        // },
        { title: 'Prix', field: 'prix',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    value={props.rowData.prix}
                    inputProps={{
                        min:"0"
                    }}
                    name="prix"
                    onChange={e=>handleChangeDetail(e,props)}
                        errors={props.rowData.errors||[]}
                        type="number"
                        label=" "
                    />
            )
        },
        { title: 'Montant', field: 'montant',editable:'never',
            read_only:true,initialEditValue:"0",
            // render:rowData=>rowData.montant && util.separteurMillier(rowData.montant)
        },
    ];

    const rowAdd =  (newData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.achatMultiple);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    setAchats([...achats,{...newData,errors:{}}])
                    resolve();
                }      
            } catch (error) {
                reject()
            }
        })
    }
    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.achatMultiple);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    const a = achats.map(d=>d.tableData.id===oldData.tableData.id?newData:d);
                    setAchats(a)
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }
    const rowDelete = (oldData)=>{
        return new Promise((resolve,reject)=>{
            let a = achats.filter(d=>d.tableData.id!==oldData.tableData.id)
            a = a.map((ds,i)=>({...ds,index:i+1}));
            setAchats(a)
            resolve()
        })
    }
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} achat multiple
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <MaterialTable
                            title={`Liste des achats`}
                            icons={IconMaterialTable}
                            columns={colonnes}
                            data={achats}
                            tableRef={refListe}
                            editable={{
                                onRowUpdate:rowUpdate,
                                onRowAdd:rowAdd,
                                onRowDelete:rowDelete
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize:5,
                                pageSizeOptions:[5,10,15],
                                // debounceInterval:1000,
                                filtering:false,
                                search:false
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row-reverse" spacing={3} style={{margin:5}} >
                    <Grid item xs={6} sm={4} lg={3}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={enregistrer}
                        >
                            {id ? 'Modifier' : 'Enregistrer'}
                        </Button>
                    </Grid>
                </Grid>

            </div>
        </Container>
    )
};