export default {
    login:{
        username:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        password:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        
    },
    reinitialisation:{
        password_actuel:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        password:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        password_confirmation:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },

    }
}