import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/NumeroCompte';
import MyTextField from '../Codification/MyTextField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }, 
}))
const initData = {
    banque:"",
    errors:{}
};
export default function NumeroCompte(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [numeroCompte,setNumeroCompte] = React.useState({...initData})
    const [banques,setBanques] = React.useState([])
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [banques] = await Promise.all([
                axios.get(`/banques`),
            ]);
            if(id){
                const {data} = await axios.get(`/numero-comptes/${id}`);
                let numeroCompte = {
                        id: data.id,
                        banque:data.banque.id,
                        numero:data.numero,
                        description: data.description,
                        errors:{}
                    }
                if(pathname===`/numero-comptes/view/${id}`){
                    setView(true);
                    numeroCompte = {
                        ...numeroCompte,
                        banque:data.banque
                    }
                }
                setNumeroCompte(numeroCompte)
            }
            setBanques(banques.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setNumeroCompte({...numeroCompte,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(numeroCompte,constraint.add);
        if(errors){
            setNumeroCompte({...numeroCompte,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/numero-comptes`,{...omit(numeroCompte,['errors','prestataire'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setNumeroCompte({...initData})
            }else{
                history.push("/liste-comptes")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(numeroCompte,constraint.add);
        if(errors){
            setNumeroCompte({...numeroCompte,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/numero-comptes/${numeroCompte.id}`,{...omit(numeroCompte,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Numero Compte
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="banque"
                                value={numeroCompte.banque && numeroCompte.banque.libelle}
                                label="Banque"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="banque"
                                value={numeroCompte.banque}
                                onChange={handleChange}
                                errors={numeroCompte.errors}
                                options={banques}
                                libelle="libelle"
                                disabled={view}
                                label="Banque"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            name="numero"
                            value={numeroCompte.numero}
                            onChange={handleChange}
                            errors={numeroCompte.errors}
                            label="numero"
                            variant="outlined"
                            disabled={view}
                            required={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={numeroCompte.description}
                            onChange={handleChange}
                            errors={numeroCompte.errors}
                            label="Description"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            required={false}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};