import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

const errorHandlerAjv = (errors) => {
    //localize(errors);
    return errors.map(e=>{
        return `${e.dataPath} ${e.message}`; 
    })
}
const findIdById = (values,id) =>{
    if(!Array.isArray(values)){
        if(values.prototype.hasOwnProperty('id')) return values.id
        return null;
    }
    const value = values.find(v=>v.id===id);
    return value||null;
}
const getContentError = (error) => {
    // if(error.response) return error.response.data.message
    if(error.response && error.response.data){
        let data = error.response.data;
        if(typeof data === "string") return data
        if(Array.isArray(data)){
            let texte = ""
            data.forEach(d=>{
                if(typeof d === "string") texte += d
            })
            return texte;
        }
        if(typeof data === "object"){
            let texte =" "
            Object.keys(data).forEach(k=>{
                if(Array.isArray(data[k])){
                    data[k].forEach(m=>{
                        if(typeof m === "string") texte += m
                    })
                }
            })
            if(texte!==" ") return texte
        }

        if (data.non_field_errors) return data.non_field_errors
        if (data.detail) return data.detail;
    
    }
    return error.message
}
const getMessageConstraintError = (errors)=>{
    // console.log(errors)
    return errors ? errors.join('-'): '';
}

const getLibelle = (values,code,field,fieldLibelle)=>{
    if(values.length===0) return;
    const index = findIndex(values,v=>v[field]===code[field]);
    return index===-1?'':values[index][fieldLibelle];
}

function separteurMillier(a,b){
    a = '' + a;
    b = b || ' ';
    var c = '',
        d = 0;
    while (a.match(/^0[0-9]/)) {
    a = a.substr(1);
    }
    for (var i = a.length-1; i >= 0; i--) {
    c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
    d++;
    }
    return c;
}

function get_alveoles(nbre_oeufs){
    if(nbre_oeufs && nbre_oeufs !== 0){
        let nbre_oeufs_alveole = 30
        let nbre = parseInt(nbre_oeufs / nbre_oeufs_alveole);
        let reste = nbre_oeufs % nbre_oeufs_alveole
        let texte = nbre > 1 ? nbre+" Alveoles" : nbre === 1 ? "1 Alveole" : "";
        texte += reste>1 ?" "+reste+" Oeufs": reste ===1 ?" 1 Oeuf": ""
        return texte
    }else{
        return ""
    }
}

const MOIS = [
    {"id":"01","libelle":"Janvier"},
    {"id":"02","libelle":"Février"},
    {"id":"03","libelle":"Mars"},
    {"id":"04","libelle":"Avril"},
    {"id":"05","libelle":"Mai"},
    {"id":"06","libelle":"Juin"},
    {"id":"07","libelle":"Juillet"},
    {"id":"08","libelle":"Août"},
    {"id":"09","libelle":"Septembre"},
    {"id":"10","libelle":"Octobre"},
    {"id":"11","libelle":"Novembre"},
    {"id":"12","libelle":"Decembre"},
]
function get_libelle_mois(no_mois){
    if(!no_mois) return ""
    return find(MOIS,m=>m.id===no_mois).libelle
}
function get_nbre_oeufs(nbre_alveoles){
    if(!nbre_alveoles) return 0
    let [ i,d ] = nbre_alveoles.toString().split(".")
    if(!d) return parseInt(i)*30
    if(parseInt(d)>=30) return ""
    return (parseInt(i)*30)+parseInt(d)
}
export default {
    errorHandlerAjv,
    findIdById,
    getContentError,
    getMessageConstraintError,
    getLibelle,
    separteurMillier,
    get_alveoles,
    MOIS,
    get_libelle_mois,
    get_nbre_oeufs
}
