import React from 'react';
import {useParams } from 'react-router-dom';
import Liste from '../Codification/Liste';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
// import find from 'lodash/find';
import util from '../../utils/util';
import validate from '../../utils/validate';
import MySelectField from '../Codification/MySelectField';
import find from 'lodash/find';
import MyTextField from '../Codification/MyTextField';


const constraint = {
    lien:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        }
    },   
    matricule:{
        presence: {
            allowEmpty: false,
            message:"est obligatoire"
        }
    },   
}
export default function ListeElevesParResponsable(){
    const appContext = React.useContext(AppContext);
    const [responsable,setResponsable] = React.useState([]);
    // const [eleves,setEleves] = React.useState([])
    const [liens,setLiens] = React.useState([])
    // const history = useHistory();
    const {id} = useParams();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [liens,responsable] = await Promise.all([
                axios.get(`/lien-responsables`),
                axios.get(`/responsables/${id}`),
            ]);
            setResponsable(responsable.data)  
            setLiens(liens.data.results)
            // console.log(responsable)   
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);

    const handleChange = (e,props)=>{
        let data = {...props.rowData};
        const listes = {
            lien:liens,
        }
        if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
            const values = listes[props.columnDef.field];
            const value = find(values,v=>v.id===parseInt(e.target.value));
            if(value){
                data = {...data,
                    [props.columnDef.field]:value,
                }
                props.onChange(value)
            }
        }else{
            data = {...data,[props.columnDef.field]:e.target.value}
            props.onChange(e.target.value);
        }
        props.onRowDataChange(data)
    }

    const colonnes = [
        { 
            title: 'Lien', field: 'lien',
            editComponent: props => (
                <MySelectField 
                    name="lien"
                    value={props.value && props.value.id}
                    onChange={e=>handleChange(e,props)}
                    errors={props.rowData.errors||[]}
                    options={liens}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['lien'] ? rowData['lien'].libelle : ""
        },
        { 
            title: 'Matricule', field: 'matricule',
            editComponent: props =>(
                <MyTextField 
                    name="matricule"
                    // value={props.value}
                    value={props.rowData.eleve ? props.rowData.eleve.matricule : props.value}
                    onChange={e=>handleChange(e,props)}
                    errors={props.rowData.errors||[]}
                    type="text"
                    label=" "
                />
            ),
            render:rowData=>rowData['eleve'] ? rowData['eleve'].matricule : ""
        },
        { title: 'Nom', field: 'eleve.nom',editable:'never'},
        { title: 'Prénom', field: 'eleve.prenom',editable:'never' },
        { title: 'Tel', field: 'eleve.telephone',editable:'never',},
        { title: 'Classe', field: 'eleve.salle.nom',editable:'never'},
    ];
    const rowAdd =  (newData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    let r = {
                        lien:newData.lien.id,
                        matricule:newData.matricule,
                        responsable:id
                    }
                    await axios.post(`/responsabilites`,r);
                    appContext.addMessage('Enregistrement effectué avec succès','success');
                    resolve();
                }      
            } catch (error) {
                reject()
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');    
            }
        })
    }
    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            let data = {
                id:newData.id,
                matricule:newData.eleve.matricule,
                lien:newData.lien.id,
                responsable:newData.responsable.id,
                eleve:newData.eleve.id
            }
            try {
                const errors = validate(data,constraint);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    await axios.put(`/responsabilites/${data.id}`,data);
                    appContext.addMessage('Modification effectuée avec succès','success');
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }
    return (
            <Liste  
                // table='eleve'
                // urlBase={`responsabilites`}
                urlBaseGET={`responsabilites?responsable.id=${id}`}
                title={`Liste des eleves de : ${responsable.full_name}`}
                values={[]} 
                // setValues={setEleves}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:rowUpdate,
                    onRowAdd:rowAdd
                }}

                // maxWidth={"md"}
                // transformDataBeforeSent={data=>({...data,responsable:id})}
            />
    )
};

