import React from 'react';
import {TextField,FormControl, FormHelperText} from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import util from '../../utils/util'

export default function MyDateFiel({name,type,label,value,onChange,disabled=false,errors,...rest}){
    return(
        <FormControl fullWidth error={errors[[name]]?true:false}>
            <TextField
                    name={name}
                    required={rest.required||true}
                    type={type||"date"}
                    disabled={disabled}
                    fullWidth={rest.fullWidth||true}
                    id={name}
                    label={label||capitalize(name)}
                    value={value||""}
                    onChange={onChange}
                    InputLabelProps={{
                        shrink: true,
                    }}        
                    error={errors[[name]]?true:false}
                    // helperText={errors ? util.getMessageConstraintError(errors[[name]]):''}    
            />
            <FormHelperText>{errors ? util.getMessageConstraintError(errors[[name]]):''}</FormHelperText>
        </FormControl>
)
}