import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';
import util from '../../utils/util';

export default function ListeSubvention(){
    const [subventions,setSubventions] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_subvention', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date_debut"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Source', field: 'source_subvention.nom' },
        { title: 'Description', field: 'description' },
        { title: 'Montant', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant) 
        },
    ];
    return (
            <Liste table='subvention' 
                values={subventions} 
                setValues={setSubventions}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouveau subvention',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/subventions")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher subvention',
                        onClick: (event, rowData) => history.push(`/subventions/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier subvention',
                        onClick: (event, rowData) => history.push(`/subventions/${rowData.id}`)
                    },
                ]}

            />
    )
};

