import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Grid,TextField} from '@material-ui/core';
import findIndex from 'lodash/findIndex';

// import moment from 'moment';
import axios from '../../utils/axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';

// import validate from '../../validate';
import util from '../../utils/util';
import IconMaterialTable from '../../IconMaterialTable';
import SelectRoles from './SelectRoles';

const useStyles = makeStyles(theme => ({
    root: {
        // width: '80%',
        marginTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowX: 'auto',
    },
    paper:{
        width:'100%'
    },
    title : {
        marginTop: theme.spacing(2)
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))

export default function Liste(){
    const classes = useStyles();
    const [users,setUsers] = React.useState([]);
    const [roles,setRoles] = React.useState([]);
    const [listeRoles] = React.useState({}); // transforme role en objet pour material-table
   
    const appContext = React.useContext(AppContext);
    const refListeUser = React.useRef(null);
    const colonnes = [
        { title: 'Username', field: 'username', editable: 'onAdd' },
        { title: 'Nom', field: 'last_name' },
        { title: 'Prenom', field: 'first_name' },
        { title: 'Telephone', field: 'telephone' },
        { title: 'Email', field: 'email'},
        {
            title:'Roles',field:'groups',
            render:rowData=> rowData.groups ? rowData.groups.map(g=>g.name).join(','):"",
            editComponent: props=>(
                <SelectRoles roles={roles} 
                    rolesSelected={roles.map(r=>findIndex(props.value,rs=>rs.id===r.id)!==-1?{...r,selected:true}:{...r,selected:false})} 
                    onChange={props.onChange} 
                />
            ),
            lookup:listeRoles,
        },
        { title: 'Password', 
            field: 'password',
            editComponent: props => (
            <TextField
                type="password"
                placeholder="Password"
                name={props.name}
                value={props.value||''}
                onChange={e => props.onChange(e.target.value)}
                InputProps={{style: {fontSize: 13}}}
          />)
        },
        // {title:'Roles',field:'roles',
        //     render:rowData=>rowData.roles ? rowData.roles.join(','):"",
        //     editComponent: props=>(
        //         <SelectRoles roles={roles} rolesSelected={props.value} onChange={props.onChange} />
        //     ),
        //     lookup:listeRoles

        // },
    ];
    const fetchUsers = async ()=>{
        try {
            const [users,roles] = await Promise.all([
                axios.get(`/liste-users?app_id=${appContext.appSelected.id}`),
                axios.get(`/liste-groups?app_id=${appContext.appSelected.id}`),
                // axios.get('/roles'),
            ]);
            setUsers(users.data.results);
            setRoles(roles.data.results);

            /*const listeRoles = {};
            roles.data.forEach(r=>listeRoles[r.code_role]=r.code_role);
            setListeRoles(listeRoles);*/
            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }
    React.useEffect(()=>{
        fetchUsers();
        // eslint-disable-next-line
    },[]);
    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const res = await axios.put(`/users/${newData.id}?app_id=${appContext.appSelected.id}`, {...newData});
                const user = res.data;
                // eslint-disable-next-line
                const us = users.map(u=>u.id==user.id?user:u);
                setUsers(us);
                appContext.addMessage('Modification effectuée avec succès','success');
                resolve();
                fetchUsers();
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }

    const rowAdd =  (newData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const res = await axios.post(`/users?app_id=${appContext.appSelected.id}`,{...newData});
                const user = res.data;
                setUsers([...users,user]);
                appContext.addMessage('Ajout effectuée avec succès','success');
                resolve();
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        })
    }

    return (
        <Grid container className={classes.root} color="primary">
            <Grid item xs={12}>
                <Typography variant="h4" color="primary" align="center" className={classes.title}>
                        Liste des utilisateurs
                </Typography>
                <MaterialTable
                    title=""
                    icons={IconMaterialTable}
                    columns={colonnes}
                    data={users}
                    tableRef={refListeUser}
                    editable={{
                        onRowUpdate:rowUpdate,
                        onRowAdd:rowAdd
                    }}
                    actions={[
                        rowData => ({
                            icon:rowData.is_active ?'lock_open':'lock',
                            tooltip:rowData.is_active ?'Desactiver': 'Activer',
                            iconProps:{color:rowData.bloquer ? '#d93025':'primary'},
                            onClick:(e,data)=>{
                                //afficher une boite de confirmation
                                let etat = rowData.is_active ? 'desactiver' : 'activer';
                                appContext.setConfirmationMessage({
                                    content:`Voulez-vous ${etat} cet utilisateur`,
                                    title:'Confirmation',
                                    valider: async ()=>{
                                        try {
                                            await axios.put(`/users/${data.id}/${etat}`);
                                            appContext.addMessage('Utilisateur modifié avec succès','success');
                                            //refresh de lapage
                                            fetchUsers();
                                        } catch (error) {
                                            console.log(error,error.response);
                                            appContext.addMessage(util.getContentError(error),'error');
                                        }
                                        appContext.setConfirmationMessage(null);
                                    }
                                })
                            }        
                        })
                    ]}
                />
            </Grid>
        </Grid>
    )
};

