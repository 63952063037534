import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Liste from '../Codification/Liste';
import MySelectField from '../Codification/MySelectField';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';
import find from 'lodash/find';



export default function ListeMois(){
    const appContext = React.useContext(AppContext);
    const [mois,setMois] = React.useState([]);
    const [reload,setReload] = React.useState(false)
    const history = useHistory();

    React.useEffect(()=>{
        if(reload) setReload(false)
    },[reload])

    const colonnes = [
            { title: 'Annee', field: 'annee' },
            { title: 'Mois', field: 'no_mois',
            editComponent: props => (
                <MySelectField 
                    name="no_mois"
                    value={props.value && props.value}
                    onChange={(e,v)=>handleChange(e,v,props)}
                    errors={props.rowData.errors||[]}
                    options={util.MOIS}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['no_mois'] ? util.get_libelle_mois(rowData['no_mois']): ""    
            },
            { title: 'Libelle', field: 'libelle',editable:'never'},
            // render:rowData=>rowData['no_mois'] ? util.get_libelle_mois(rowData['no_mois'])+' '+rowData['annee']: ""     },
            { title: 'Montant', field: 'montant',editable:'never',filtering:false,
                cellStyle:{textAlign:"right"},
                headerStyle:{textAlign:'right'},
                render : rowData=>rowData && util.separteurMillier(rowData.montant)   
            },
            { title: 'Etat', field: 'flag_cloture',editable:'never',filtering:false,
            render:rowData=>rowData && <div className={`badge badge-${rowData.flag_cloture?'success':'warning'} px-4`}>
                {rowData.flag_cloture ?'Clôturé':'Encours'}
            </div>
            },
            { title: 'Date clôture', field: 'date_cloture',editable:'never',filtering:false, 
            render : rowData=>rowData && rowData.date_cloture ? moment(rowData.date_cloture).format('DD/MM/YYYY'): ""
            },

    ];
    const handleChange = (e,valeur,props)=>{
        let data = {...props.rowData};
        const listes = {
            no_mois:util.MOIS,
        }
        if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
            if(valeur===""){
                props.onChange(valeur)
            }else{
                const values = listes[props.columnDef.field];
                const value = find(values,v=>parseInt(v.id)===parseInt(valeur));
                if(value){
                    data = {...data,
                        [props.columnDef.field]:valeur,
                    }
                    props.onChange(valeur)
                }
            }
        }else{
            props.onChange(e.target.value);
        }
        data = {
            ...data,
            libelle:util.get_libelle_mois(valeur)+ ' '+data.annee ,
            errors:{}
        }
        props.onRowDataChange(data)

    }

    const cloture = async (mois) => {
        if(mois.flag_cloture) {
            appContext.addMessage('Déjà cloturé','info');
            return;
        }
        appContext.setOpenLoading(true);
        try {
            await axios.put(`/mois/${mois.id}/cloture`);
            mois.flag_cloture = true;
            appContext.addMessage('Mois cloturé avec succès','success');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        } 
        appContext.setOpenLoading(false);   
    }
    const generer = async (moisSelected) => {
        if(moisSelected.flag_cloture) {
            appContext.addMessage('Déjà cloturé','info');
            return;
        }
        appContext.setOpenLoading(true);
        try {
            let res = null;
            if(appContext.appSelected.libelle==='ALTO'){
                res = await axios.post(`/mois/${moisSelected.id}/generer-bulletins-alto`);
            }else if(appContext.appSelected.libelle==='BRIQUETERIE'){
                res = await axios.post(`/mois/${moisSelected.id}/generer-bulletins-briqueterie`);
            }else{
                res = await axios.post(`/mois/${moisSelected.id}/generer-bulletins`);
            }
            setReload(true)
            appContext.addMessage(res.data.message,'success');
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }

    return (
            <Liste table='moi' 
                values={mois} 
                setValues={setMois}
                colonnes={colonnes}
                reload={reload}
                // editable={false}
                // ref={refCodificationListe}
                actions={[
                    rowData=> !rowData.flag_cloture && ({
                        icon: 'create_new_folder',
                        iconProps:{style:{color:green[500]}},
                        tooltip: 'Générer les bulletins',
                        onClick: (event, rowData) =>appContext.setConfirmationMessage({
                            content:'Voulez-vous générer les bulletins',
                            title:'Confirmation',
                            valider: async ()=>{generer(rowData);appContext.setOpenConfirmationMessage(false)},
                            annuler: () => appContext.setOpenConfirmationMessage(false)
                        })
                    }),
                    {
                        icon: 'view_headline',
                        iconProps:{color:'primary'},
                        tooltip: 'Liste des bulletins',
                        onClick: (event, rowData) => history.push(`/liste-bulletins/${rowData.annee}-${rowData.no_mois}`)
                    },
                    rowData => !rowData.flag_cloture && ({
                        icon: rowData.flag_cloture ? 'lock' : 'lock_open',
                        iconProps:{style:{color:rowData.flag_cloture ?green[500]:yellow[500]}},
                        tooltip: 'Clotûrer le mois',
                        onClick: (event, rowData) =>appContext.setConfirmationMessage({
                            content:'Voulez-vous clôturer ce mois',
                            title:'Confirmation',
                            valider: async ()=>{cloture(rowData);appContext.setOpenConfirmationMessage(false)},
                            annuler: () => appContext.setOpenConfirmationMessage(false)
                        })
                    }),
                ]}
            />
    )
};

