import React from 'react';

import Liste from '../Codification/Liste';


export default function ListeArticle(){
    const [sourceSubventions,setSourceSubventions] = React.useState([]);
   
    const colonnes = [
        { title: 'Nom', field: 'nom' },
        { title: 'Telephone', field: 'telephone' },
        { title: 'Email', field: 'email',emptyValue:()=>""},
        { title: 'Adresse', field: 'adresse',emptyValue:()=>""},
    ];
    return (
            <Liste table='sources_subvention' 
                values={sourceSubventions} 
                setValues={setSourceSubventions}
                colonnes={colonnes}

            />
    )
};

