import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import find from 'lodash/find';
import 'Icons'

import Message from './Components/Message/Message';
import ConfirmationDialog from './Components/Message/ConfirmationDialog';
import ModalLoading from './Components/Modals/ModalLoading';

const store = configureStore();
export const AppContext = React.createContext();
function App() {
	const [messages,setMessages] = React.useState([])
    const [user,setUser] = React.useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null)
    const [apps,setApps] = React.useState([]); // liste des apps dont le user a acces
    // const [parametres,setParametres] = React.useState({}); // liste des apps dont le user a acces
    const [menus,setMenus] = React.useState([]); // liste des apps dont le user a acces
    const [appSelected,setAppSelected] = React.useState(localStorage.getItem('app') ? JSON.parse(localStorage.getItem('app')) : null)
    const [projetSelected,setProjetSelected] = React.useState(localStorage.getItem('projet') ? JSON.parse(localStorage.getItem('projet')) : null)
    const [confirmationMessage,setConfirmationMessage] = React.useState(null);
    const [openConfirmationMessage,setOpenConfirmationMessage] = React.useState(false)
    const [openLoading,setOpenLoading] = React.useState(false)
    React.useEffect(()=>{
        if(confirmationMessage) setOpenConfirmationMessage(true);
        else setOpenConfirmationMessage(false);
    },[confirmationMessage])

    React.useEffect(()=>{
        if(!user) return;
        setApps(user.apps);
    },[user])

    React.useEffect(()=>{
        if(!appSelected) return;
        setMenus(appSelected.menus);
        if(appSelected.parametres){
            const parametres = {}
            appSelected.parametres.forEach(p=>parametres[p.nom]=p.valeur);
            // console.log(parametres)
            // setParametres(parametres)
        }
    },[appSelected])
    function getParametres(params) {
        const parametres = {}
        if(params){
            params.forEach(p=>parametres[p.nom]=p.valeur);
        }
        return parametres
    }
    const storeCredentiels = (token,user) => {
        setUser(user);
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
    }
    const logout = () => {
        localStorage.clear();
        setUser(null);
        setMenus([]);
        setAppSelected(null);
    }
    const isAuthenticated = ()=>{
        return user ? true : false;
    }
    const isReseted = ()=>{
        return user.is_reseted
    }
    const isAuthorized = (link)=>{
        let b = false;
       menus.forEach(menu=>{
           console.log(menu)
           let id = find(menu.sousMenus,{link})
           if(id) b=true
       });
       console.log(b)
       return b;
    }

    const addMessage = (content,type)=>{
        setMessages([...messages,{content,type}]);
	}
	
	const app = {
        messages,
        addMessage,
        user,
        setUser,
        storeCredentiels,
        logout,
        isAuthenticated,
        isAuthorized,
        setConfirmationMessage,
        setOpenConfirmationMessage,
        apps,
        appSelected,
        setAppSelected,
        projetSelected,
        setProjetSelected,
        parametres:appSelected ? getParametres(appSelected.parametres) : {},
        menus,
        setMenus,
        isReseted,
        openLoading,
        setOpenLoading,
    }

    return (
		<AppContext.Provider value={app}>
			<Provider store={store}>
				<BrowserRouter basename="/">
				<CssBaseline />
				{app.messages.map((message,key)=>(
						<Message key={key} message={message.content} type={message.type} />
					))
				}
				{openLoading &&
					<ModalLoading open={openLoading} />
				} 
				{confirmationMessage &&
					<ConfirmationDialog title={confirmationMessage.title} content={confirmationMessage.content} 
						open={openConfirmationMessage} 
						valider={confirmationMessage.valider} 
						annuler={()=>setOpenConfirmationMessage(false)}
					/>
				} 
				<ScrollToTop>
					<Routes />
				</ScrollToTop>
				</BrowserRouter>
			</Provider>
		</AppContext.Provider>
    );
}

export default App;
