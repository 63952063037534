import React from 'react';

import Liste from '../Codification/Liste';


export default function ListeArticle(){
    const [fournisseurs,setFournisseurs] = React.useState([]);
   
    const colonnes = [
        { title: 'Nom', field: 'nom' },
        { title: 'Telephone', field: 'telephone' },
        { title: 'Email', field: 'email',emptyValue:()=>""},
        { title: 'Adresse', field: 'adresse',emptyValue:()=>""},
    ];
    return (
            <Liste table='fournisseur' 
                values={fournisseurs} 
                setValues={setFournisseurs}
                colonnes={colonnes}

            />
    )
};

