import React from 'react';
// import { useHistory } from 'react-router-dom';
import Liste from '../Codification/Liste';
// import { AppContext } from '../../App';
export default function ListeTranchePaiement(){
    const [tranches,setTranches] = React.useState([]);
    // const history = useHistory();
    const colonnes = [
        { title: 'Libelle', field: 'libelle' },
        // { title: 'Frais scolarité', field: 'montant' },
        { title: 'Description', field: 'description' },
    ];
    return (
            <Liste 
                table='tranche' 
                values={tranches} 
                setValues={setTranches}
                colonnes={colonnes}
                maxWidth={"md"}
                // transformDataBeforeSent={data=>({...data,unite:data.unite ? data.unite.id: null})}
            />
    )
};

