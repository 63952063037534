import React from 'react';
import TextField  from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import Liste from '../Codification/Liste';

export default function ListeOperations(){
    const [operations,setOperations] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_operation', type:'date',
            filterComponent: props =>
            <TextField
                id="date"
                name="date_operation"
                type="date"
                value={props.columnDef.tableData.filterValue||""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
            />
    
        },
        { title: 'Description', field: 'description'},
    ];
    return (
            <Liste urlBaseGET='operations-fabriques' 
                title='Liste des operations'
                values={operations} 
                setValues={setOperations}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        tooltip: 'Nouvelle opération',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/operations-fabriques")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher',
                        onClick: (event, rowData) => history.push(`/operations-fabriques/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier',
                        onClick: (event, rowData) => history.push(`/operations-fabriques/${rowData.id}`)
                    },
                ]}

            />
    )
};

