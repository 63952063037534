import React from 'react';

import Liste from '../Codification/Liste';

export default function ListeFonction(){
    const [fonctions,setFonctions] = React.useState([]);


    const colonnes = [
            { title: 'Libelle', field: 'libelle' },
];
    return (
            <Liste table='fonction' 
                values={fonctions} 
                setValues={setFonctions}
                colonnes={colonnes}

            />
    )
};

