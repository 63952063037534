import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';

import Liste from '../Codification/Liste';

export default function ListeRamassage(){
    const [ramassages,setRamassages] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_ramassage', type:'date',
            filterComponent: props =>
            <TextField
                id="date"
                name="date_ramassage"
                type="date"
                value={props.columnDef.tableData.filterValue||""}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
            />
    
        },
        { title: 'Hangar', field: 'hangar_bande.hangar.libelle'},
        { title: 'Bande', field: 'hangar_bande.bande.nom'},
        { title: 'Quantite', field: 'nbre_oeufs' },
        { title: 'Volailler', field: 'volailler.username' },
    ];
    return (
            <Liste table='ramassage' 
                values={ramassages} 
                setValues={setRamassages}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouveau ramassage',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/ramassages")
                    },
                    {
                        icon: 'view_headline',
                        iconProps:{style:{fontSize:50,color:orange[500]}},
                        tooltip: 'Ramassage journalier',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/ramassage-journalier")
                    },
                    {
                        icon: 'edit',
                        iconProps:{style:{color:yellow[500]}},
                        tooltip: 'Modifier ramassage',
                        onClick: (event, rowData) => history.push(`/ramassages/${rowData.id}`)
                    },
                ]}

            />
    )
};

