import React, { Fragment } from 'react';

import PagesError500Content from '../../Components/PagesError500/PagesError500Content';
export default function PagesError500() {
  return (
    <Fragment>
      <PagesError500Content />
    </Fragment>
  );
}
