import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';
import util from '../../utils/util';

export default function ListePaiement(){
    const [paiements,setPaiements] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_paiement', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date_debut"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Mois',field:'paiement_location.mois.libelle'},
        { title: 'Annee',field:'paiement_location.annee.valeur'},
        { title: 'Appartement', field: 'paiement_location.contrat_location.appartement.info'},
        { title: 'Description', field: 'description'},
        { title: 'Locataire', field: 'paiement_location.contrat_location.locataire.full_name' },
        { title: 'Montant paye', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant) 
        },
        { title: 'Reste', field: 'montant_restant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant_restant) 
        },
    ];
    return (
            <Liste table='paiements-location' 
                values={paiements} 
                setValues={setPaiements}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouveau paiement',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/paiements-locations")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher paiement',
                        onClick: (event, rowData) => history.push(`/paiements-locations/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier paiement',
                        onClick: (event, rowData) => history.push(`/paiements-locations/${rowData.id}`)
                    },
                ]}

            />
    )
};

