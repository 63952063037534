import React from 'react';
import {useParams } from 'react-router-dom';
import Liste from '../Codification/Liste';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
// import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Print from '@material-ui/icons/Print';

// import find from 'lodash/find';
import util from '../../utils/util';
import MyDateField from '../Codification/MyDateField';
// import parameters from '../../config/parameters'
const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      },
    montant:{
        textAlign:'right'
    }
}));

export default function ListePaiementsParScolarite(){
    const classes = useStyles();
    const appContext = React.useContext(AppContext);
    const [scolarite,setScolarite] = React.useState({})
    const [paiement,setPaiement] = React.useState({})
    const [paiements,setPaiements] = React.useState([])

    // const history = useHistory();
    const {id} = useParams();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [scolarite] = await Promise.all([
                // axios.get(`/lien-responsables?eleve.id=${id}`),
                axios.get(`/scolarites/${id}`),
            ]);
            setScolarite(scolarite.data)
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);

    const handleChange = (e,props)=>{
        // console.log(paiement)
        setPaiement({...paiement,[e.target.name]:e.target.value})
        props.onChange(e.target.value);
        // props.onRowDataChange(paiement)
    }
    const colonnes = [
        { title: 'Date', field: 'date_paiement',
            editComponent: props => (
                <MyDateField 
                    name="date_paiement"
                    value={props.value||""}
                    onChange={e=>handleChange(e,props)}
                    errors={props.rowData.errors||[]}
                    label=" "
                />
            )
        },
        { title: 'Payeur',field:'payeur'},
        { title: 'Montant', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.montant) 
        },
        { title: 'Description',field:'description'},
    ];
    const imprimer = async (recu) =>{
        try {            
            const  res = await axios.get(`recu/paiements-scolarites/${recu.id}`,{responseType:'blob'});
            const file = new Blob([res.data],{type:'application/pdf'})
            const fileURL = URL.createObjectURL(file)
            window.open(fileURL)
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                <Typography variant="h3" gutterBottom>
                    Paiments scolarite {scolarite.eleve ? scolarite.eleve.matricule +' '+ scolarite.eleve.full_name : ''}
                </Typography>
                <Grid container spacing={1}>
                    <Liste  
                        table='paiements-scolarite'
                        // urlBase={`/paiements-scolarites?scolarite.id=${id}`}
                        urlBaseGET={`/paiements-scolarites?scolarite.id=${id}`}
                        // title={`Paiments d'scolarite de : ${scolarite.eleve ? scolarite.eleve.matricule +' '+ scolarite.eleve.full_name : ''} `}
                        title={`Liste`} 
                        values={paiements} 
                        setValues={setPaiements}
                        colonnes={colonnes}
                        actions={[
                            rowData=> ({
                                icon:()=> (
                                    <IconButton color="primary" 
                                    aria-label="upload picture" 
                                    component="span" 
                                    >
                                        <Print />
                                    </IconButton>
                                ),
                                iconProps:{style:{color:green[500]}},
                                tooltip: 'Imprimer',
                                onClick: (event, rowData) => imprimer(rowData)
                                // onClick: (event, rowData) => <PDFDownloadLink document={<Recu />} fileName='Recu.pdf' style={{textDecoration:'none'}}/>
                            }),
                        ]}

                        // maxWidth={"sm"}
                        transformDataBeforeSent={data=>({...data,scolarite:id})}
                    />                
                </Grid>
        </div>

        </Container>
    )
};

