export default {
    add:{
        marque:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        nom:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },       
    }
}