import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper,FormControl,FormHelperText } from '@material-ui/core';
import omit  from 'lodash/omit';
import find  from 'lodash/find';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/PaiementLocation';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    date_paiement:"",
    contrat:"",
    errors:{}
};
export default function Paiement(){
    const classes = useStyles();
    const history = useHistory();
    const { id,idContrat,idPaiement } = useParams()
    const appContext = React.useContext(AppContext);
    const [paiement,setPaiement] = React.useState({...initData})
    const [mois,setMois] = React.useState([])
    const [annees,setAnnees] = React.useState([])
    const [locataires,setLocataires] = React.useState([])
    const [contrats,setContrats] = React.useState([])
    const [contratSelectionne,setContratSelectionne] = React.useState(null)
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [locataires,mois,annees] = await Promise.all([
                axios.get(`/locataires`),
                axios.get(`/mois-parametres`),
                axios.get(`/annees`),
            ]);
            setLocataires(locataires.data.results)   
            setMois(mois.data.results)         
            setAnnees(annees.data.results)         
            if(id){ // modification d'un paiement
                const {data} = await axios.get(`/paiements-locations/${id}`);
                let paiement = {
                        id: data.id,
                        date_paiement:data.date_paiement,
                        mois: data.paiement_location.mois.id,
                        annee: data.paiement_location.annee.id,
                        locataire: data.paiement_location.contrat_location.locataire.id,
                        contrat: data.paiement_location.contrat_location.id,
                        montant:data.montant,
                        description: data.description,
                        errors:{}
                }
                const contratSelectionne = {
                    montant_paye:data.paiement_location.montant_paye,
                    montant_a_payer:data.paiement_location.montant_a_payer
                }
                setContratSelectionne(contratSelectionne)
                setContrats([data.paiement_location.contrat_location])
                if(pathname===`/paiements-locations/view/${id}`){
                    setView(true);
                    paiement = {
                        ...paiement,
                        paiement_location:data.paiement_location
                    }
                }
                setPaiement(paiement)

                // setPrestataireContrats(contrats.data.results.filter(c=>c.prestataire.id===data.contrat.prestataire.id && !data.contrat.flag_termine))
            }else if(idContrat){ // ajout d'un paiement à partir d'un contrat
                if(idPaiement){
                    const {data} = await  axios.get(`/paiements-locations-mensuel/${idPaiement}`);
                    if(data){
                        let paiement = {
                            locataire: data.contrat_location.locataire.id,
                            contrat: data.contrat_location.id,
                            mois:data.mois.id,
                            annee:data.annee.id,
                            errors:{}
                        }
                        setContrats([data.contrat_location])
                        setLocataires([data.contrat_location.locataire])
                        setPaiement(paiement)
                        load_montant(paiement)
                    }                
                }else{
                    const {data} = await  axios.get(`/contrats-locations/${idContrat}`);
                    if(data){
                        let paiement = {
                            locataire: data.locataire.id,
                            contrat: data.id,
                            errors:{}
                        }
                        setContrats([data])
                        setLocataires([data.locataire])
                        setPaiement(paiement)
                    }

                }
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        // if(!idContrat){ // pas besoin d'arrêter le loading, car la page est entièrement recharger
            appContext.setOpenLoading(false);
        // }
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = async (e)=>{
        let data = {[e.target.name]:e.target.value}
        if(e.target.name==='locataire'){
            const loc = find(locataires,l=>l.id===e.target.value)
            data={...data,contrat:""}
            // const cs = loc.contrats.
            setContrats(loc.contrats.filter(c=>!c.flag_termine))
            setContratSelectionne(null)
        }else if(e.target.name === 'mois'){
            if(!e.target.value) {
               data = {...data,locataire:"",contrat:""}
            }
        }else if(e.target.name === 'annee'){
            if(!e.target.value) {
               data = {...data,locataire:"",contrat:""}
            }
        }else if(e.target.name === 'contrat'){
            // const c = find(contrats,c=>c.id===e.target.value);
            data = {contrat:e.target.value}
        }
        const paie = {...paiement,...data};
        if(['locataire','mois','annee','contrat'].indexOf(e.target.name)!==-1) load_montant(paie);
        setPaiement(paie)
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(paiement,constraint.add);
        if(errors){
            setPaiement({...paiement,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/paiements-locations`,{...omit(paiement,['errors','prestataire'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            setContratSelectionne(null)
            if(nouveau){
                if(!idContrat) setPaiement({...initData})
                else fetchData()
            }else{
                history.goBack()
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(paiement,constraint.add);
        if(errors){
            setPaiement({...paiement,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/paiements-locations/${paiement.id}`,{...omit(paiement,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const load_montant = async (paiement) => {
        if(paiement.annee && paiement.mois && paiement.contrat){
            appContext.setOpenLoading(true);
            const res = await axios.get(`/paiements-locations/annee/${paiement.annee}/mois/${paiement.mois}/contrat/${paiement.contrat}`);
            if(res.data){
                setContratSelectionne(res.data)
            }
            appContext.setOpenLoading(false);
        }
    }
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Paiement
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <MyDateField 
                            name="date_paiement"
                            value={paiement.date_paiement||""}
                            onChange={handleChange}
                            errors={paiement.errors}
                            label="Date paiement"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {view ?
                            <MyTextField 
                                name="mois"
                                value={paiement.paiement_location && paiement.paiement_location.mois.libelle}
                                label="Mois"
                                disabled={view}
                            />
                        :
                            <MySelectField 
                                name="mois"
                                value={paiement.mois}
                                onChange={handleChange}
                                errors={paiement.errors}
                                options={mois}
                                libelle="libelle"
                                disabled={view}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {view ?
                            <MyTextField 
                                name="annee"
                                value={paiement.paiement_location && paiement.paiement_location.annee.valeur}
                                label="Annee"
                                disabled={view}
                            />
                        :
                            <MySelectField 
                                name="annee"
                                value={paiement.annee}
                                onChange={handleChange}
                                errors={paiement.errors}
                                options={annees}
                                libelle="valeur"
                                disabled={view}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        {view ?
                            <MyTextField 
                                name="locataire"
                                value={paiement.paiement_location && paiement.paiement_location.contrat_location.locataire.full_name}
                                label="Locataire"
                                disabled={view}
                            />
                        :
                            <MySelectField 
                                name="locataire"
                                value={paiement.locataire}
                                onChange={handleChange}
                                errors={paiement.errors}
                                options={locataires}
                                libelle="full_name"
                                disabled={view}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        {view ?
                            <MyTextField 
                                name="contrat"
                                value={paiement.paiement_location && paiement.paiement_location.contrat_location.info}
                                label="Objet contrat"
                                variant="outlined"
                                disabled={view}
                                required={false}
                            />
                        :
                        <FormControl fullWidth>
                            <MySelectField 
                                name="contrat"
                                value={paiement.contrat}
                                onChange={handleChange}
                                errors={paiement.errors}
                                options={contrats}
                                libelle="info"
                                disabled={view}
                            />
                        </FormControl>
                        }
                        {contratSelectionne &&
                            <React.Fragment>
                                <FormHelperText>Montant payé:{util.separteurMillier(contratSelectionne.montant_paye)}</FormHelperText>
                                <FormHelperText>Montant restant:{util.separteurMillier(contratSelectionne.montant_a_payer-contratSelectionne.montant_paye)}</FormHelperText>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={paiement.description}
                            onChange={handleChange}
                            errors={paiement.errors}
                            label="Description du paiement"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            required={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(paiement.montant):paiement.montant}
                            onChange={handleChange}
                            errors={paiement.errors}
                            label="Montant du paiement"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};