import React from 'react';
import {useParams } from 'react-router-dom';
import Liste from '../Codification/Liste';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
// import find from 'lodash/find';
import util from '../../utils/util';
export default function ListeClasseUnite(){
    const appContext = React.useContext(AppContext);
    const [classe,setClasse] = React.useState([]);
    const [salles,setSalles] = React.useState([])
    // const history = useHistory();
    const {id} = useParams();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [classe,salles] = await Promise.all([
                axios.get(`/classes/${id}`),
                axios.get(`/salles?classe.id=${id}`)
            ]);
            setClasse(classe.data)     
            setSalles(salles.data.results)      
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);

    const colonnes = [
        { title: 'Libelle', field: 'libelle' },
    ];
    return (
            <Liste  
                table='salle'
                // urlBase={`classe-unites?classe.id=${id}`}
                urlBaseGET={`salles?classe.id=${id}`}
                title={`Liste des salles : ${classe.libelle}`}
                values={salles} 
                setValues={setSalles}
                colonnes={colonnes}
                // maxWidth={"md"}
                transformDataBeforeSent={data=>({...data,classe:id})}
            />
    )
};

