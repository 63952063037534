import React, { Fragment } from 'react';

import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';

import { connect } from 'react-redux';

import projectLogo from '../../assets/images/react.svg';
import { AppContext } from '../../App';

const HeaderLogo = props => {
	const { sidebarToggle, sidebarHover } = props;
	const appContext = React.useContext(AppContext);
	const app = appContext.appSelected ? appContext.appSelected.libelle.toUpperCase() : "Triton";
	const projet = appContext.projetSelected ? appContext.projetSelected.nom.toUpperCase() : "Triton"
	const title = `${app}(${projet})`

	return (
		<Fragment>
			<div
				className={clsx('app-header-logo', {
					'app-header-logo-close': sidebarToggle,
					'app-header-logo-open': sidebarHover
				})}>
				<Box 
					className="header-logo-wrapper"
					title={title}>
					<Link to="/" className="header-logo-wrapper-link">
						<IconButton
							color="primary"
							size="medium"
							className="header-logo-wrapper-btn">
							<img
								className="app-header-logo-img"
								alt="{"
								src={projectLogo}
							/>
						</IconButton>
					</Link>
					<Box className="header-logo-text">{projet}</Box>
					{/* <Box className="header-logo-text">{projet}</Box> */}
				</Box>
			</div>
		</Fragment>
	);
};

const mapStateToProps = state => ({
	sidebarToggle: state.ThemeOptions.sidebarToggle,
	sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
