import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/OperationVehicule';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }, 
}))
const initData = {
    date_operation:"",
    salaire:0,
    vehicule:"",
    errors:{}
};
export default function Operation(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [operation,setOperation] = React.useState({...initData})
    const [vehicules,setVehicules] = React.useState([])
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [vehicule] = await Promise.all([
                axios.get(`/vehicules`),
            ]);
            if(id){
                const {data} = await axios.get(`/operations-vehicules/${id}`);
                let operation = {
                        id: data.id,
                        date_operation:data.date_operation,
                        vehicule:data.vehicule.id,
                        montant_recette:data.montant_recette,
                        montant_carburant:data.montant_carburant,
                        salaire:data.salaire,
                        nbre_km_parcouru:data.nbre_km_parcouru,
                        autres_depenses:data.autres_depenses,
                        description: data.description,
                        carburant_consomme:data.carburant_consomme,
                        errors:{}
                    }
                if(pathname===`/operations-vehicules/view/${id}`){
                    setView(true);
                    operation = {
                        ...operation,
                        vehicule:data.vehicule
                    }
                }
                setOperation(operation)
            }
            setVehicules(vehicule.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        if(e.target.name==='montant_recette'){
            let salaire = Math.floor((parseInt(e.target.value)/appContext.parametres.recette)*appContext.parametres.salaire);
            operation.salaire = salaire
        }
        setOperation({...operation,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(operation,constraint.add);
        if(errors){
            setOperation({...operation,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/operations-vehicules`,{...omit(operation,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setOperation({...initData})
            }else{
                history.push("/liste-operations-vehicules")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(operation,constraint.add);
        if(errors){
            setOperation({...operation,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/operations-vehicules/${operation.id}`,{...omit(operation,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Opération
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_operation"
                            value={operation.date_operation||""}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Date operation"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="vehicule"
                                value={operation.vehicule && operation.vehicule.immatriculation}
                                label="Vehicule"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="vehicule"
                                value={operation.vehicule}
                                onChange={handleChange}
                                errors={operation.errors}
                                options={vehicules}
                                libelle="immatriculation"
                                disabled={view}
                                label="Véhicule"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant_recette"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.montant_recette):operation.montant_recette}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Recette"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={4}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant_carburant"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.montant_carburant):operation.montant_carburant}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Mt carburant"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={4}>
                        <MyTextField 
                            inputProps={{
                                // min:"-1",
                                style:{textAlign:'right'}
                            }}
                            name="salaire"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.salaire):operation.salaire}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Salaire"
                            disabled={true}
                            // style={{}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="nbre_km_parcouru"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.nbre_km_parcouru):operation.nbre_km_parcouru}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Km parcouru"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>                                       
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="carburant_consomme"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.carburant_consomme):operation.carburant_consomme}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Consommation(Litre)"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="autres_depenses"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(operation.autres_depenses):operation.autres_depenses}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Mt. autres dépenses"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>                    
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={operation.description}
                            onChange={handleChange}
                            errors={operation.errors}
                            label="Description"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            // required={false}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};