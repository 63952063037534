import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import omit from 'lodash/omit';
import { useHistory,useParams,useLocation } from 'react-router-dom';
// import moment from 'moment';
import find from 'lodash/find';
// import axios from 'axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Commande';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';
import IconMaterialTable from '../../IconMaterialTable';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      },
}));
// const initDataDetail = {
//     article:"",
//     errors:[]
// }

export default function CommandeFournisseur(){
    const initData = {
        // date_commande:moment().format('YYYY-MM-DD'),
        fournisseur:"",
        details:[],
        errors:{}
    };
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [commande,setCommande] = React.useState({...initData})
    const [fournisseurs,setFournisseurs] = React.useState([])
    const [articles,setArticles] = React.useState([])
    // const [unites,setUnites] = React.useState([])
    const refListe = React.useRef(null);
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();

    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [fournisseurs,articles/*,unites*/] = await Promise.all([
                axios.get(`/fournisseurs`),
                axios.get(`/articles`),
                // axios.get(`/unites`),
            ]);
            if(id){
                if(pathname===`/commandes/view/${id}`){
                    setView(true)
                }
                const {data} = await axios.get(`/commandes/${id}`);
                const commande = {
                        id: data.id,
                        date_commande : data.date_commande,
                        fournisseur: data.fournisseur.id,
                        details:data.details.map(d=>({...d,montant:util.separteurMillier(d.montant)})),
                        errors:{}
                    }
                setCommande(commande)
            }
            setFournisseurs(fournisseurs.data.results)            
            setArticles(articles.data.results)            
            // setUnites(unites.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setCommande({...commande,[e.target.name]:e.target.value})
    }
    const handleChangeDetail = (e,props)=>{
        let data = {...props.rowData};
        if(props.columnDef.field==='article'){
            const article = find(articles,a=>a.id===parseInt(e.target.value))
            data = {...data,
                [props.columnDef.field]:article,
                prix: article ? article.prix.toString() : "",
                unite_stockage:article.unite ? article.unite : null
            }
            props.onChange(article)
        }/*else if(props.columnDef.field==='unite_stockage'){
            const unite_stockage = find(unites,a=>a.id===parseInt(e.target.value))
            data = {...data,
                [props.columnDef.field]:unite_stockage,
            }
            props.onChange(unite_stockage)
        }*/
        else{
            data = {...data,[props.columnDef.field]:e.target.value}
            props.onChange(e.target.value);
        }
        data = {
            ...data,
            montant:(parseInt(data.quantite||0)*parseInt(data.prix||0)).toLocaleString('fr-FR'),
            errors:{}
        }
        props.onRowDataChange(data)
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(commande,constraint.add);
        if(errors){
            setCommande({...commande,errors});
            return;
        }else if(commande.details.length===0){
            appContext.addMessage("Aucun article",'  error');
            return
        }
        appContext.setOpenLoading(true);
        try {
            const a = {...omit(commande,['errors'])};
            if(!id){
                a.details = a.details.map(d=>({
                    id:d.id,
                    article:d.article.id,
                    unite_stockage:d.article.unite ? d.article.unite.id : null,
                    quantite:d.quantite,
                    prix:d.prix
                }));
                await axios.post(`/commandes`,a);
                appContext.addMessage('Enregistrement effectué avec succès','success');
            }else{
                a.details = a.details.map(d=>({
                    id:d.id,
                    article:d.article.id,
                    unite_stockage:d.unite_stockage ? d.unite_stockage.id : null,
                    quantite:d.quantite,
                    prix:d.prix
                }));
                await axios.put(`/commandes/${a.id}`,a);
                appContext.addMessage('Modification effectuée avec succès','success');
            }
            setCommande({...initData})
            history.push("/liste-commandes")
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }  
        appContext.setOpenLoading(false);  
    }

    const colonnes = [
        { title: 'N°', field: 'index', editable:'never',
            read_only:true,width:'0.5%',
            cellStyle:{witheSpace:'nowrap'},
            // headerStyle:{width:300,maxWidth:300},
            // initialEditValue:commande.details.length+1,
            render: rowData => rowData ? rowData.tableData.id+1 : commande.details.length+1
        },
        { title: 'Article', field: 'article',
            editComponent: props => (
                <MySelectField 
                    name="article"
                    value={props.value && props.value.id}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    options={articles}
                    libelle="nom"
                    label=" "
                />
            ),
            render:rowData=>rowData['article'] ? rowData['article'].nom : ""
        },
        { title: 'Quantite', field: 'quantite',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    inputProps={{
                        min:"0"
                    }}
                    name="quantite"
                    value={props.value||0}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    type="number"
                    label=" "
                />
            )
        },
        { title: 'Unite', field: 'unite_stockage.libelle',editable:'never' },
        /*{ title: 'Unite', field: 'unite_stockage',
            editComponent: props => (
                <MySelectField 
                    name="unite_stockage"
                    value={props.value && props.value.id}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    options={unites}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['unite_stockage'] ? rowData['unite_stockage'].libelle : ""
        },*/
        { title: 'Prix', field: 'prix',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    value={props.rowData.prix}
                    inputProps={{
                        min:"0"
                    }}
                    name="prix"
                    onChange={e=>handleChangeDetail(e,props)}
                        errors={props.rowData.errors||[]}
                        type="number"
                        label=" "
                    />
            )
        },
        { title: 'Montant', field: 'montant',editable:'never',
            read_only:true,initialEditValue:"0",
            // render:rowData=>rowData.montant && util.separteurMillier(rowData.montant)
        },
    ];

    const rowAdd =  (newData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.detail);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    const a = {...commande}
                    a.details.push({...newData,errors:{}});
                    setCommande(a)
                    resolve();
                }      
            } catch (error) {
                reject()
            }
        })
    }
    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.detail);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    const a = {...commande}
                    a.details = a.details.map(d=>d.tableData.id===oldData.tableData.id?newData:d);
                    setCommande(a)
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }
    const rowDelete = (oldData)=>{
        return new Promise((resolve,reject)=>{
            const ds = [...commande.details];
            let details = ds.filter(d=>d.tableData.id!==oldData.tableData.id)
            details = details.map((ds,i)=>({...ds,index:i+1}));
            setCommande({...commande,details})
            resolve()
        })
    }
    const editable = ()=>!view ? ({
        onRowUpdate:rowUpdate,
        onRowAdd:rowAdd,
        onRowDelete:rowDelete
    }):{}
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? view ? "Consultation": "Modification" : "Saisie"} Commande
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                        <MyDateField 
                            name="date_commande"
                            value={commande.date_commande}
                            onChange={handleChange}
                            errors={commande.errors}
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <MySelectField 
                            name="fournisseur"
                            value={commande.fournisseur}
                            onChange={handleChange}
                            errors={commande.errors}
                            options={fournisseurs}
                            libelle="nom"
                            disabled={view}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <MaterialTable
                            title={`Liste des articles`}
                            icons={IconMaterialTable}
                            columns={colonnes}
                            data={commande.details}
                            tableRef={refListe}
                            editable={editable()}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize:5,
                                pageSizeOptions:[5,10,15],
                                // debounceInterval:1000,
                                filtering:false,
                                search:false
                            }}
                        />
                    </Grid>
                </Grid>
                {!view && 
                    <Grid container direction="row-reverse" spacing={3} style={{margin:5}} >
                        <Grid item xs={6} sm={4} lg={3}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={enregistrer}
                            >
                                {id ? 'Modifier' : 'Enregistrer'}
                            </Button>
                        </Grid>
                    </Grid>

                }
            </div>
        </Container>
    )
};