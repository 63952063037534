import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';
import util from '../../utils/util';

import Liste from '../Codification/Liste';

export default function ListeNumeroCompte(){
    const [numeroComptes,setNumeroComptes] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Banque', field: 'banque.libelle' },
        { title: 'Numero compte', field: 'numero' },
        { title: 'Description', field: 'description' },
        { title: 'Solde', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.solde) 
        },
    ];
    return (
            <Liste table='numero-compte' 
                values={numeroComptes} 
                setValues={setNumeroComptes}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouveau numeroCompte',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/numero-comptes")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher numeroCompte',
                        onClick: (event, rowData) => history.push(`/numero-comptes/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier numeroCompte',
                        onClick: (event, rowData) => history.push(`/numero-comptes/${rowData.id}`)
                    },
                ]}

            />
    )
};

