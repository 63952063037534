import moment from 'moment';
export default {
    add:{
        date_mouvement:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            datetime: {
                dateOnly: true,
                latest:moment().format('YYYY-MM-DD'),
                message :"date incorrect"
                // latest: moment.utc().subtract(18, 'years'),
                // message: "^You need to be at least 18 years old"
            }
        },
        article:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        unite:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },       
        description:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        quantite:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:0,
                message:" doit être superieur à 0"
            },
        },   
    }
}