import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';

import omit from 'lodash/omit';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';
import IconMaterialTable from '../../IconMaterialTable';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Ramassage';


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    // date_ramassage:moment().format('YYYY-MM-DD'),
    date_ramassage:"",
    details:[],
    errors:{}
};
export default function RamassageJournalier(){
    const classes = useStyles();
    const history = useHistory();
    const refListe = React.useRef(null);

    const appContext = React.useContext(AppContext);
    const [ramassage,setRamassage] = React.useState({...initData})
    // const [editable,setEditable] = React.useState(false)

    const fetchData = async ()=>{
        appContext.setOpenLoading(true); 
        try {
            const [hangars] = await Promise.all([
                axios.get(`/hangars/actifs`),
            ]);
            setRamassage({...ramassage,details:hangars.data.results.map(h=>({hangar_bande:{id:h.id,libelle:`${h.hangar.libelle}-${h.bande.nom}`},nbre_oeufs:0,nbre_alveoles:0}))})            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false); 
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e,props)=>{
        let data = {...props.rowData};
        data = {
            ...data,
            [props.columnDef.field]:e.target.value,
            nbre_oeufs:util.get_nbre_oeufs(e.target.value),
            errors:{}
        }
        props.onChange(e.target.value);
        props.onRowDataChange(data)
    }
    const colonnes = [
        { title: 'Hangar', field: 'hangar_bande.libelle', editable:'never'
        },
        { title: 'Nbre Alveoles', field: 'nbre_alveoles',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    inputProps={{
                        min:"0"
                    }}
                    name="nbre_alveoles"
                    value={props.value||0}
                    onChange={e=>handleChange(e,props)}
                    errors={props.rowData.errors||[]}
                    type="number"
                    label=" "
                />
            )
        },
        { title: 'Nbre Oeufs', field: 'nbre_oeufs', editable:'never'},
    ];
    const enregistrer = async (nouveau)=>{
        const errors = validate(ramassage,constraint.journalier.maitre);
        if(errors){
            setRamassage({...ramassage,errors});
            return;
        }
        try {
            const details = ramassage.details.filter(d=>d.nbre_oeufs!==0).map(d=>({hangar_bande:d.hangar_bande.id,nbre_oeufs:d.nbre_oeufs}));
            if(details.length===0){
                appContext.addMessage('Aucun ramassage saisie','error');
                return;
            }
            appContext.setOpenLoading(true); 
            await axios.post(`/ramassages/multiple`,{...omit(ramassage,['errors']),details});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false); 
    }

    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            const errors = validate(newData,constraint.journalier.detail);
            if(errors){
                newData['errors'] = errors;
                reject()
            }else{
                setRamassage({...ramassage,details:ramassage.details.map(h=>h.hangar_bande.id===newData.hangar_bande.id?newData:h)});
                resolve();
            }    
        });
    }    

    const rowDelete = (oldData)=>{
        return new Promise((resolve,reject)=>{
            setRamassage({...ramassage,details:ramassage.details.filter(h=>h.hangar_bande.id!==oldData.hangar_bande.id)});
            resolve()
        })
    }
    
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
                <Typography variant="h5" gutterBottom>
                    Saisie ramassage journalier
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <MyDateField 
                            name="date_ramassage"
                            value={ramassage.date_ramassage}
                            onChange={e=>setRamassage({...ramassage,date_ramassage:e.target.value})}
                            errors={ramassage.errors}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                        <MaterialTable
                            title={`Liste des hangars`}
                            icons={IconMaterialTable}
                            columns={colonnes}
                            data={ramassage.details}
                            tableRef={refListe}
                            editable={{
                                onRowUpdate:rowUpdate,
                                onRowDelete:rowDelete
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                paging:false,
                                // debounceInterval:1000,
                                filtering:false,
                                search:false
                            }}
                        />
                    </Grid>
                    <Grid container direction="row-reverse" spacing={3} style={{margin:5}} >
                        <Grid item xs={6} sm={4} lg={3}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={enregistrer}
                            >
                                Enregistrer
                            </Button>
                        </Grid>
                    </Grid>
                    
                </Grid>          
        </Container>
    )
};