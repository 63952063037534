import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper  from '@material-ui/core/Paper';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Contrat';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    date_debut:"",
    objet:"",
    prestataire:"",
    metier:"",
    errors:{}
};
export default function Contrat(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [contrat,setContrat] = React.useState({...initData})
    const [prestataires,setPrestataires] = React.useState([])
    const [metiers,setMetiers] = React.useState([])
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [prestataires,metiers] = await Promise.all([
                axios.get(`/prestataires`),
                axios.get(`/metiers`),
            ]);
            if(id){
                if(pathname===`/contrats/view/${id}`){
                    setView(true)
                }
                const {data} = await axios.get(`/contrats/${id}`);
                const contrat = {
                        id: data.id,
                        date_debut:data.date_debut,
                        date_fin:data.date_fin,
                        prestataire:data.prestataire.id,
                        metier:data.metier.id,
                        montant:data.montant,
                        objet: data.objet,
                        errors:{}
                    }
                setContrat(contrat)
            }
            setPrestataires(prestataires.data.results)            
            setMetiers(metiers.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e,value)=>{
        setContrat({...contrat,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(contrat,constraint.add);
        if(errors){
            setContrat({...contrat,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/contrats`,{...omit(contrat,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setContrat({...initData})
            }else{
                history.push("/liste-contrats")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(contrat,constraint.add);
        if(errors){
            setContrat({...contrat,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/contrats/${contrat.id}`,{...omit(contrat,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Contrat
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_debut"
                            value={contrat.date_debut||""}
                            onChange={handleChange}
                            errors={contrat.errors}
                            label="Date debut"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_fin"
                            value={contrat.date_fin||""}
                            onChange={handleChange}
                            errors={contrat.errors}
                            label="Date fin"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MySelectField 
                            name="prestataire"
                            value={contrat.prestataire}
                            onChange={handleChange}
                            errors={contrat.errors}
                            options={prestataires}
                            libelle="full_name"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MySelectField 
                            name="metier"
                            value={contrat.metier}
                            onChange={handleChange}
                            errors={contrat.errors}
                            options={metiers}
                            libelle="libelle"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0"
                            }}
                            name="objet"
                            value={contrat.objet}
                            onChange={handleChange}
                            errors={contrat.errors}
                            label="Objet du contrat"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(contrat.montant):contrat.montant}
                            onChange={handleChange}
                            errors={contrat.errors}
                            label="Montant du contrat"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};