import moment from 'moment';
export default {
    add:{
        description:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        date_operation:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            datetime: {
                dateOnly: true,
                latest:moment().format('YYYY-MM-DD'),
                message :"date incorrect"
            }
        },
        montant:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:0,
                message:" doit être superieur à 0"
            },
        },        
        type_operation:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        numero_compte:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
    
    }
}