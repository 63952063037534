import React from 'react';
import {useParams } from 'react-router-dom';
import Liste from '../Codification/Liste';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
// import find from 'lodash/find';
import util from '../../utils/util';
// import validate from '../../utils/validate';
// import MySelectField from '../Codification/MySelectField';
// import find from 'lodash/find';
// import MyTextField from '../Codification/MyTextField';


export default function ListeResponsablesParEleve(){
    const appContext = React.useContext(AppContext);
    // const [responsabilites,setResponsabilites] = React.useState([]);
    const [eleve,setEleve] = React.useState([])
    // const [liens,setLiens] = React.useState([])
    // const history = useHistory();
    const {id} = useParams();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [/*responsabilites,*/eleve] = await Promise.all([
                // axios.get(`/lien-responsables?eleve.id=${id}`),
                axios.get(`/eleves/${id}`),
            ]);
            // setResponsabilites(responsabilites.data.results)  
            setEleve(eleve.data)
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);

    const rowDelete = (oldData)=>{
        return new Promise( async (resolve,reject)=>{
            try {
                await axios.put(`responsabilites/${oldData.id}/disable`);
                appContext.addMessage('Suppression effectuée avec succès','success');
                resolve();  
            } catch (error) {
                reject()
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');    
            }
        })
    }


    const colonnes = [
        { title: 'Lien', field: 'lien.libelle',editable:'never'},
        { title: 'Nom', field: 'responsable.nom',editable:'never'},
        { title: 'Prénom', field: 'responsable.prenom',editable:'never' },
        { title: 'Tel', field: 'responsable.telephone',editable:'never',},
    ];
    return (
            <Liste  
                // table='eleve'
                // urlBase={`responsabilites`}
                urlBaseGET={`responsabilites?eleve.id=${id}`}
                title={`Liste des responsables de : ${eleve.matricule} ${eleve.full_name}`}
                values={[]} 
                // setValues={()=>null}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null,
                    onRowDelete:rowDelete
                }}

                // maxWidth={"md"}
                // transformDataBeforeSent={data=>({...data,responsable:id})}
            />
    )
};

