import axios from 'axios';
import parameters from '../config/parameters';
import omit from 'lodash/omit';
const instance = axios.create({
    baseURL: parameters.API_URL,
    // timeout: 3000,
    headers : {'Authorization':' Bearer '+localStorage.getItem('token')}
});

instance.interceptors.request.use(config=>{
    let app = localStorage.getItem('app');
    if(app){
        app = JSON.parse(app);
        config.params = {...config.params,app_id:app.id}
    }
    let projet = localStorage.getItem('projet');
    if(projet){
        projet = JSON.parse(projet);
        if(projet.key) config.params = {...config.params,projet_id:projet.key}
    }
    if(config.params && config.params.filters){
        const filters = {} 
        config.params.filters.forEach(f=>{
            filters[f.column.field] = f.value;
        });
        config.params = {...omit(config.params,['filters']),...filters};

    } 
    return config 
})

export default instance;