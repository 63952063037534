import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import omit from 'lodash/omit';
import { useHistory,useParams } from 'react-router-dom';
// import moment from 'moment';
import find from 'lodash/find';
// import axios from 'axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Employe';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';
import IconMaterialTable from '../../IconMaterialTable';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      },
}));
// const initDataDetail = {
//     article:"",
//     errors:[]
// }

export default function RubriqueEmployer(){
    const initData = {
        rubriques:[],
        errors:{}
    };
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [employe,setEmploye] = React.useState({...initData})
    const [rubriques,setRubriques] = React.useState([])
    const [dirty,setDirty] = React.useState(false)
    const refListe = React.useRef(null);

    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [rubriques,employe] = await Promise.all([
                axios.get(`/rubriques`),
                axios.get(`/employes/${id}`)
            ]);
            setRubriques(rubriques.data.results);
            setEmploye({...employe.data})         
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
            history.goBack()
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChangeRubrique = (e,valeur,props)=>{
        const listes = {
            rubrique:rubriques,
        }
        if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
            if(valeur===""){
                props.onChange(valeur)
            }else{
                const values = listes[props.columnDef.field];
                const value = find(values,v=>v.id===parseInt(valeur));
                if(value){
                    props.onChange(value)
                }
            }
        }else{
            props.onChange(e.target.value);
        }
    }

    const enregistrer = async ()=>{
        if(employe.rubriques.length===0){
            appContext.addMessage("Aucune rubriques",'  error');
            return
        }
        appContext.setOpenLoading(true);
        try {
            const e = {...omit(employe,['errors'])};
            e.rubriques = e.rubriques.map(e=>({
                ...omit(e),
                rubrique:e.rubrique.id,
                employe:id
            }));
            await axios.post(`/employes/${id}/rubriques`,e.rubriques);
            appContext.addMessage('Enregistrement effectué avec succès','success');
            setDirty(false)
            // history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }  
        appContext.setOpenLoading(false);  
    }

    const colonnes = [
        { title: 'Rubrique', field: 'rubrique',
            editComponent: props => (
                <MySelectField 
                    name="rubrique"
                    value={props.value && props.value.id}
                    onChange={(e,v)=>handleChangeRubrique(e,v,props)}
                    errors={props.rowData.errors||[]}
                    options={rubriques}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['rubrique'] ? rowData['rubrique'].libelle : ""
        },
        { title: 'Debut', field: 'date_debut',
            editComponent: props => (
                <MyDateField 
                    name="date_debut"
                    value={props.value}
                    onChange={(e,v)=>handleChangeRubrique(e,v,props)}
                    errors={props.rowData.errors||[]}
                    label=" "
                />
            )
        },
        { title: 'Fin', field: 'date_fin',
            editComponent: props => (
                <MyDateField 
                    name="date_fin"
                    value={props.value}
                    onChange={(e,v)=>handleChangeRubrique(e,v,props)}
                    errors={props.rowData.errors||[]}
                    label=" "
                />
            )
        },
        { title: 'Montant', field: 'montant',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    value={props.rowData.montant}
                    inputProps={{
                        min:"0"
                    }}
                    name="montant"
                    onChange={(e,v)=>handleChangeRubrique(e,v,props)}
                        errors={props.rowData.errors||[]}
                        type="number"
                        label=" "
                    />
            ),
            render:rowData=>rowData.montant && util.separteurMillier(rowData.montant)
        },
    ];

    const rowAdd =  (newData)=>{
        if(!dirty) setDirty(true);
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.rubriques);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    const e = {...employe}
                    e.rubriques.push({...newData,errors:{}});
                    setEmploye(e)
                    resolve();
                }      
            } catch (error) {
                reject()
            }
        })
    }
    const rowUpdate = (newData,oldData)=>{
        if(!dirty) setDirty(true);
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.rubriques);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    const e = {...employe}
                    e.rubriques = e.rubriques.map(d=>d.tableData.id===oldData.tableData.id?newData:d);
                    setEmploye(e)
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }

    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                <Typography variant="h3" gutterBottom>
                    Saisie des rubriques de {employe.full_name}
                </Typography>
                <Grid container direction="row-reverse" spacing={3} style={{margin:5}} >
                    <Grid item xs={6} sm={4} lg={3}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={()=>history.goBack()}
                        >
                            Retour
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={4} lg={3}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={enregistrer}
                            disabled={!dirty}
                        >
                            Enregistrer
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12}>
                        <MaterialTable
                            title={`Liste des rubriques`}
                            icons={IconMaterialTable}
                            columns={colonnes}
                            data={employe.rubriques}
                            tableRef={refListe}
                            editable={{
                                onRowUpdate:rowUpdate,
                                onRowAdd:rowAdd,
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize:5,
                                pageSizeOptions:[5,10,15],
                                // debounceInterval:1000,
                                filtering:false,
                                search:false
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
};