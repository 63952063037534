import React from 'react';
// import { useHistory } from 'react-router-dom';
import Liste from '../Codification/Liste';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
import MySelectField from '../Codification/MySelectField';
import find from 'lodash/find';
import util from '../../utils/util';
export default function ListeArticle(){
    const appContext = React.useContext(AppContext);
    const [articles,setArticles] = React.useState([]);
    const [unites,setUnites] = React.useState([])
    // const history = useHistory();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [unites] = await Promise.all([
                axios.get(`/unites`),
            ]);
            setUnites(unites.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e,props)=>{
        let data = {...props.rowData};
        const listes = {
            unite:unites,
        }
        if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
            const values = listes[props.columnDef.field];
            const value = find(values,v=>v.id===parseInt(e.target.value));
            if(value){
                data = {...data,
                    [props.columnDef.field]:value,
                }
                props.onChange(value)
            }
        }else{
            data = {...data,[props.columnDef.field]:e.target.value}
            props.onChange(e.target.value);
        }
        props.onRowDataChange(data)
        // setProduction({...production,[e.target.name]:e.target.value})
    }
    const colonnes = [
        { title: 'Nom', field: 'nom' },
        // { title: 'Nom', field: 'unite.libelle',editable:'never' },
        { title: 'Unite principale', field: 'unite',
            editComponent: props => (
                <MySelectField 
                    name="unite"
                    value={props.value && props.value.id}
                    onChange={e=>handleChange(e,props)}
                    errors={props.rowData.errors||[]}
                    options={unites}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['unite'] ? rowData['unite'].libelle : ""
        },
        { title: 'Prix', field: 'prix',
        render:rowdata=>util.separteurMillier(rowdata.prix)
        },

    ];
    return (
            <Liste 
                table='article' 
                values={articles} 
                setValues={setArticles}
                colonnes={colonnes}
                maxWidth={"md"}
                // actions={[                
                //     {
                //         icon: 'view_headline',
                //         iconProps:{color:'primary'},
                //         tooltip: 'Gestion des unités',
                //         onClick: (event, rowData) => history.push(`/liste-article-unites/${rowData.id}`)
                //     },
                // ]}
                transformDataBeforeSent={data=>({...data,unite:data.unite ? data.unite.id: null})}
            />
    )
};

