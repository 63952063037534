import React from 'react';
import { useParams,useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import Liste from './Liste';
import util from 'utils/util';
const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      },
}));

export default function ListeBulletinsParMois(){
    const classes = useStyles();
    const history = useHistory();
    const {annee,mois} = useParams()


    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                <Typography variant="h3" gutterBottom>
                    Bulletins {util.get_libelle_mois(mois)+' '+ annee}
                </Typography>
                <Grid container direction="row-reverse" spacing={3} style={{margin:5}} >
                    <Grid item xs={6} sm={4} lg={3}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={()=>history.goBack()}
                        >
                            Retour
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Liste 
                        url={`bulletins?annee=${annee}&mois=${mois}`}
                    />
                </Grid>
        </div>

        </Container>
    )
};

