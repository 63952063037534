import React from 'react';
// import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
// import CssBaseline from '@material-ui/core/CssBaseline';

// import moment from 'moment';
import axios from '../../utils/axios';
import { AppContext } from '../../App';
import MaterialTable from 'material-table';


// import validate from '../../validate';
import util from '../../utils/util';
import IconMaterialTable from '../../IconMaterialTable';
const useStyles = makeStyles(theme => ({
    root: {
        // width: '80%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        overflowX: 'auto' ,
    },
    paper:{
        width:'100%'
    },
    title : {
        marginTop: theme.spacing(2)
    },
    TableHeader:{
        backgroundColor: theme.palette.primary
    },
    fab: {
        margin: theme.spacing(1),
    },
    button:{
        margin: theme.spacing(2)
    }
}))

export default function Liste({table,values,setValues,colonnes,...rest}){
    const classes = useStyles();
   
    const appContext = React.useContext(AppContext);
    const refListe = React.useRef(null);
    let urlBase = `/${table}s`;
    let urlBaseGET = `/${table}s`;
    if(rest.urlBaseGET) urlBaseGET=rest.urlBaseGET
    if(rest.url) urlBaseGET=rest.url
    if(rest.urlBase) {
        urlBase=rest.urlBase
        urlBaseGET=rest.urlBase
    }
    // if(rest.urlBase) urlBase=rest.urlBaseGET

    const fetchData = async (query)=>{
        // appContext.setOpenLoading(true);
        try {
            const [values] = await Promise.all([
                axios.get(urlBaseGET,{params:{...query,page:query.page+1}}),
            ]);
            
            // setValues(values.data.results)
            return {
                data:values.data.results,
                page:query.page,
                totalCount:values.data.count
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        // appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        
        if(rest.reload && refListe) refListe.current.onQueryChange() 
        // eslint-disable-next-line
    },[rest.reload])

    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                let data = {...newData};
                if(rest.transformDataBeforeSent) data = rest.transformDataBeforeSent(data) 

                const res = await axios.put(`${urlBase}/${data.id}`, {...data});
                const value = res.data;
                // eslint-disable-next-line
                const rs = values.map(r=>r[`code_${table}`]==value[`code_${table}`]?value:r);
                setValues(rs);
                appContext.addMessage('Modification effectuée avec succès','success');
                resolve();
                // fetchData();
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }

    const rowAdd =  (newData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                let data = {...newData};
                if(rest.transformDataBeforeSent) data = rest.transformDataBeforeSent(data) 
                const res = await axios.post(`${urlBase}`,{...data});
                const value = res.data;
                setValues([...values,value]);
                appContext.addMessage('Ajout effectuée avec succès','success');
                resolve();
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        })
    }

    return (
        <Container component="main" maxWidth={rest.maxWidth||"lg"} className={classes.root}>
            <CssBaseline />
            <div className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        {values && 
                            <MaterialTable
                                title={rest.title ? rest.title : `Liste des ${table}s`}
                                icons={IconMaterialTable}
                                columns={colonnes}
                                data={rest.fetchData ? rest.fetchData : fetchData}
                                tableRef={refListe}
                                editable={rest.editable || {
                                    onRowUpdate:rowUpdate,
                                    onRowAdd:rowAdd
                                }}
                                options={rest.options ? {...rest.options} :{
                                    actionsColumnIndex: -1,
                                    addRowPosition:'first',
                                    pageSize:10,
                                    pageSizeOptions:[10,20,30,40,50,70,100],
                                    debounceInterval:1000,
                                    filtering:true,
                                    search:false
                                }}
                                {...rest}
                            />
                        }
                    </Grid>
                </Grid>
            </div>
        </Container>
    )
};

