import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper  from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import omit from 'lodash/omit';
import { useHistory,useParams } from 'react-router-dom';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Ramassage';
import MySelectField from '../Codification/MySelectField';



const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    // date_ramassage:moment().format('YYYY-MM-DD'),
    date_ramassage:"",
    hangar_bande:"",
    errors:{}
};
export default function Ramassage(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [ramassage,setRamassage] = React.useState({...initData})
    const [hangars,setHangars] = React.useState([])
    const [detailAlveaole,setDetailAlveole] = React.useState('0 Alveole')
    // const [editable,setEditable] = React.useState(false)
    React.useEffect(()=>{
        setDetailAlveole(util.get_alveoles(ramassage.nbre_oeufs))
    // eslint-disable-next-line
    },[ramassage.nbre_oeufs]);
    const fetchData = async ()=>{
        appContext.setOpenLoading(true); 
        try {
            const [hangars] = await Promise.all([
                axios.get(`/hangars/actifs`),
            ]);
            if(id){
                const {data} = await axios.get(`/ramassages/${id}`);
                const ramassage = {
                        id: data.id,
                        date_ramassage : data.date_ramassage,
                        hangar_bande:data.hangar_bande.id,
                        nbre_oeufs:data.nbre_oeufs,
                        errors:{}
                    }
                setRamassage(ramassage)
            }
            setHangars(hangars.data.results.map(h=>({id:h.id,libelle:`${h.hangar.libelle}-${h.bande.nom}`})))            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false); 
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setRamassage({...ramassage,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(ramassage,constraint.add);
        console.log(errors)
        if(errors){
            setRamassage({...ramassage,errors});
            return;
        }
        appContext.setOpenLoading(true); 
        try {
            await axios.post(`/ramassages`,{...omit(ramassage,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setRamassage({...initData,date_ramassage:ramassage.date_ramassage})
            }else{
                history.push("/liste-ramassages")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false); 
    }
    const modifier = async ()=>{
        const errors = validate(ramassage,constraint.add);
        if(errors){
            setRamassage({...ramassage,errors});
            return;
        }
        appContext.setOpenLoading(true); 
        try {
            await axios.put(`/ramassages/${ramassage.id}`,{...omit(ramassage,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
                history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        } 
        appContext.setOpenLoading(false);    
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Ramassage
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth error={ramassage.errors['date_ramassage']?true:false}>
                            <TextField
                                required
                                id="date"
                                name="date_ramassage"
                                label="Date Ramassage"
                                type="date"
                                value={ramassage.date_ramassage}
                                // defaultValue={ramassage.date_ramassage}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChange}
                            />
                            <FormHelperText>{ramassage.errors ? util.getMessageConstraintError(ramassage.errors['date_ramassage']):''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MySelectField 
                            name="hangar_bande"
                            value={ramassage.hangar_bande}
                            onChange={handleChange}
                            errors={ramassage.errors}
                            options={hangars}
                            libelle="libelle"
                            label="Hangar"
                            // disabled={view}
                        />
                        {/* <FormControl fullWidth error={ramassage.errors['hangar']?true:false}>
                            <InputLabel shrink htmlFor="age-native-label-placeholder">
                                Hangar
                            </InputLabel>
                            <NativeSelect
                                value={ramassage.hangar}
                                onChange={handleChange}
                                name="hangar"
                                inputProps={{
                                    name: 'hangar',
                                    id: 'hangar-native-label-placeholder',
                                }}
                            >
                                <option value=""></option>
                                {
                                    hangars && hangars.map(h=>(
                                        <option key={h.id} value={h.id}>{h.libelle}</option>
                                    ))

                                }
                            </NativeSelect>
                            <FormHelperText>{ramassage.errors ? util.getMessageConstraintError(ramassage.errors['hangar']):''}</FormHelperText>
                        </FormControl>                 */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <TextField
                                name="nbre_oeufs"
                                required
                                type="number"
                                fullWidth
                                id="firstName"
                                label="Nombre d'oeufs"
                                value={ramassage.nbre_oeufs||""}
                                onChange={handleChange}
                                error={ramassage.errors['nbre_oeufs']?true:false}
                                helperText={ramassage.errors ? util.getMessageConstraintError(ramassage.errors['nbre_oeufs']):''}    
                            />
                            <FormHelperText>{detailAlveaole}</FormHelperText>
                        </FormControl>
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={modifier}
                            >
                                Modifier
                            </Button>
                        </Grid>
                        :
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};