import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Subvention';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }, 
}))
const initData = {
    date_subvention:"",
    source_subvention:"",
    errors:{}
};
export default function Subvention(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [subvention,setSubvention] = React.useState({...initData})
    const [sources,setSources] = React.useState([])
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [sources] = await Promise.all([
                axios.get(`/sources_subventions`),
            ]);
            if(id){
                const {data} = await axios.get(`/subventions/${id}`);
                let subvention = {
                        id: data.id,
                        date_subvention:data.date_subvention,
                        source_subvention:data.source_subvention.id,
                        montant:data.montant,
                        description: data.description,
                        errors:{}
                    }
                if(pathname===`/subventions/view/${id}`){
                    setView(true);
                    subvention = {
                        ...subvention,
                        source_subvention:data.source_subvention
                    }
                }
                setSubvention(subvention)
            }
            setSources(sources.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setSubvention({...subvention,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(subvention,constraint.add);
        if(errors){
            setSubvention({...subvention,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/subventions`,{...omit(subvention,['errors','prestataire'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setSubvention({...initData})
            }else{
                history.push("/liste-subventions")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(subvention,constraint.add);
        if(errors){
            setSubvention({...subvention,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/subventions/${subvention.id}`,{...omit(subvention,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Subvention
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MyDateField 
                            name="date_subvention"
                            value={subvention.date_subvention||""}
                            onChange={handleChange}
                            errors={subvention.errors}
                            label="Date subvention"
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="source_subvention"
                                value={subvention.source_subvention && subvention.source_subvention.nom}
                                label="Source"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="source_subvention"
                                value={subvention.source_subvention}
                                onChange={handleChange}
                                errors={subvention.errors}
                                options={sources}
                                libelle="nom"
                                disabled={view}
                                label="Source subvention"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                            }}
                            name="description"
                            value={subvention.description}
                            onChange={handleChange}
                            errors={subvention.errors}
                            label="Description de la subvention"
                            multiline
                            rows="4"
                            variant="outlined"
                            disabled={view}
                            required={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(subvention.montant):subvention.montant}
                            onChange={handleChange}
                            errors={subvention.errors}
                            label="Montant du subvention"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};