import moment from 'moment';
export default {
    add:{
        date_subvention:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            datetime: {
                dateOnly: true,
                latest:moment().format('YYYY-MM-DD'),
                message :"date incorrect"
                // latest: moment.utc().subtract(18, 'years'),
                // message: "^You need to be at least 18 years old"
            }
        },
        source_subvention:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        montant:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:0,
                message:" doit être superieur à 0"
            },
        },        
    }
}