import React from 'react';
// import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
// import yellow from '@material-ui/core/colors/yellow';
// import MaterialTable from 'material-table';

import Liste from '../Codification/Liste';
// import util from '../../utils/util';
// import axios from '../../utils/axios';
// import { AppContext } from '../../App';
import MyTextField from '../Codification/MyTextField';

export default function ListePropriete(){
    // const appContext = React.useContext(AppContext);
    const [proprietes,setProprietes] = React.useState([]);
    const history = useHistory();

    // React.useEffect(()=>{
    //     console.log(proprieteSelected)
    const colonnes = [
        { title: 'Code', field: 'code',
            // width:'30%',cellStyle:{witheSpace:'nowrap'},
            editComponent: props => (
                <MyTextField 
                    name="code"
                    value={props.value||""}
                    onChange={e=>props.onChange(e.target.value)}
                    errors={props.rowData.errors||[]}
                    type="text"
                    label=" "
                />
            )
        },
        { title: 'Description', field: 'description',
            width:'50%',cellStyle:{witheSpace:'nowrap'},
            editComponent: props => (
                <MyTextField 
                    name="description"
                    value={props.value||""}
                    onChange={e=>props.onChange(e.target.value)}
                    errors={props.rowData.errors||[]}
                    type="text"
                    label=" "
                />
            )
        },
        { title: 'Adresse', field: 'adresse',
            editComponent: props => (
                <MyTextField 
                    name="adresse"
                    value={props.value||""}
                    onChange={e=>props.onChange(e.target.value)}
                    errors={props.rowData.errors||[]}
                    type="text"
                    label=" "
                />
            )
        },
    ];
    return (
        <React.Fragment>
            <Liste table='propriete' 
                values={proprietes} 
                setValues={setProprietes}
                colonnes={colonnes}
                actions={[
                    rowData=> !rowData.flag_termine && ({
                        icon: 'calendar_view_day',
                        iconProps:{style:{color:green[500]}},
                        tooltip: 'Gestion appartements',
                        onClick: (event, rowData) => history.push(`/proprietes/${rowData.id}/appartements`)
                    }),
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher propriete',
                        onClick: (event, rowData) => history.push(`/proprietes/view/${rowData.id}`)
                    },
                ]}
            />
        </React.Fragment>

    )
};

