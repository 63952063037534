import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';

import util from '../../utils/util';

export default function ListeVehicule(){
    const [vehicules,setVehicules] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_achat', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date_achat"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },        
        { title: 'Numero', field: 'immatriculation' },
        { title: 'Modele', field: 'modele.nom' },
        { title: 'Carburant', field: 'carburant.libelle' },
        { title: 'Prix', field: 'prix_achat',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.prix_achat) 
        },
        { title: 'N° Chassis', field: 'numero_chassis' },
        { title: 'KM achat', field: 'nbre_km_achat',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.nbre_km_achat) 
        },
    ];
    return (
            <Liste table='vehicule' 
                values={vehicules} 
                setValues={setVehicules}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouveau vehicule',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/vehicules")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher vehicule',
                        onClick: (event, rowData) => history.push(`/vehicules/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier vehicule',
                        onClick: (event, rowData) => history.push(`/vehicules/${rowData.id}`)
                    },
                ]}

            />
    )
};

