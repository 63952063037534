import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper  from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import omit from 'lodash/omit';
import { useHistory,useParams } from 'react-router-dom';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';
import MySelectField from '../Codification/MySelectField';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Mortalite';


const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
}))
const initData = {
    // date_mortalite:moment().format('YYYY-MM-DD'),
    date_mortalite:"",
    hangar_bande:"",
    errors:{}
};
export default function Mortalite(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [mortalite,setMortalite] = React.useState({...initData})
    const [hangars,setHangars] = React.useState([])

    const fetchData = async ()=>{
        try {
            const [hangars] = await Promise.all([
                axios.get(`/hangars/actifs`),
            ]);
            if(id){
                const {data} = await axios.get(`/mortalites/${id}`);
                const mortalite = {
                        id: data.id,
                        date_mortalite : data.date_mortalite,
                        hangar_bande:data.hangar_bande.id,
                        nbre:data.nbre,
                        errors:{}
                    }
                setMortalite(mortalite)
            }
            setHangars(hangars.data.results.map(h=>({id:h.id,libelle:`${h.hangar.libelle}-${h.bande.nom}`})))            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setMortalite({...mortalite,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(mortalite,constraint.add);
        if(errors){
            setMortalite({...mortalite,errors});
            return;
        }
        try {
            await axios.post(`/mortalites`,{...omit(mortalite,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setMortalite({...initData,date_mortalite:mortalite.date_mortalite})
            }else{
                history.push("/liste-mortalites")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
    }
    const modifier = async ()=>{
        const errors = validate(mortalite,constraint.add);
        if(errors){
            setMortalite({...mortalite,errors});
            return;
        }
        try {
            await axios.put(`/mortalites/${mortalite.id}`,{...omit(mortalite,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
                history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Mortalite
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth error={mortalite.errors['date_mortalite']?true:false}>
                            <TextField
                                required
                                id="date"
                                name="date_mortalite"
                                label="Date Mortalite"
                                type="date"
                                value={mortalite.date_mortalite}
                                // defaultValue={mortalite.date_mortalite}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={handleChange}
                            />
                            <FormHelperText>{mortalite.errors ? util.getMessageConstraintError(mortalite.errors['date_mortalite']):''}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <MySelectField 
                            name="hangar_bande"
                            value={mortalite.hangar_bande}
                            onChange={handleChange}
                            errors={mortalite.errors}
                            options={hangars}
                            libelle="libelle"
                            label="Hangar"
                            // disabled={view}
                        />           
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                            <TextField
                                name="nbre"
                                required
                                type="number"
                                fullWidth
                                id="firstName"
                                label="Nombre"
                                value={mortalite.nbre||""}
                                onChange={handleChange}
                                error={mortalite.errors['nbre']?true:false}
                                helperText={mortalite.errors ? util.getMessageConstraintError(mortalite.errors['nbre']):''}    
                            />
                        </FormControl>
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={modifier}
                            >
                                Modifier
                            </Button>
                        </Grid>
                        :
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};