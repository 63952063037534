import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Grid,
	Dialog,
} from '@material-ui/core';

import util from '../../utils/util';
import MaterialTable from 'material-table';
const ModalLivraison = ({livraisons=[],open,toggleOpen}) => {
	const history = useHistory();
	const colonnes = [
        { title: 'Date', field: 'date_livraison', type:'date',      
        },
        // { title: 'Receptionnaire', field: 'receptionnaire.full_name'},
        { title: 'Montant', field: 'montant',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            render:rowdata=>util.separteurMillier(rowdata.montant) 
        },
    ];

	return (
		<Fragment>
			<Dialog scroll="body" maxWidth="md" open={open} onClose={toggleOpen}>
				<Grid container spacing={0}>
					<Grid item xs={12} lg={12}>
						<div className="bg-white ">
							<MaterialTable
									title="Liste des livraisons"
									columns={colonnes}
									data={livraisons}
									options={{
										actionsColumnIndex: -1,
										pageSize:5,
										filtering:false,
										search:false
									}}
									actions={[
										{
											icon: 'visibility',
											iconProps:{color:'primary'},
											tooltip: 'Afficher',
											onClick: (event, rowData) => history.push(`/livraisons/view/${rowData.id}`)
										},
									]}
                            />
						</div>
					</Grid>
				</Grid>
			</Dialog>

		</Fragment>
	);
};

export default ModalLivraison;
