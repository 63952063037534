import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import omit from 'lodash/omit';
import { useHistory,useParams,useLocation } from 'react-router-dom';
// import moment from 'moment';
import find from 'lodash/find';
// import axios from 'axios';
import MaterialTable from 'material-table';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/Vente';
import MyTextField from '../Codification/MyTextField';
import MyDateField from '../Codification/MyDateField';
import MySelectField from '../Codification/MySelectField';
import IconMaterialTable from '../../IconMaterialTable';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      },
}));
// const initDataDetail = {
//     article:"",
//     errors:[]
// }

export default function VenteClient(){
    const initData = {
        date_vente:"",
        client:"",
        montant_brut:0,
        montant_net:0,
        montant_remise:0,
        montant_paye:0,
        remise:0,
        details:[],
        errors:{}
    };
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [vente,setVente] = React.useState({...initData})
    const [clients,setClients] = React.useState([])
    const [articles,setArticles] = React.useState([])
    // const [unites,setUnites] = React.useState([])
    const [typeReglements,setTypeReglements] = React.useState([])
    const refListe = React.useRef(null);
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();

    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [clients,articles,/*unites,*/typeReglements] = await Promise.all([
                axios.get(`/clients`),
                axios.get(`/articles`),
                // axios.get(`/unites`),
                axios.get(`/type-reglements`),
            ]);
            if(id){
                if(pathname===`/ventes/view/${id}`){
                    setView(true)
                }
                const {data} = await axios.get(`/ventes/${id}`);
                const vente = {
                        id: data.id,
                        date_vente : data.date_vente,
                        reference : data.reference,
                        client: data.client.id,
                        montant_brut:data.montant_brut,
                        montant_net:data.montant_net,
                        montant_remise:data.montant_remise,
                        montant_paye:data.montant_paye,
                        remise:(data.montant_remise/data.montant_brut)*100,
                        details:data.detail_ventes.map(d=>({
                                                ...d,
                                                montant_format:util.separteurMillier(d.montant),
                                                montant:d.montant
                                            })),
                        errors:{}
                    }
                setVente(vente)
            }
            setClients(clients.data.results)            
            setArticles(articles.data.results)            
            // setUnites(unites.data.results)    
            setTypeReglements(typeReglements.data.results)        
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        let data = {...vente,[e.target.name]:e.target.value}
        if(e.target.name==="remise"){
            let remise = parseInt(e.target.value)
            if(remise>100) remise=100 
            data = {...vente,[e.target.name]:remise}
            data = update_montant(data,0)
        }else if(e.target.name==="montant_paye"){
            data = {...vente,[e.target.name]:parseInt(e.target.value)}
        }
        setVente(data)
    }
    const handleChangeDetail = (e,props)=>{
        let data = {...props.rowData};
        if(props.columnDef.field==='article'){
            const article = find(articles,a=>a.id===parseInt(e.target.value))
            data = {...data,
                [props.columnDef.field]:article,
                prix: article ? article.prix.toString() : "",
                unite_stockage:article.unite ? article.unite : null
            }
            props.onChange(article)
        }/*else if(props.columnDef.field==='unite_stockage'){
            const unite_stockage = find(unites,a=>a.id===parseInt(e.target.value))
            data = {...data,
                [props.columnDef.field]:unite_stockage,
            }
            props.onChange(unite_stockage)
        }*/
        else{
            data = {...data,[props.columnDef.field]:e.target.value}
            props.onChange(e.target.value);
        }
        data = {
            ...data,
            montant:(parseInt(data.quantite||0)*parseInt(data.prix||0)),
            montant_format:(parseInt(data.quantite||0)*parseInt(data.prix||0)).toLocaleString('fr-FR'),
            errors:{}
        }
        props.onRowDataChange(data)
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(vente,constraint.add);
        if(errors){
            setVente({...vente,errors});
            return;
        }else if(vente.details.length===0){
            appContext.addMessage("Aucun article",'error');
            return;
        }else if(!id && vente.montant_paye>0 && !vente.type_reglement){
            appContext.addMessage("Le type de reglement est obligatoire",'error');
            return;
        }else if(vente.montant_paye>vente.montant_net){
            appContext.addMessage("Le montant payé ne peut être superieur au net",'error');
            return;
        }
        appContext.setOpenLoading(true);
        try {
            const a = {...omit(vente,['errors','remise'])};
            if(!id){
                a.detail_ventes = a.details.map(d=>({
                    id:d.id,
                    article:d.article.id,
                    unite_stockage:d.article.unite ? d.article.unite.id : null,
                    quantite:d.quantite,
                    prix:d.prix
                }));
                await axios.post(`/ventes`,a);
                appContext.addMessage('Enregistrement effectué avec succès','success');
            }else{
                a.detail_ventes = a.details.map(d=>({
                    id:d.id,
                    article:d.article.id,
                    unite_stockage:d.unite_stockage ? d.unite_stockage.id : null,
                    quantite:d.quantite,
                    prix:d.prix
                }));
                await axios.put(`/ventes/${a.id}`,a);
                appContext.addMessage('Modification effectuée avec succès','success');
            }
            setVente({...initData})
            history.push("/liste-ventes")
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }  
        appContext.setOpenLoading(false);  
    }

    const colonnes = [
        { title: 'N°', field: 'index', editable:'never',
            read_only:true,width:'0.5%',
            cellStyle:{witheSpace:'nowrap'},
            // headerStyle:{width:300,maxWidth:300},
            // initialEditValue:vente.details.length+1,
            render: rowData => rowData ? rowData.tableData.id+1 : vente.details.length+1
        },
        { title: 'Article', field: 'article',
            editComponent: props => (
                <MySelectField 
                    name="article"
                    value={props.value && props.value.id}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    options={articles}
                    libelle="nom"
                    label=" "
                />
            ),
            render:rowData=>rowData['article'] ? rowData['article'].nom : ""
        },
        { title: 'Quantite', field: 'quantite',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    inputProps={{
                        min:"0"
                    }}
                    name="quantite"
                    value={props.value||0}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    type="number"
                    label=" "
                />
            )
        },
        { title: 'Unite', field: 'unite_stockage.libelle',editable:'never' },
        /*
        { title: 'Unite', field: 'unite_stockage',
            editComponent: props => (
                <MySelectField 
                    name="unite_stockage"
                    value={props.value && props.value.id}
                    onChange={e=>handleChangeDetail(e,props)}
                    errors={props.rowData.errors||[]}
                    options={unites}
                    libelle="libelle"
                    label=" "
                />
            ),
            render:rowData=>rowData['unite_stockage'] ? rowData['unite_stockage'].libelle : ""
        },*/
        { title: 'Prix', field: 'prix',initialEditValue:"0",
            editComponent: props => (
                <MyTextField 
                    value={props.rowData.prix}
                    inputProps={{
                        min:"0"
                    }}
                    name="prix"
                    onChange={e=>handleChangeDetail(e,props)}
                        errors={props.rowData.errors||[]}
                        type="number"
                        label=" "
                    />
            )
        },
        { title: 'Montant', field: 'montant_format',editable:'never',
            read_only:true,initialEditValue:"0",
            // render:rowData=>rowData.montant && util.separteurMillier(rowData.montant)
        },
    ];

    const rowAdd =  (newData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.detail);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    let v = {...vente}
                    v.details.push({...newData,errors:{}});
                    v = update_montant(v,newData.montant)
                    setVente(v)
                    resolve();
                }      
            } catch (error) {
                reject()
            }
        })
    }
    const rowUpdate = (newData,oldData)=>{
        return new Promise(async (resolve,reject)=>{
            try {
                const errors = validate(newData,constraint.detail);
                if(errors){
                    newData['errors'] = errors;
                    reject()
                }else{
                    const ecart = newData.montant - oldData.montant;
                    const v = {...vente}
                    v.details = v.details.map(d=>d.tableData.id===oldData.tableData.id?newData:d);
                    setVente(update_montant(v,ecart))
                    resolve();
                }      
            } catch (error) {
                console.log(error,error.response);
                appContext.addMessage(util.getContentError(error),'error');
                reject();
            }
        });
    }
    const rowDelete = (oldData)=>{
        return new Promise((resolve,reject)=>{
            const ds = [...vente.details];
            let details = ds.filter(d=>d.tableData.id!==oldData.tableData.id)
            details = details.map((ds,i)=>({...ds,index:i+1}));
            const v = {...vente,details}
            setVente(update_montant(v,-1*oldData.montant))
            resolve()
        })
    }
    const editable = ()=>!view ? ({
        onRowUpdate:rowUpdate,
        onRowAdd:rowAdd,
        onRowDelete:rowDelete
    }):{}
    const update_montant = (v,montant)=>{
        const brut = v.montant_brut+montant;
        const remise = brut*(v.remise/100)
        const data = {...v,
            montant_brut:brut,
            montant_remise:remise,
            montant_net:brut-remise
        };
        return data;
    }
    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? view ? "Consultation": "Modification" : "Saisie"} Vente
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={4}>
                        <MyTextField 
                            name="reference"
                            type={"text"}
                            value={vente.reference}
                            onChange={handleChange}
                            errors={vente.errors}
                            label="Reference"
                            disabled={true}
                        />
                    </Grid>    
                    <Grid item xs={6} sm={4}>
                        <MyDateField 
                            name="date_vente"
                            label="Date"
                            value={vente.date_vente}
                            onChange={handleChange}
                            errors={vente.errors}
                            disabled={view}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <MySelectField 
                            name="client"
                            value={vente.client}
                            onChange={handleChange}
                            errors={vente.errors}
                            options={clients}
                            libelle="full_name"
                            disabled={view}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12}>
                        <MaterialTable
                            title={`Liste des articles vendus`}
                            icons={IconMaterialTable}
                            columns={colonnes}
                            data={vente.details}
                            tableRef={refListe}
                            editable={editable()}
                            options={{
                                actionsColumnIndex: -1,
                                pageSize:5,
                                pageSizeOptions:[5,10,15],
                                // debounceInterval:1000,
                                filtering:false,
                                search:false
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                max:"100",
                                style:{textAlign:'right'}
                            }}
                            name="remise"
                            type={view?"text":"number"}
                            value={view ? util.separteurMillier(vente.remise):vente.remise}
                            onChange={handleChange}
                            errors={vente.errors}
                            label="Remise(%)"
                            disabled={view}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant_brut"
                            type={"text"}
                            value={util.separteurMillier(vente.montant_brut)}
                            onChange={handleChange}
                            errors={vente.errors}
                            label="Montant total"
                            disabled={true}
                        />
                    </Grid>    
                    <Grid item xs={6} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant_remise"
                            type={"text"}
                            value={util.separteurMillier(vente.montant_remise)}
                            onChange={handleChange}
                            errors={vente.errors}
                            label="Montant remise"
                            disabled={true}
                            // style={{}}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <MyTextField 
                            inputProps={{
                                min:"0",
                                style:{textAlign:'right'}
                            }}
                            name="montant_net"
                            type={"text"}
                            value={util.separteurMillier(vente.montant_net)}
                            onChange={handleChange}
                            errors={vente.errors}
                            label="Montant Net"
                            disabled={true}
                        />
                    </Grid> 
                    {!id && // pas de modification du montant payé sauf dans les règlements
                        <Grid item xs={6} sm={3}>
                            <MyTextField 
                                inputProps={{
                                    min:"0",
                                    style:{textAlign:'right'}
                                }}
                                name="montant_paye"
                                type={view?"text":"number"}
                                value={view ? util.separteurMillier(vente.montant_paye):vente.montant_paye}
                                onChange={handleChange}
                                errors={vente.errors}
                                label="Montant payé"
                                disabled={view}
                                // style={{}}
                            />
                        </Grid>
                    }
                    {!view && // pas de modification du type de reglement sauf dans les règlements
                    <React.Fragment>
                        {!id &&
                            <Grid item xs={6} sm={3}>
                                <MySelectField 
                                    name="type_reglement"
                                    value={vente.type_reglement}
                                    onChange={handleChange}
                                    errors={vente.errors}
                                    options={typeReglements}
                                    libelle="libelle"
                                    label="Mode règlement"
                                    disabled={view}
                                />
                            </Grid>
                        }

                        <Grid item xs={6} sm={6} lg={3}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={enregistrer}
                            >
                                {id ? 'Modifier' : 'Enregistrer'}
                            </Button>
                        </Grid>
                    </React.Fragment>
                    }
                </Grid>
            </div>
        </Container>
    )
};