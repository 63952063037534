import moment from 'moment';
export default {
    add:{
        date_operation:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            datetime: {
                dateOnly: true,
                latest:moment().format('YYYY-MM-DD'),
                message :"date incorrect"
            }
        },
        vehicule:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        montant_recette:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:-1,
                message:" doit être superieur à 0"
            },
        },        
        montant_carburant:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:-1,
                message:" doit être superieur à 0"
            },
        },        
        salaire:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:-1,
                message:" doit être superieur à 0"
            },
        },        
        nbre_km_parcouru:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:0,
                message:" doit être superieur à 0"
            },
        },        
        autres_depenses:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:-1,
                message:" doit être superieur à 0"
            },
        },        
        description:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            }
        },
        carburant_consomme:{
            presence: {
                allowEmpty: false,
                message:"est obligatoire"
            },
            numericality:{
                greaterThan:-1,
                message:" doit être superieur à 0"
            },
        },        
    
    }
}