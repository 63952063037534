import React from 'react';
import { useParams,useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Grid  from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import { AppContext } from '../../App';
import axios from '../../utils/axios';

import util from 'utils/util';
import Liste from '../Codification/Liste';
// import util from '../../utils/util';
// import axios from '../../utils/axios';
// import { AppContext } from '../../App';
import MyTextField from '../Codification/MyTextField';
const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(0),
        marginTop: theme.spacing(0),
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
      },
}));

export default function ListeAppartements(props){
    const classes = useStyles();
    const history = useHistory();
    const {id} = useParams();
    const [propriete,setPropriete] = React.useState({})
    const [appartements,setAppartements] = React.useState([])
    const appContext = React.useContext(AppContext);
    const fetchData = async ()=>{
        if(props.propriete){
            setPropriete(props.propriete)  
            return;
        }
        appContext.setOpenLoading(true);
        try {
            const propriete = await axios.get(`/proprietes/${id}`)
            setPropriete(propriete.data)           
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);

    const colonnes = [
        { title: 'nom', field: 'nom',
            // width:'30%',cellStyle:{witheSpace:'nowrap'},
            editComponent: props => (
                <MyTextField 
                    name="nom"
                    value={props.value||""}
                    onChange={e=>props.onChange(e.target.value)}
                    errors={props.rowData.errors||[]}
                    type="text"
                    label=" "
                />
            )
        },
        { title: 'Description', field: 'description',
            width:'70%',cellStyle:{witheSpace:'nowrap'},
            editComponent: props => (
                <MyTextField 
                    name="description"
                    value={props.value||""}
                    onChange={e=>props.onChange(e.target.value)}
                    errors={props.rowData.errors||[]}
                    type="text"
                    label=" "
                />
            )
        },
    ];

    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <div className={classes.paper}>

                <Typography variant="h3" gutterBottom>
                {propriete.code}-{propriete.description}
                </Typography>
                <Grid container direction="row-reverse" spacing={3} style={{margin:5}} >
                    <Grid item xs={6} sm={4} lg={3}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={()=>history.goBack()}
                        >
                            Retour
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                {propriete.id &&
                    <Liste 
                        title='Liste des appartements'
                        urlBase={`proprietes/${propriete.id}/appartements`} 
                        values={appartements} 
                        setValues={setAppartements}
                        colonnes={colonnes}
                        transformDataBeforeSent={data=>({...data,propriete:propriete.id})}
                    />
                }
                </Grid>
        </div>

        </Container>
    )
};

