import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';
// import util from '../../utils/util';

export default function ListeOperationVehicule(){
    const [affectations,setAffectations] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Vehicule', field: 'vehicule.immatriculation' },
        { title: 'Chauffeur', field: 'chauffeur.full_name' },
        { title: 'Date', field: 'date_debut', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date_debut"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Date', field: 'date_fin', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date_fin"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
    ];
    return (
            <Liste table='affectation-vehicule' 
                values={affectations} 
                setValues={setAffectations}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouvelle opération',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/affectation-vehicules")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher opération',
                        onClick: (event, rowData) => history.push(`/affectation-vehicules/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier operation',
                        onClick: (event, rowData) => history.push(`/affectation-vehicules/${rowData.id}`)
                    },
                ]}

            />
    )
};

