import React, { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useHistory } from 'react-router-dom';

import PersonIcon from '@material-ui/icons/Person';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import axios from '../../../utils/axios';
import validate from '../../../utils/validate';
import constraintUser from '../../../Constraints/User';
import util from '../../../utils/util';
import parametres from '../../../config/parameters';
import { AppContext } from '../../../App';

const Login = () => {
	const history = useHistory();
	const [user,setUser]=React.useState({username:'',password:'',errors:{}})
	const appContext = React.useContext(AppContext);
	React.useEffect(()=>{
        if(appContext.user) history.push('/');
        
        // eslint-disable-next-line
    },[appContext.user]);
    const handleChange = (e)=> {
        const u = {...user,[[e.target.name]]:e.target.value};
        setUser(u);
    }
    const keyPressed = (event)=> {
        if (event.key === "Enter") {
          login();
        }
      }
    const login = async ()=>{
        const errors = validate(user,constraintUser.login);
        if(errors){
            setUser({...user,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            // console.log(axios.defaults.headers
            axios.defaults.headers = null;
            const res = await axios.post(parametres.API_URL+'/login',user);
            appContext.storeCredentiels(res.data.token,res.data.user);
            axios.defaults.headers = {'Authorization':' Bearer '+localStorage.getItem('token')};
            appContext.addMessage('Bienvenue sur la plateforme','success');
            if(res.data.user.is_reseted){
                history.push('change-password')
            }else{
                res.data.user.apps = res.data.user.apps.map(app=>({...app,projets:[{id:-1,nom:'GLOBAL',key:null},...app.projets]}))
                if(res.data.user.apps.length===1){
                    const app = res.data.user.apps[0];
                    localStorage.setItem('app',JSON.stringify(app));
                    appContext.setAppSelected(app);
                    if(app.projets.length===2){
                      const projet = app.projets[1]
                      localStorage.setItem('projet',JSON.stringify(projet));
                      appContext.setProjetSelected(projet)
                      history.push('/');
                    }else{
                      history.push('/selection-projet');
                    }
                }else{
                    history.push('/selection-application');
                }
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

  return (
    <Fragment>
      <div className="app-wrapper min-vh-100">
        <div className="app-main flex-column">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5 ">
                    <Grid container style={{flexDirection:'row',justifyContent:'center'}}>
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        className="d-flex flex-column align-items-center">
                        <span className="w-100 text-left text-md-center pb-4">
                          <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                            Connectez-vous
                          </h1>
                        </span>
                        <Card className="m-0 w-100 p-0 border-0">
                          <CardContent className="p-3">
                            <form className="px-5" onKeyPress={keyPressed}>
                              <div className="mb-3">
                                <FormControl className="w-100" error={user.errors['username']?true:false}>
                                  <InputLabel htmlFor="input-with-icon-adornment">
                                    Login
                                  </InputLabel>
                                  <Input
									fullWidth
									onChange={handleChange}
									id="input-with-icon-adornment"
									name="username"
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <PersonIcon />
                                      </InputAdornment>
                                    }
                                  />
								  <FormHelperText>{user.errors ? util.getMessageConstraintError(user.errors['username']):''}</FormHelperText>
                                </FormControl>
                              </div>
                              <div className="mb-3">
                                <FormControl className="w-100" error={user.errors['password']?true:false}>
                                  <InputLabel htmlFor="standard-adornment-password">
                                    Password
                                  </InputLabel>
                                  <Input
                                    id="standard-adornment-password"
									fullWidth
									onChange={handleChange}
									type="password"
									name="password"
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <LockTwoToneIcon />
                                      </InputAdornment>
                                    }
                                  />
									<FormHelperText>{user.errors ? util.getMessageConstraintError(user.errors['password']):''}</FormHelperText>
                                </FormControl>
                              </div>
                              <div className="text-center">
                                <Button
                                  color="primary"
                                  variant="contained"
                                  size="large"
								  className="my-2"
								  onClick={login}
								>
                                  Connexion
                                </Button>
                              </div>
                            </form>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Login;
