import React from 'react';
import { useHistory } from 'react-router-dom';
import Liste from '../Codification/Liste';


export default function ListeResponsable(){
    const [responsables,setResponsables] = React.useState([]);
    const history = useHistory();
    const colonnes = [
        { title: 'Nom', field: 'nom' },
        { title: 'Prenoms', field: 'prenom' },
        { title: 'Telephone', field: 'telephone' },
        { title: 'Email', field: 'email',emptyValue:()=>""},
    ];
    return (
            <Liste table='responsable' 
                values={responsables} 
                setValues={setResponsables}
                colonnes={colonnes}
                actions={[                
                    {
                        icon: 'people_outline',
                        iconProps:{color:'primary'},
                        tooltip: 'Elèves',
                        onClick: (event, rowData) => history.push(`/responsables/${rowData.id}/eleves`)
                    },
                ]}

            />
    )
};

