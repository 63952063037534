import React from 'react';
import {Grid,Button,Card,CardContent } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import find from 'lodash/find';
// import axios from 'axios';

import { AppContext } from '../../App';
import {MySelectField} from '../Codification/MySelectField'

export default function SelectionApplication(){
    const history = useHistory();
    const appContext = React.useContext(AppContext);
    const [projetSelected,setProjetSelected] = React.useState(null);



    const handleChange = (e)=> {
        const projet = find(appContext.appSelected.projets,{id:parseInt(e.target.value)});
        setProjetSelected(projet?projet:null);
    }
    const selectionner = async ()=>{
        localStorage.setItem('projet',JSON.stringify(projetSelected))
        appContext.setProjetSelected(projetSelected);
        history.push('/');
    }
    return (
        <React.Fragment>
        <div className="app-wrapper min-vh-100">
        <div className="app-main flex-column">
            <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
                <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5 ">
                    <Grid container style={{flexDirection:'row',justifyContent:'center'}}>
                        <Grid
                        item
                        xs={12}
                        lg={4}
                        className="d-flex flex-column align-items-center">
                        <span className="w-100 text-left text-md-center pb-4">
                            <h1 className="display-3 text-xl-left text-center mb-3 font-weight-bold">
                                Projets
                            </h1>
                        </span>
                        <Card className="m-0 w-100 p-0 border-0">
                            <CardContent className="p-3">
                            <form className="px-5">
                                <MySelectField 
                                    name="projet"
                                    value={projetSelected?projetSelected.id:''}
                                    onChange={handleChange}
                                    errors={[]}
                                    options={appContext.appSelected.projets}
                                    libelle="nom"
                                    label=" "
                                />

                                <div className="text-center">
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="large"
                                        className="my-2"
                                        onClick={selectionner}
                                        disabled={projetSelected===null}
                                    >
                                        Valider
                                    </Button>
                                </div>
                            </form>
                            </CardContent>
                        </Card>
                        </Grid>
                    </Grid>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        </React.Fragment>

    )
};