import React from 'react';
// import {TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { green } from '@material-ui/core/colors';

import Liste from '../Codification/Liste';
import MyDateField from '../Codification/MyDateField';
import util from '../../utils/util';

export default function ListeOperationVehicule(){
    const [operations,setOperations] = React.useState([]);
    const history = useHistory();
   
    const colonnes = [
        { title: 'Date', field: 'date_operation', type:'date',
            filterComponent: props =>
            <MyDateField 
                name="date_operation"
                value={props.columnDef.tableData.filterValue||""}
                onChange={e=>{
                    props.columnDef.tableData.filterValue = e.target.value;
                    props.onFilterChanged(props.columnDef.tableData.columnOrder,e.target.value)
                }}
                errors={[]}
                label=" "
            />
        },
        { title: 'Granite', field: 'chargement_granite.libelle' },
        { title: 'Sable(brouette)', field: 'nbre_brouette_sable',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.nbre_brouette_sable)
        },
        { title: 'Ciment(sac)', field: 'nbre_sac_ciment',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.nbre_sac_ciment)
        },
        { 
            title: 'Manoeuvres(nbre)', field: 'nbre_manoeuvre',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.nbre_manoeuvre)
        },
        { 
            title: 'Prime.Manoeuvres', field: 'prime_manoeuvre',
            cellStyle:{textAlign:"right"},
            headerStyle:{textAlign:'right'},
            filtering:false,
            render:rowdata=>util.separteurMillier(rowdata.prime_manoeuvre)
        },
        { title: 'Description', field: 'description' },
    ];
    return (
            <Liste table='operations-briqueterie' 
                values={operations} 
                setValues={setOperations}
                colonnes={colonnes}
                editable={{
                    onRowUpdate:null,
                    onRowAdd:null
                }}
                actions={[
                    {
                        icon: 'add_circle',
                        iconProps:{style:{fontSize:50,color:green[500]}},
                        tooltip: 'Nouvelle opération',
                        isFreeAction: true,
                        onClick: (event, rowData) => history.push("/operations-briqueteries")
                    },
                    {
                        icon: 'visibility',
                        iconProps:{color:'primary'},
                        tooltip: 'Afficher opération',
                        onClick: (event, rowData) => history.push(`/operations-briqueteries/view/${rowData.id}`)
                    },
                    {
                        icon: 'edit',
                        tooltip: 'Modifier operation',
                        onClick: (event, rowData) => history.push(`/operations-briqueteries/${rowData.id}`)
                    },
                ]}

            />
    )
};

