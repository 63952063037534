import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Container,CssBaseline,
    Grid,Button,Paper } from '@material-ui/core';
import omit from 'lodash/omit';
// import moment from 'moment';
import { useHistory,useParams,useLocation } from 'react-router-dom';

import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';

import validate from '../../utils/validate';
import constraint from '../../Constraints/modele';
import MyTextField from '../Codification/MyTextField';
import MySelectField from '../Codification/MySelectField';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }, 
}))
const initData = {
    marque:"",
    errors:{}
};
export default function Modele(){
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams()
    const appContext = React.useContext(AppContext);
    const [modele,setModele] = React.useState({...initData})
    const [marques,setMarques] = React.useState([])
    const [view,setView] = React.useState(false);
    const { pathname } = useLocation();
    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [marques] = await Promise.all([
                axios.get(`/marques`),
            ]);
            if(id){
                const {data} = await axios.get(`/modeles/${id}`);
                let modele = {
                        id: data.id,
                        marque:data.marque.id,
                        nom: data.nom,
                        errors:{}
                    }
                if(pathname===`/modeles/view/${id}`){
                    setView(true);
                    modele = {
                        ...modele,
                        marque:data.marque
                    }
                }
                setModele(modele)
            }
            setMarques(marques.data.results)       
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }

    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e)=>{
        setModele({...modele,[e.target.name]:e.target.value})
    }
    const enregistrer = async (nouveau)=>{
        const errors = validate(modele,constraint.add);
        if(errors){
            setModele({...modele,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.post(`/modeles`,{...omit(modele,['errors'])});
            appContext.addMessage('Enregistrement effectué avec succès','success');
            if(nouveau){
                setModele({...initData})
            }else{
                history.push("/liste-modeles")
            }
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    const modifier = async ()=>{
        const errors = validate(modele,constraint.add);
        if(errors){
            setModele({...modele,errors});
            return;
        }
        try {
            appContext.setOpenLoading(true);
            await axios.put(`/modeles/${modele.id}`,{...omit(modele,['errors'])});
            appContext.addMessage('Modification effectué avec succès','success');
            history.goBack()
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }    
        appContext.setOpenLoading(false);
    }
    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <Paper className={classes.paper}>

                <Typography variant="h5" gutterBottom>
                    {id ? "Modification" : "Saisie"} Modele
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        {view ?
                            <MyTextField 
                                name="marque"
                                value={modele.marque && modele.marque.nom}
                                label="Marque"
                                disabled={view}
                                required={false}
                            />
                        :
                            <MySelectField 
                                name="marque"
                                value={modele.marque}
                                onChange={handleChange}
                                errors={modele.errors}
                                options={marques}
                                libelle="nom"
                                disabled={view}
                                label="Marque"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MyTextField 
                            name="nom"
                            value={modele.nom}
                            onChange={handleChange}
                            errors={modele.errors}
                            label="nom"
                            variant="outlined"
                            disabled={view}
                            required={false}
                        />
                    </Grid>
                    {id ?
                        <Grid item xs={12} sm={12}>
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={view?history.goBack:modifier}
                            >
                                {view ?'Retour':'Modifier'}
                            </Button>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={6} sm={6} style={{display:'flex', justifyContent:'center'}}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(true)}
                                >
                                    Enregistrer & Nouveau
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={()=>enregistrer(false)}
                                >
                                    Enregistrer & Quitter
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>
    )
};