import React from 'react';

import Liste from '../Codification/Liste';
import MySelectField from '../Codification/MySelectField';
import MyDateField from '../Codification/MyDateField';
import { AppContext } from '../../App';
import axios from '../../utils/axios';
import util from '../../utils/util';
import find from 'lodash/find';
import { useHistory } from 'react-router-dom';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';


export default function ListePersonnel(){
    const [personnels,setPersonnels] = React.useState([]);
    const [fonctions,setFonctions] = React.useState([]);
    const appContext = React.useContext(AppContext);
    const history = useHistory();

    const fetchData = async ()=>{
        appContext.setOpenLoading(true);
        try {
            const [fonctions] = await Promise.all([
                axios.get(`/fonctions`),
            ]);
            setFonctions(fonctions.data.results)            
        } catch (error) {
            console.log(error,error.response);
            appContext.addMessage(util.getContentError(error),'error');
        }
        appContext.setOpenLoading(false);
    }
    React.useEffect(()=>{
        fetchData();
        // eslint-disable-next-line
    },[]);
    const handleChange = (e,valeur,props)=>{
        const listes = {
            fonction:fonctions,
        }
        if(Object.keys(listes).findIndex(v=>v===props.columnDef.field)!==-1){
            if(valeur===""){
                props.onChange(valeur)
            }else{
                const values = listes[props.columnDef.field];
                const value = find(values,v=>v.id===parseInt(valeur));
                if(value){
                         props.onChange(value)
                }
            }
        }else{
            props.onChange(e.target.value);
        }
    }
   
    const colonnes = [
        { title: 'Nom', field: 'nom' },
        { title: 'Prenoms', field: 'prenom' },
        { title: 'Telephone', field: 'telephone' },
        { title: 'Email', field: 'email'},
        { title: 'Adresse', field: 'adresse'},
        { title: 'Date embauche', field: 'date_embauche',
            editComponent: props => (
                <MyDateField 
                    name="date_embauche"
                    value={props.value}
                    onChange={(e,v)=>handleChange(e,v,props)}
                    errors={props.rowData.errors||[]}
                    label=" "
                />
            )

        },
        { title: 'Fonction', field: 'fonction',
        editComponent: props => (
            <MySelectField 
                name="fonction"
                value={props.value && props.value.id}
                onChange={(e,v)=>handleChange(e,v,props)}
                errors={props.rowData.errors||[]}
                options={fonctions}
                libelle="libelle"
                label=" "
            />
        ),
        render:rowData=>rowData['fonction'] ? rowData['fonction'].libelle : ""
        }
    ];
    return (
            <Liste table='employe' 
                values={personnels} 
                setValues={setPersonnels}
                colonnes={colonnes}
                transformDataBeforeSent={(personnel)=>{
                    const fonction = personnel.fonction ? personnel.fonction.id : ""
                    return {...personnel,fonction}
                }}
                actions={[
                    rowData=>  ({
                        icon: 'payments',
                        iconProps:{style:{color:green[500]}},
                        tooltip: 'Gestion salaire',
                        onClick: (event, rowData) => history.push(`/employes/${rowData.id}/rubriques`)
                    }),
                    rowData=>  ({
                        icon: 'view_headline',
                        iconProps:{style:{color:blue[500]}},
                        tooltip: 'Bulletins',
                        onClick: (event, rowData) => history.push(`/employes/${rowData.id}/bulletins`)
                    }),
                ]}
            />
    )
};

